import React, { useEffect, useState } from "react";

import { GET_LIST } from "react-admin";
import {
  Paper,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@material-ui/core/";

import ApiProvider from "../../provider/apiProvider";
import CardTitle from "../utils/cardTitle/CardTitle";
import CarrierWorkbook from "./CarrierWorkbook";
import useSort from "../../hooks/useSort";

export default function CarrierSaturation() {
  const [carriers, setCarriers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const { order, orderBy, sortArray, check_order_and_order_by } = useSort(
    "off_time"
  );

  const chartStyle = {
    tableSection: {
      marginTop: 20,
    },
    tableStyle: {
      width: 800,
      margin: "auto",
      paddingBottom: "20px",
    },
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getCarriers();
  }, []);

  const sortCarriers = (key) => {
    check_order_and_order_by(key);
    setPage(0);
  };

  const getCarriers = () => {
    setCarriers([]);
    ApiProvider(GET_LIST, "fulfillment-carrier", {
      filter: {},
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      res.data.status === 0 && setCarriers(Object.values(res.data.data));
    });
  };

  return (
    <div style={chartStyle.tableSection}>
      <Paper style={{ paddingTop: "20px" }}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={8} style={{ textAlign: "right" }}>
            <CardTitle main={"Saturación por Transportista"} size="medium" />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <CarrierWorkbook tableContent={carriers} />
          </Grid>
        </Grid>
        <br />
        <Table style={chartStyle.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "advanced"}
                  direction={order}
                  onClick={() => sortCarriers("advanced")}
                >
                  Adelantos
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "on_time"}
                  direction={order}
                  onClick={() => sortCarriers("on_time")}
                >
                  On time
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "delayed"}
                  direction={order}
                  onClick={() => sortCarriers("delayed")}
                >
                  Retrasados
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === "off_time"}
                  direction={order}
                  onClick={() => sortCarriers("off_time")}
                >
                  Off time
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {carriers
              .sort(sortArray(0))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((carrier) => (
                <TableRow key={carrier.id}>
                  <TableCell align="right">
                    <strong>{carrier.id}</strong> - {carrier.name}
                  </TableCell>
                  <TableCell align="right">{carrier.advanced || 0}</TableCell>
                  <TableCell align="right">{carrier.on_time || 0}</TableCell>
                  <TableCell align="right">{carrier.delayed || 0}</TableCell>
                  <TableCell align="right">{carrier.off_time || 0}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          style={chartStyle.tableStyle}
          labelRowsPerPage="Filas por página:"
          rowsPerPageOptions={[15, 25, 100]}
          component="div"
          count={carriers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
