import React, { Component } from "react";

import { CREATE, DELETE } from "react-admin";
import { Divider, CardContent, Card, Grid, Button } from "@material-ui/core";

import Table from "./FeriasTable";
import ErrorList from "./ErrorList";

import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";

import Filter from "../utils/filter/TableFilter";
import Snack from "../utils/snackbar/SnackBar";
import Dialog from "../utils/dialog/Dialog";
import CardTitle from "../utils/cardTitle/CardTitle";

import { logUserActivity } from "../utils/logger/Logger";

import { ExcelRenderer } from "react-excel-renderer";

import ApiProvider from "../../provider/apiProvider";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico", "trafico_full"],
      permission_Visibility: [
        "operacion_2_supervisor",
        "operacion_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
      ],

      tittle: "FERIAS",
      create: "FERIA",
      endpoint: "ferias",

      tableContent: [],
      status: 3,

      open_dialog: false,
      confirm: false,
      message_dialog: "",
      message_deleteAll: "¿Esta seguro de eliminar TODAS las FERIAS?",

      delete_id: 0,

      open: false,
      message: "",
      icon: "success",
      errorData: {},

      isErrorCalled: false,
    };
  }

  handleFilter = (tableContent) => {
    this.setState({ tableContent: tableContent, status: 0 });
  };

  handleTableHide = (status) => {
    this.setState({ status: status });
  };

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
  };

  timeTransform = (data) => {
    if (typeof data === "number") {
      let transform = new Date((data - (25567 + 1)) * 86400 * 1000);
      let result = new Date(transform.getTime() + 60 * 5 * 60000 - 1020000)
        .toTimeString()
        .split(" ")[0];
      result = result.substring(0, result.length - 3);
      return result;
    } else if (data.length === 5) {
      return data;
    } else {
      return "";
    }
  };

  handleUpload = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        try {
          let rows = resp.rows;
          let checkHead = [
            "CD",
            "tienda",
            "lunes_ini",
            "lunes_fin",
            "martes_ini",
            "martes_fin",
            "miercoles_ini",
            "miercoles_fin",
            "jueves_ini",
            "jueves_fin",
            "viernes_ini",
            "viernes_fin",
            "sabado_ini",
            "sabado_fin",
            "domingo_ini",
            "domingo_fin",
          ];
          if (
            resp.rows[0][0].trim() === checkHead[0] &&
            resp.rows[0][1].trim() === checkHead[1] &&
            resp.rows[0][2].trim() === checkHead[2] &&
            resp.rows[0][3].trim() === checkHead[3] &&
            resp.rows[0][4].trim() === checkHead[4] &&
            resp.rows[0][5].trim() === checkHead[5] &&
            resp.rows[0][6].trim() === checkHead[6] &&
            resp.rows[0][7].trim() === checkHead[7] &&
            resp.rows[0][8].trim() === checkHead[8] &&
            resp.rows[0][9].trim() === checkHead[9] &&
            resp.rows[0][10].trim() === checkHead[10] &&
            resp.rows[0][11].trim() === checkHead[11] &&
            resp.rows[0][12].trim() === checkHead[12] &&
            resp.rows[0][13].trim() === checkHead[13] &&
            resp.rows[0][14].trim() === checkHead[14] &&
            resp.rows[0][15].trim() === checkHead[15]
          ) {
            rows.shift();
            let array = [];
            for (var key in rows) {
              let myJson = {};
              if (rows[key][0] === undefined) {
                break;
              }

              myJson["center_distribution"] = rows[key][0];
              myJson["store"] = rows[key][1];
              myJson["monday_ini"] = rows[key][2]
                ? this.timeTransform(rows[key][2])
                : "";
              myJson["monday_end"] = rows[key][3]
                ? this.timeTransform(rows[key][3])
                : "";
              myJson["tuesday_ini"] = rows[key][4]
                ? this.timeTransform(rows[key][4])
                : "";
              myJson["tuesday_end"] = rows[key][5]
                ? this.timeTransform(rows[key][5])
                : "";
              myJson["wednesday_ini"] = rows[key][6]
                ? this.timeTransform(rows[key][6])
                : "";
              myJson["wednesday_end"] = rows[key][7]
                ? this.timeTransform(rows[key][7])
                : "";
              myJson["thursday_ini"] = rows[key][8]
                ? this.timeTransform(rows[key][8])
                : "";
              myJson["thursday_end"] = rows[key][9]
                ? this.timeTransform(rows[key][9])
                : "";
              myJson["friday_ini"] = rows[key][10]
                ? this.timeTransform(rows[key][10])
                : "";
              myJson["friday_end"] = rows[key][11]
                ? this.timeTransform(rows[key][11])
                : "";
              myJson["saturday_ini"] = rows[key][12]
                ? this.timeTransform(rows[key][12])
                : "";
              myJson["saturday_end"] = rows[key][13]
                ? this.timeTransform(rows[key][13])
                : "";
              myJson["sunday_ini"] = rows[key][14]
                ? this.timeTransform(rows[key][14])
                : "";
              myJson["sunday_end"] = rows[key][15]
                ? this.timeTransform(rows[key][15])
                : "";

              array.push(myJson);
            }
            ApiProvider(CREATE, this.state.endpoint, { data: { array } }).then(
              (res) => {
                if (!res.data.status) {
                  logUserActivity(`ferias,CREATE,EXCEL UPLOAD`);
                  this.setState({
                    open: true,
                    icon: "success",
                    message:
                      String(array.length) +
                      " ferias han sido cargadas con exito.",
                  });
                } else {
                  this.setState({
                    errorData: res.data.data,
                    isErrorCalled: true,
                  });
                }
              }
            );
          } else {
            this.setState({
              open: true,
              icon: "error",
              message: "El archivo parece no ser el correcto",
            });
          }
        } catch (error) {
          this.setState({
            open: true,
            icon: "error",
            message: "Archivo no soportado.",
          });
        }
      }
    });
  };

  handle_dialog = (id, message_dialog) => {
    this.setState({
      delete_id: id,
      open: false,
      open_dialog: true,
      message_dialog: message_dialog,
    });
  };

  handleDelete = (value) => {
    this.setState({ open_dialog: false });
    if (value) {
      ApiProvider(DELETE, this.state.endpoint, { id: 0 }).then((res) => {
        if (res.data.status === 0) {
          this.setState({
            open: true,
            icon: "success",
            message: "Se han eliminado todas las ferias",
          });
        }
      });
    }
  };

  getRole = () => localStorage.getItem("role");

  goBack = () => {
    this.setState({
      isErrorCalled: false,
    });
  };

  componentDidMount() {
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (
      this.state.permission_Gestion.includes(this.state.permission) ||
      this.state.permission_Visibility.includes(this.state.permission)
    ) {
      if (this.state.isErrorCalled) {
        return (
          <Card>
            <CardContent>
              <br></br>

              <h3>Errores en su intento de carga masiva:</h3>
              <br></br>
              <br></br>
              <br></br>
              {this.state.errorData ? (
                <ErrorList data={this.state.errorData} />
              ) : null}
              <Divider />
              <br></br>
              <br></br>
              <br></br>

              <Grid item xs={12} sm={12} className="myTableCenter">
                <Button
                  onClick={() => this.goBack()}
                  variant="contained"
                  component="span"
                  className="btn btn-info btn-circle3 btn-xl3"
                >
                  VOLVER
                </Button>
              </Grid>
            </CardContent>
          </Card>
        );
      } else {
        return (
          <Card>
            <CardContent>
              <CardTitle main={this.state.tittle} />
              <Divider />
              <br></br>

              <Filter
                name={this.state.tittle}
                endpoint={this.state.endpoint}
                handleFilter={this.handleFilter}
                handleTableHide={this.handleTableHide}
              />
              <br></br>
              <br></br>

              {this.state.status === 0 ? (
                <Table
                  tableContent={this.state.tableContent}
                  handleSnack={this.handleSnack}
                  handleEditHide={this.handleEditHide}
                />
              ) : null}
              {this.state.status === 1 ? (
                <center>
                  <h1>No hay resultados disponibles.</h1>
                </center>
              ) : null}
              {this.state.status === 3 ? (
                <center>
                  <MyLoading /> <h4 className="msg">{this.state.tittle}</h4>
                </center>
              ) : null}

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
              <Dialog
                open_dialog={this.state.open_dialog}
                message_dialog={this.state.message_dialog}
                handleDelete={this.handleDelete}
              />
              {this.state.permission_Gestion.includes(this.state.permission) ? (
                <Grid className="myTableCenter">
                  <input
                    id="contained-button-file"
                    className="inputButton"
                    multiple
                    type="file"
                    onChange={this.handleUpload}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      component="span"
                      className="btn btn-info btn-circle3 btn-xl3"
                    >
                      SUBIR
                    </Button>
                  </label>
                  &nbsp;&nbsp; &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      this.handle_dialog(0, this.state.message_deleteAll);
                    }}
                    variant="contained"
                    component="span"
                    className="btn btn-info btn-circle3 btn-xl3"
                  >
                    BORRAR
                  </Button>
                </Grid>
              ) : null}
            </CardContent>
          </Card>
        );
      }
    } else {
      return <NotFound />;
    }
  }
}

export default Container;
