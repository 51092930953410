import React from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

export default function UnlockTrailer(props) {
  return (
    <div>
      <Tooltip title="Desbloquear">
        <Button
          variant="outlined"
          onClick={() => props.tryToUnlockTrailer(props.plate)}
        >
          Bloqueado
        </Button>
      </Tooltip>
    </div>
  );
}
