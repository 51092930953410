import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const tempFunction = props.function;
  const id = props.id;
  const message = props.message ? props.message : "No ha hecho una selección";

  const handleClickConfirm = () => {
    if (id !== 0) {
      tempFunction(id);
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
      >
        Eliminar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions>
          <div>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button
              style={dialog.confirmDeleteBtn}
              onClick={() => handleClickConfirm()}
            >
              Confirmar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const dialog = {
  confirmDeleteBtn: {
    border: "1px solid red"
  }
};
