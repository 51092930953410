import React, {Component} from "react";
import {Button, Card, CardContent, Divider, Grid} from "@material-ui/core";
import {Title} from "react-admin";
import Table from "./Table";
import CardTitle from "../../utils/cardTitle/CardTitle";
import MyLoading from "../../utils/loading/Loading";
import ApiProvider from "../../../provider/apiProvider";
import {CREATE} from "react-admin";
import Workbook from "react-excel-workbook";
import { extractDateAndTime } from "../../utils/date/DateUtils";
import { round } from "../../utils/number/NumberUtils";
import { withRouter } from "react-router-dom";
import { logUserActivity } from  "./../../utils/logger/Logger";

const STATUS_LOADED = 0
const STATUS_EMPTY = 1
const STATUS_LOADING = 3

const SCHEDULER_ENDPOINT = "scheduler";
const SAVE_SCHEDULE_RESULT_ENDPOINT = "schedule-result";
const ZONE = "REGIONES";

const BUTTON_STYLE = {
    backgroundColor: "#3f51b5",
    color: "#FFFFFF",
    border: "1px solid '#3f51b5"
}
class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReturnList: [],
            unScheduledTrailers: [],
            scheduledTrailers: [],
            loadingStatus: STATUS_LOADING,
            tableContent: [],
            title: "Optimizador",
            scheduleDisabled: false
        };
    }

    handleSnackCallback = (icon, message) => {
        if (!this.props.handleSnack) {
            return;
        }

        this.props.handleSnack(icon, message);
    }

    handleFilter = (scheduledTrailers, unscheduledTrailers) => {
        let allTrailers = scheduledTrailers.concat(unscheduledTrailers);
        allTrailers.forEach(trailer => {
            trailer.formattedSchedule = this.formatSchedule(trailer.schedule_start);
        });

        let totalTrailers = allTrailers.length;
        this.setState({
            scheduledTrailers: scheduledTrailers,
            unScheduledTrailers: unscheduledTrailers,
        })

        this.handleLoadingStatus(totalTrailers > 0? STATUS_LOADED : STATUS_EMPTY);
        this.handleSnackCallback(
            "success",
            "Se han cargado " + String(totalTrailers) + " filas."
        );
    };

    handleLoadingStatus = (loadingStatus) => {
        this.setState({ loadingStatus: loadingStatus });
    };

    componentDidMount() {
        this.setState({
            tableContent: [],
            loadingStatus: STATUS_LOADING
        });

        this.schedule(this.props.trailersToSchedule);
    }

    formatSchedule = (schedule) => {
        if(!schedule) {
            return;
        }

        let dateTime = extractDateAndTime(schedule);
        return dateTime.time + " " + dateTime.date;
    }

    schedule = (trailersToSchedule) => {
         let formattedTrailers = trailersToSchedule.map((value, key) => {
            value.is_return && this.state.isReturnList.push(value.trailer_plate);

            return {
                key: key,
                ambient_description: value.ambient_description,
                carrier: value.carrier_name,
                center_distribution: value.center_distribution,
                hour: value.hour,
                lead_time: value.lead_time.map(lt => round((lt || 0) / 60)),
                route: value.route,
                trailer_plate: value.trailer_plate
            }
        });
        let data = {
            zone: ZONE,
            trailers: formattedTrailers,
        };

        ApiProvider(CREATE, SCHEDULER_ENDPOINT, {
            data: {array: data}
        }).then((res) => {
            if (res.data.status === "ok") {
                logUserActivity(`scheduler,CREATE,${data.trailers.map(d=>d.trailer_plate).join("-")}`);
                this.handleFilter(res.data.scheduled_trailers, res.data.unscheduled_trailers);
            } else {
                this.handleFilter([], []);
            }
        });
    }
    
    saveResults = () => {
      
      this.setState({scheduleDisabled: true});
      let data = this.state.scheduledTrailers.map(trailer => ({
        trailer_plate: trailer.trailer_plate,
        center_distribution: trailer.center_distribution,
        route: trailer.route,
        is_return: this.state.isReturnList.includes(trailer.trailer_plate),
        schedule_start: trailer.schedule_start,
        schedule_end: trailer.schedule_end,
        schedule_type: trailer.schedule_type,
        ambient_description: trailer.ambient_description,
        visits: trailer.visits
      }));

      ApiProvider(CREATE, SAVE_SCHEDULE_RESULT_ENDPOINT, {
        data: {array: data}
      }).then((res) => {
          this.setState({scheduleDisabled: false});
          if (res.data.status === 0) {
            logUserActivity(`schedule-result,CREATE,${data.map(d=>d.trailer_plate).join("-")}`);
            this.props.history.push('/resultados/regiones');
          } else {
            this.handleSnackCallback(
                "error",
                "No se han podido gestionar los resultados."
            )
          }

      });
    }

    render() {

        let scheduledTable = <React.Fragment>
            <Grid container item  justify="space-between">
                <Grid item>
                    <h4>Resultados disponibles para gestionar</h4>
                </Grid>
                <Grid item >
                    <Button disabled={this.state.scheduleDisabled}  onClick={this.saveResults} style={BUTTON_STYLE}>
                        Gestionar
                    </Button>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Table tableContent={this.state.scheduledTrailers} />
            </Grid>
        </React.Fragment>;

        let unScheduledTable = this.state.unScheduledTrailers.length > 0 && <React.Fragment>
            <Grid item xs={12}>
                <h4>Trailers sin agenda por disponibilidad de la tienda</h4>
            </Grid>
            <Grid item xs={12}>
                <Table tableContent={this.state.unScheduledTrailers} />
            </Grid>
        </React.Fragment>;

        return (
          <Card>
            <CardContent>
                <Title title={this.state.title} /> 
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <CardTitle main={this.state.title} />
                    </Grid>
                    <Grid item>
                        <Workbook
                          filename="optimizadorRegiones.xlsx"
                          element={
                            <Button variant="outlined" color="secondary">
                              Descargar
                            </Button>
                          }
                        >
                          <Workbook.Sheet
                            data={this.state.scheduledTrailers}
                            name="AGENDADOS"
                          >
                            <Workbook.Column
                              label="CD"
                              value="center_distribution"
                            />
                            <Workbook.Column
                              label="TRAILER"
                              value="trailer_plate"
                            />
                            <Workbook.Column label="RUTA" value="route" />
                            <Workbook.Column
                              label="LEADTIME A PRIMERA TIENDA"
                              value="lead_time"
                            />
                            <Workbook.Column label="TRANSPORTISTA" value="" />
                            <Workbook.Column
                              label="ESTADO"
                              value="bussiness_status"
                            />
                            <Workbook.Column
                              label="AGENDA"
                              value="formattedSchedule"
                            />
                          </Workbook.Sheet>

                          <Workbook.Sheet
                            data={this.state.unScheduledTrailers}
                            name="NO AGENDADOS"
                          >
                            <Workbook.Column
                              label="CD"
                              value="center_distribution"
                            />
                            <Workbook.Column
                              label="TRAILER"
                              value="trailer_plate"
                            />
                            <Workbook.Column label="RUTA" value="route" />
                            <Workbook.Column
                              label="LEADTIME A PRIMERA TIENDA"
                              value="lead_time"
                            />
                            <Workbook.Column label="TRANSPORTISTA" value="" />
                            <Workbook.Column
                              label="ESTADO"
                              value="bussiness_status"
                            />
                          </Workbook.Sheet>
                        </Workbook>
                    </Grid>                
                </Grid>
            <Divider />
            <Grid container style={{ marginTop: "10px" }}>
                {this.state.loadingStatus === STATUS_LOADED && (
                  <Grid container item spacing={16}>
                    <Grid container item spacing={8}>
                      {scheduledTable}
                    </Grid>
                    <Grid container item spacing={8}>
                      {unScheduledTable}
                    </Grid>
                  </Grid>
                )}
                {this.state.loadingStatus === STATUS_LOADING && (
                    <Grid container item justify="center">
                        <Grid item>
                            <MyLoading />
                        </Grid>
                    </Grid>
                )}
                {this.state.loadingStatus === STATUS_EMPTY && (
                    <Grid container item justify="center">
                        <h1>No hay resultados disponibles.</h1>
                    </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        );
    }
}

export default withRouter(Container);
