import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";
import { UPDATE, GET_ONE } from "react-admin";

import { Label } from "semantic-ui-react";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class FixplanningEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "FIXPLANNING",

      endpoint: "fixed-planning",

      id: this.props.id,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,

      is_StatesLoaded: false,
    };
  }

  handleInput = (state, value) => {
    if (value === "") {
      value = 0;
    }
    let myinput = parseInt(value);
    if (state === "monday") {
      this.setState({ monday: myinput });
    } else if (state === "tuesday") {
      this.setState({ tuesday: myinput });
    } else if (state === "wednesday") {
      this.setState({ wednesday: myinput });
    } else if (state === "thursday") {
      this.setState({ thursday: myinput });
    } else if (state === "friday") {
      this.setState({ friday: myinput });
    } else if (state === "saturday") {
      this.setState({ saturday: myinput });
    } else if (state === "sunday") {
      this.setState({ sunday: myinput });
    }
  };

  handleEdit = () => {
    ApiProvider(UPDATE, this.state.endpoint, {
      id: this.state.id,
      data: {
        monday: this.state.monday,
        tuesday: this.state.tuesday,
        wednesday: this.state.wednesday,
        thursday: this.state.thursday,
        friday: this.state.friday,
        saturday: this.state.saturday,
        sunday: this.state.sunday,
      },
    }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`fixed-planning,PATCH,${this.state.id}`);
        this.props.handleSnack(true, "success", "Se ha editado fixplanning.");
        this.props.handleEditHide(false, 0);
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No se ha podido editar fixplanning."
        );
      }
    });
  };

  callApi_fillStates = () => {
    ApiProvider(GET_ONE, this.state.endpoint, {
      id: this.state.id,
    }).then((res) => {
      if (res.data.status === 0) {
        this.setState({
          center_distribution: res.data.data.center_distribution,
          store: res.data.data.store,
          ambient_desc: res.data.data.ambient_desc,

          monday: res.data.data.monday,
          tuesday: res.data.data.tuesday,
          wednesday: res.data.data.wednesday,
          thursday: res.data.data.thursday,
          friday: res.data.data.friday,
          saturday: res.data.data.saturday,
          sunday: res.data.data.sunday,

          is_StatesLoaded: true,
        });
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No se ha podido cargar fixplanning."
        );
      }
    });
  };

  componentDidMount() {
    this.callApi_fillStates();
  }

  render() {
    if (this.state.is_StatesLoaded) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CD</Label>
              <Label className="select">{this.state.center_distribution}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIENDA</Label>
              <Label className="select">{this.state.store}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIPO MERCADERIA</Label>
              <Label className="select">{this.state.ambient_desc}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">LUNES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("monday", event.target.value)
                }
                defaultValue={this.state.monday}
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">MARTES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("tuesday", event.target.value)
                }
                defaultValue={this.state.tuesday}
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">MIERCOLES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("wednesday", event.target.value)
                }
                defaultValue={this.state.wednesday}
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">JUEVES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("thursday", event.target.value)
                }
                defaultValue={this.state.thursday}
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">VIERNES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("friday", event.target.value)
                }
                defaultValue={this.state.friday}
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">SABADO</Label>
              <input
                onChange={(event) =>
                  this.handleInput("saturday", event.target.value)
                }
                defaultValue={this.state.saturday}
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">DOMINGO</Label>
              <input
                onChange={(event) =>
                  this.handleInput("sunday", event.target.value)
                }
                defaultValue={this.state.sunday}
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleEdit()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                EDITAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">{this.state.title}</h4>
        </CardContent>
      );
    }
  }
}

export default FixplanningEdit;
