import React from "react";

import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";

export default function TableCarrier(props) {
  const { tenderingData } = props;

  let avaliableCenters = [];
  if (tenderingData[0] && tenderingData[0].cd) {
    for (const cd in tenderingData[0].cd) {
      avaliableCenters.push(cd);
    }
  }

  return (
    <Table className="itemAlign">
      <TableHead>
        <TableRow>
          <TableCell className="myTableBorder">RUT</TableCell>
          <TableCell className="myTableBorder">TRANSPORTISTA</TableCell>
          <TableCell className="myTableBorder">COMUNA</TableCell>
          <TableCell className="myTableBorder">TENDERING</TableCell>
          <TableCell className="myTableBorder">RANKING</TableCell>
          {tenderingData[0] && tenderingData[0].cd
            ? avaliableCenters.map((center) => (
                <TableCell key={center} className="myTableBorder">
                  {center}
                </TableCell>
              ))
            : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {tenderingData.map((obj, index) => {
          const { rut, name, commune, percent, ranking, cd } = obj;
          return (
            <TableRow key={index}>
              <TableCell className="myTableLeft">{rut}</TableCell>
              <TableCell className="myTableLeft">{name}</TableCell>
              <TableCell className="myTableLeft">{commune}</TableCell>
              <TableCell className="myTableLeft">{percent}</TableCell>
              <TableCell className="myTableLeft">{ranking}</TableCell>
              {avaliableCenters
                ? avaliableCenters.map((center) => (
                    <TableCell key={center} className="myTableBorder">
                      {cd[center]}
                    </TableCell>
                  ))
                : null}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
