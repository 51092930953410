import React from "react";

import { Toolbar, AppBar } from "@material-ui/core";

import headerWalmart from "./../../images/walmart.png";
import headerSimpliRoute from "./../../images/simpliroute.png";

const headerStyle = {
  position: "fixed",
  top: "0",
  marginBottom: 30
};

const MyHeader = () => {
  return (
    <AppBar style={headerStyle} color="primary">
      <Toolbar>
        <div color="inherit" className="widthHeader">
          <img
            src={headerWalmart}
            alt="walmart logo"
            width="175px"
            height="50px"
          ></img>
          <div className="displayHeader" color="inherit">
            <img
              className="imgHeader"
              alt="simpli logo"
              src={headerSimpliRoute}
              width="189px"
              height="41px"
            ></img>
            <h6 className="hHeader">Powered by</h6>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MyHeader;
