import React from "react";

import styled from "@emotion/styled";

const LargeTitle = styled("h1")`
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 5px;
  padding-left: 10px;
`;

const MediumTitle = styled("h3")``;

/**
 * Returns a title to use on section or module headers
 */
const CardTitle = ({ size, optional, main }) => {
  // Returned if no main text is passed
  if (!main) {
    return <MediumTitle data-testid="error">Title not found</MediumTitle>;
  }

  let text = `${main}${optional ? ` - ${optional}` : ""}`;

  if (size === "medium") {
    return <MediumTitle data-testid="medium-title">{text}</MediumTitle>;
  } else {
    return <LargeTitle data-testid="large-title">{text}</LargeTitle>;
  }
};

export default CardTitle;
