import React, { Component } from "react";
import { DELETE } from "react-admin";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Button,
  CardContent,
} from "@material-ui/core";

import { logUserActivity } from "../utils/logger/Logger";

import ApiProvider from "../../provider/apiProvider";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: "user",

      tableContent: {},
      is_TableLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      tableContent: this.props.tableContent,
      is_TableLoaded: true,
    });
  }

  handleEdit(id) {
    this.props.handleEditHide(true, id);
  }

  handleDelete(id) {
    ApiProvider(DELETE, this.state.endpoint, { id: id }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`user,DELETE,${id}`);
        this.props.handleSnack(true, "success", "Se ha eliminado usuario.");
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No ha sido posible eliminar usuario."
        );
      }
    });
  }

  render() {
    if (this.state.is_TableLoaded) {
      return (
        <Table className="itemAlign">
          <TableHead>
            <TableRow>
              <TableCell className="myTableBorder">USERNAME</TableCell>
              <TableCell className="myTableBorder">CD</TableCell>
              <TableCell className="myTableBorder">ROL</TableCell>
              <TableCell className="myTableBorder">NOMBRE</TableCell>
              <TableCell className="myTableBorder">ULTIMO CAMBIO</TableCell>
              <TableCell className="myTableBorder">EMAIL</TableCell>
              <TableCell className="myTableBorderLessFlag"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.tableContent.map((usr, index) => {
              const {
                id,
                username,
                center_distribution,
                role,
                name,
                last_name,
                hour_last_change,
                email,
              } = usr;
              return (
                <TableRow key={id} id={id}>
                  <TableCell className="myTableLeft">{username}</TableCell>
                  <TableCell className="myTableLeft">
                    {center_distribution}
                  </TableCell>
                  <TableCell className="myTableLeft">{role}</TableCell>
                  <TableCell className="myTableLeft">{`${name} ${last_name}`}</TableCell>
                  <TableCell className="myTableLeft">
                    {hour_last_change}
                  </TableCell>
                  <TableCell className="myTableLeft">{email}</TableCell>
                  <TableCell className="myTableBorderLessFix">
                    <Button
                      onClick={() => {
                        this.handleEdit(id);
                      }}
                      variant="contained"
                      component="span"
                      className="btn btn-info btn-circle6 btn-xl6"
                    >
                      EDITAR
                    </Button>
                  </TableCell>
                  <TableCell className="myTableBorderLessFlag">
                    <Button
                      onClick={() => {
                        this.handleDelete(id);
                      }}
                      variant="contained"
                      component="span"
                      className="btn btn-info btn-circle2 btn-xl2"
                    >
                      ELIMINAR
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}
export default UserList;
