import React, { useState } from "react";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { extractDateAndTime } from "../utils/date/DateUtils";

export const labelFormat = (value) => {
  let hour = value["start"].hour;
  if (hour === 0) return "MADRUGADA";
  if (hour === 1) return "00:00-07:00";
  if (hour === 6) return "AM";
  if (hour === 7) return "07:00-12:00";
  if (hour === 12) return "PM";
  if (hour === 13) return "12:00-20:00";
  if (hour === 18) return "NOCHE";
  if (hour === 19) return "20:00-00:00";
};

export default function VisitsCalendar({
  updateDate,
  visits,
  visitStatus,
  whiteFlags,
}) {
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [whiteFlagDialog, setwhiteFlagDialog] = useState([]);
  const [openHeaderDialog, setOpenHeaderDialog] = useState(false);

  const is_container = (trailer_plate) => /^\d{6}$/.test(trailer_plate);

  const eventContentFormat = (value) => {
    let event = value.event.toJSON();

    if (event.title) {
      return (
        <Grid container style={{ fontWeight: "bold" }}>
          {event.extendedProps.motive === "Manual" && (
            <Grid item xs={2}>
              <span role="img" aria-label="black check">
                &#128197;
              </span>
            </Grid>
          )}
          <Grid item xs={10}>
            {event.extendedProps.center_distribution}
            <br />
            {event.extendedProps.trailer_plate}
          </Grid>
          {is_container(event.extendedProps.trailer_plate) && (
            <Grid className="container-box">
              <span role="img" aria-label="UPPERCASE C">
                &#67;
              </span>
            </Grid>
          )}
          <Grid
            style={{
              position: "absolute",
              margin: "auto",
              top: "80%",
              bottom: 0,
            }}
          >
            {event.extendedProps.arrival ? (
              <p className="black_check_mark">&#10003;</p>
            ) : (
              visitStatus[event.extendedProps.id_departures]
            )}
          </Grid>
        </Grid>
      );
    }
    return "";
  };

  const handlerOpenHeaderDialog = (_) => setOpenHeaderDialog(!openHeaderDialog);

  const headerWhiteFlag = (date) => {
    if (whiteFlags[date]) {
      setwhiteFlagDialog(whiteFlags[date]);
      handlerOpenHeaderDialog();
    }
  };

  const eventClickEvent = (value) => {
    let event = value.event.toJSON();
    if (event.title) {
      setSelectedEvent(event);
      handleClickOpen(event);
    }
  };

  const handleClickOpen = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const datesSet = (newDate) => {
    updateDate(newDate);
  };

  return (
    <React.Fragment>
      <Dialog
        maxWidth={"xs"}
        fullWidth={true}
        open={openHeaderDialog}
        onClose={handlerOpenHeaderDialog}
      >
        <DialogTitle>Banderas blancas:</DialogTitle>
        <DialogContent>
          {whiteFlagDialog.map((element) => (
            <Grid container key={element.id}>
              <Grid item xs={4}>
                CD: {element.center_distribution}
              </Grid>
              <Grid item xs={8}>
                Ambiente: {element.ambient_desc}
              </Grid>
              <br />
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
      {selectedEvent.title && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {selectedEvent.title}
            <br />
          </DialogTitle>
          <DialogContent style={{ fontSize: 14 }}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              Transportista
            </Typography>
            <Typography component="p" gutterBottom>
              {selectedEvent.extendedProps.carrier}
            </Typography>
            {selectedEvent.extendedProps.cd && (
              <>
                <Typography
                  gutterBottom
                  style={{ fontWeight: "bold", marginTop: 7 }}
                >
                  CD
                </Typography>
                <Typography component="p" gutterBottom>
                  {selectedEvent.extendedProps.cd.id} -{" "}
                  {selectedEvent.extendedProps.cd.name}
                </Typography>
              </>
            )}
            <Typography
              gutterBottom
              style={{ fontWeight: "bold", marginTop: 7 }}
            >
              Ambiente
            </Typography>
            <Typography component="p" gutterBottom>
              {selectedEvent.extendedProps.ambient
                ? selectedEvent.extendedProps.ambient
                : "Sin ambiente"}
            </Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: "bold", marginTop: 7 }}
            >
              Motivo
            </Typography>
            <Typography component="p" gutterBottom>
              {selectedEvent.extendedProps.motive
                ? selectedEvent.extendedProps.motive
                : "Sin motivo"}
            </Typography>
            <Typography
              gutterBottom
              style={{ fontWeight: "bold", marginTop: 7 }}
            >
              Ruta
            </Typography>

            <Typography component="p" gutterBottom>
              {selectedEvent.extendedProps.route}
            </Typography>
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      )}
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        allDaySlot={false}
        dayHeaderContent={(obj) => {
          const currentDate = extractDateAndTime(obj.date).date;
          return Array.isArray(whiteFlags[currentDate]) &&
            whiteFlags[currentDate].length > 0 ? (
            <p
              className="calendar_header"
              onClick={() => headerWhiteFlag(currentDate)}
            >
              {obj.text}
            </p>
          ) : (
            obj.text
          );
        }}
        locale={esLocale}
        contentHeight="auto"
        slotDuration="01:00:00"
        slotLabelInterval="01:00:00"
        dayHeaderFormat={{
          weekday: "long",
          month: "numeric",
          day: "numeric",
          omitCommas: true,
        }}
        headerToolbar={{
          left: "",
          center: "",
          right: "today prev,next",
        }}
        slotLabelFormat={(value) => labelFormat(value)}
        datesSet={(dateInfo) => {
          datesSet(dateInfo);
        }}
        events={visits}
        eventContent={(arg) => eventContentFormat(arg)}
        eventClick={(arg) => eventClickEvent(arg)}
      ></FullCalendar>
    </React.Fragment>
  );
}
