import React, { Component } from "react";

import { CardContent, Grid } from "@material-ui/core";
import TableTendering from "./TableTendering";
import { hasCorrectRole } from "../utils/roleManager/RoleManager";
import TenderingFilter from "./TenderingFilter";
import Workbook from "react-excel-workbook";
import Snack from "../utils/snackbar/SnackBar";
import MyLoading from "../utils/loading/Loading";

const STATUS_LOADING = "LOADING"
const STATUS_LOADED = "LOADED"
const STATUS_EMPTY = "EMPTY"
class TenderingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenderingData: [],
      status: STATUS_LOADING,
      errorList: [],
    };
  }

  handleFilter = (tenderingData) => {
    const status = tenderingData.length === 0? STATUS_EMPTY : STATUS_LOADED
    this.setState({ tenderingData: tenderingData, status: status });
  };

  handleLoading = (loading) => {
    const status = loading? STATUS_LOADING : STATUS_LOADED
    this.setState({ status: status });
  };
  
  render() {
    const { tenderingData, status} = this.state;
      
    return (
      <CardContent>
        <TenderingFilter handleFilter={this.handleFilter} handleLoading={this.handleLoading}/>
        {hasCorrectRole(["trafico_full", "trafico"]) ? (
          <Grid className="myTableRight">
            <input
              id="contained-button-file"
              className="inputButton"
              multiple
            />
            <label htmlFor="contained-button-file">
              {tenderingData ? (
                <Workbook
                  filename="Tendering.xlsx"
                  element={<button variant="outlined" component="span" className="btn btn-info btn-circle3 btn-xl3"> DESCARGAR </button>}>
                  <Workbook.Sheet data={tenderingData} name="Tendering">
                    <Workbook.Column label="Region" value="region" />
                    <Workbook.Column label="Localidad" value="commune"/>
                    <Workbook.Column label="Transportista" value="carrier" />
                    <Workbook.Column label="Participacion%" value={row => row.expectedPercent + "" } />
                    <Workbook.Column label="Viajes" value={row => row.expectedDispatches + ""} />
                    <Workbook.Column label="Total Comuna" value={row => row.totalCommuneDispatches + ""} />
                    <Workbook.Column label="Control%" value={row => row.currentPercent + ""} />
                    <Workbook.Column label="Control" value={row => row.currentDispatches + ""} />
                    <Workbook.Column label="Cumplimiento" value={row => row.currentCompliance + ""} />
                    <Workbook.Column label="Desviacion%" value={row => row.deviation + ""} />
                  </Workbook.Sheet>
                </Workbook>) : null}
            </label>
          </Grid>
        ) : null}
        {status === STATUS_LOADED? ( 
        <TableTendering tenderingData={this.state.tenderingData} />
        ) : null }
        
        <br></br>

        {status === STATUS_LOADING? ( 
          <center>
            <MyLoading />
          </center> 
        ) : null}

        {status === STATUS_EMPTY? ( 
          <center>
            <h1>No hay resultados disponibles.</h1>
          </center>
        ) : null}
        
        <Snack
          open={this.state.open}
          icon={this.state.icon}
          message={this.state.message}
        />
      </CardContent>
    );
  }
}

export default TenderingContainer;
