import React from "react";

import CardTitle from "../utils/cardTitle/CardTitle";

import { CardContent } from "@material-ui/core";

import { useForm } from "react-hook-form";

import FormContainer from "../../reusableStyles/FormContainer.js";

const apiUrl = process.env.REACT_APP_API_URL;

export default function CreateCarrier({
  handleSectionToShow,
  sections,
  handleAddCarrier,
}) {
  const TITLE = "Crear transportista";

  const { register, handleSubmit } = useForm();

  const createCarrier = (newCarrier) => {
    let tempCarrier = newCarrier;
    tempCarrier.rut = parseInt(newCarrier.rut);

    fetch(`${apiUrl}/carrier`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(tempCarrier),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          handleAddCarrier();
        } else {
          handleSectionToShow(sections[0]);
        }
      });
  };

  const onSubmit = (data) => {
    createCarrier(data);
  };

  return (
    <CardContent>
      <CardTitle main={TITLE} size="medium" />
      <br></br>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Rut</label>
          <input name="rut" type="number" ref={register({ required: true })} />
          <label>Nombre</label>
          <input name="name" type="text" ref={register({ required: true })} />
          <label>Tipo</label>
          <input name="type" type="text" ref={register({ required: true })} />
          <label>Vehículo</label>
          <input
            name="vehicle"
            type="text"
            ref={register({ required: true })}
          />
          <label>Spot</label>
          <input 
            type="checkbox" 
            name="is_spot" 
            className="" 
            style={{
              height: 25 + 'px',
              width: 25 + 'px',
            }}
            ref={register({ default: false })}
          /> 
          <br></br>
          <br></br>   
          <button type="submit" className="submit">
            Crear
          </button>
          <button
            type="button"
            className="goBack"
            onClick={() => handleSectionToShow(sections[0])}
          >
            Volver
          </button>
        </form>
      </FormContainer>
    </CardContent>
  );
}
