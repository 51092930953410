import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";

import { GET_LIST } from "react-admin";
import { Label } from "semantic-ui-react";
import Select from "react-select";
import ApiProvider from "../../provider/apiProvider";

const apiUrl = process.env.REACT_APP_API_URL;

class TenderingFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: "tendering",

      cdSelect: [],
      is_SelectLoaded: false,
      id_cd: 0,

      carrierSelect: [],
      is_SelectCarrierLoaded: false,
      carrier: "",
    };
  }

  callApi_Distribution = () => {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let improvedCdData = res.data.map((center) => ({
        label: center.id,
        value: center.id,
        name: center.name,
        id: center.id,
      }));

      this.setState({ cdSelect: improvedCdData, is_SelectLoaded: true });
    });
  };

  async callApi_Carriers() {
    await fetch(`${apiUrl}/carrier`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({
            carrierSelect: res.data,
            is_SelectCarrierLoaded: true,
          });
        }
      });
  }

  callApi_Endpoint = () => {
    let myFilter = {};

    if (this.state.id_cd) {
      myFilter.center_distribution = this.state.id_cd;
    }

    if (this.state.carrier) {
      myFilter.name = this.state.carrier;
    }

    ApiProvider(GET_LIST, this.state.endpoint, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: myFilter,
    }).then((res) => {
      if (res.data.status === 0) {
        this.props.handleFilter(res.data.data);
      } else {
        this.props.handleFilter([]);
      }
    });
  };

  handleCD = (value) => {
    let id_cd = parseInt(value);
    this.setState({ id_cd: id_cd });
  };

  handleCarrier = (name) => {
    this.setState({ carrier: name });
  };

  componentDidMount() {
    this.callApi_Carriers();
    this.callApi_Distribution();
    this.callApi_Endpoint();
  }

  render() {
    const { is_SelectLoaded, is_SelectCarrierLoaded } = this.state;

    if (is_SelectLoaded) {
      return (
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myTableCenter myLabelSelect">CD</Label>
            {is_SelectCarrierLoaded ? (
              <Label className="myTableCenter myLabelSelect">
                Transportista
              </Label>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Select
              id="CDSelect"
              className="select"
              classNamePrefix="select"
              options={this.state.cdSelect.map((opt) => ({
                label: opt.label,
                value: opt.value,
              }))}
              onChange={(opt) => this.handleCD(opt.value)}
            />
            &nbsp;&nbsp;
            {is_SelectCarrierLoaded ? (
              <Select
                id="CDSelect"
                className="select"
                classNamePrefix="select"
                options={this.state.carrierSelect.map((opt) => ({
                  label: opt.name,
                  value: opt.name,
                }))}
                onChange={(opt) => this.handleCarrier(opt.value)}
              />
            ) : null}
            &nbsp;&nbsp;
            <Button
              variant="outlined"
              style={filterStyles.fieldCorrection}
              onClick={() => this.callApi_Endpoint()}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

const filterStyles = {
  fieldCorrection: {
    marginLeft: "10px",
  },
};

export default TenderingFilter;
