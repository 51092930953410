import React, { Component } from "react";

import { CREATE } from "react-admin";
import { CardContent, Grid, Button } from "@material-ui/core";

import { Label } from "semantic-ui-react";

import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class StoreCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: "store-master",
      store_id: 0,
      address: "",
      capacity: 0,
      commune: "",
      format: "",
      latitude: 0,
      longitude: 0,
      name: "",
      observations: "",
      region: "",
      zone: "",
    };
  }

  handleCreate = () => {
    ApiProvider(CREATE, this.state.endpoint, {
      data: {
        store_id: parseInt(this.state.store_id),
        address: this.state.address,
        capacity: parseInt(this.state.capacity),
        commune: this.state.commune,
        format: this.state.format,
        latitude: parseFloat(this.state.latitude),
        longitude: parseFloat(this.state.longitude),
        name: this.state.name,
        observations: this.state.observations,
        region: this.state.region,
        zone: this.state.zone,
      },
    }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`${this.state.endpoint},CREATE,${this.state.store_id}`);
        this.props.handleSnack(true, "success", "Se ha creado la Tienda");
        this.props.handleCreateStore({
          store_id: this.state.store_id,
          address: this.state.address,
          capacity: this.state.capacity,
          commune: this.state.commune,
          format: this.state.format,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          name: this.state.name,
          observations: this.state.observations,
          region: this.state.region,
          zone: this.state.zone,
        });
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No se ha podido crear la Tienda"
        );
      }
    });
  };

  render() {
    return (
      <CardContent>
        <Grid container spacing={32}>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">ID TIENDA</Label>
            <input
              type="number"
              onChange={(event) =>
                this.setState({ store_id: event.target.value })
              }
              defaultValue={this.state.store_id}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">NOMBRE</Label>
            <input
              type="text"
              onChange={(event) => this.setState({ name: event.target.value })}
              defaultValue={this.state.name}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">LATITUD</Label>
            <input
              type="number"
              onChange={(event) =>
                this.setState({ latitude: event.target.value })
              }
              defaultValue={this.state.latitude}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">LONGITUD</Label>
            <input
              type="number"
              onChange={(event) =>
                this.setState({ longitude: event.target.value })
              }
              defaultValue={this.state.longitude}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">ZONA</Label>
            <input
              type="text"
              onChange={(event) =>
                this.setState({ zone: event.target.value })
              }
              defaultValue={this.state.zone}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">DIRECCIÓN</Label>
            <input
              type="text"
              onChange={(event) =>
                this.setState({ address: event.target.value })
              }
              defaultValue={this.state.address}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">COMUNA</Label>
            <input
              type="text"
              onChange={(event) =>
                this.setState({ commune: event.target.value })
              }
              defaultValue={this.state.commune}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">FORMATO</Label>
            <input
              type="text"
              onChange={(event) =>
                this.setState({ format: event.target.value })
              }
              defaultValue={this.state.format}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">REGION</Label>
            <input
              type="text"
              onChange={(event) =>
                this.setState({ region: event.target.value })
              }
              defaultValue={this.state.region}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">CAPACIDAD</Label>
            <input
              type="number" min="0" pattern="\d*"
              onChange={(event) =>
                this.setState({ capacity: event.target.value })
              }
              defaultValue={this.state.capacity}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">OBSERVACIONES</Label>
            <input
              type="text"
              onChange={(event) =>
                this.setState({ observations: event.target.value })
              }
              defaultValue={this.state.observations}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="myTableCenter">
            <Button
              onClick={() => this.handleCreate()}
              variant="contained"
              component="span"
              className="btn btn-info btn-circle3 btn-xl3"
            >
              CREAR
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}

export default StoreCreate;
