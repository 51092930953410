import React, { Component, Fragment } from "react";
import { CREATE, GET_ONE, GET_LIST } from "react-admin";

import ApiProvider from "../../provider/apiProvider";
import MyLoading from "../utils/loading/Loading";
import TableView from "./TableView";

import { logUserActivity } from "../utils/logger/Logger";

class ContainerTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cars: [],
      status: "running",
      isResultsLoaded: false,
      selectedInputs: this.props.selectedInputs,
      id_optimization: 0,
      gestionMessage: "",
    };

    this.timer = 0;
    this.timeLapse = 0;
  }

  getOptimizationResults = (id) => {
    this.setState({ isResultsLoaded: false });
    ApiProvider(GET_LIST, `optimization/${id}/results`, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      if (res.data.status === "not results found") {
        this.setState({ status: "not results found", isResultsLoaded: true });
      } else {
        var myJson = {};
        res.data.forEach((element) => {
          var myId = element["id_results"].toString();
          myJson[myId] = element;
        });
        this.setState({ cars: myJson, isResultsLoaded: true });
      }
    });
  };

  getOptimizationStatus = () => {
    console.log("Calling results...");
    this.timeLapse += 1;
    if (this.timeLapse === 20) {
      clearTimeout(this.timer);
      this.setState({ status: "not results found" });
      this.setState({ isResultsLoaded: true });
    } else {
      ApiProvider(GET_ONE, "optimization", {
        id: this.state.id_optimization,
      }).then((res) => {
        this.setState({ status: res.data.status });
        if (["optimal", "optimal-error"].includes(res.data.status)) {
          clearTimeout(this.timer);
          this.getOptimizationResults(this.state.id_optimization);
        } else if (res.data.status === "not results found") {
          clearTimeout(this.timer);
          this.setState({ isResultsLoaded: true });
        } else if (res.data.status === "running" && this.timeLapse === 1) {
          this.checkOptimizationStatus();
        }
      });
    }
  };

  checkOptimizationStatus = () => {
    this.timer = setInterval(this.getOptimizationStatus.bind(this), 15000);
  };

  createNewOptimization = () => {
    ApiProvider(CREATE, "optimization", {
      data: {
        number_trailers: this.state.selectedInputs.carNumber,
        zone: this.state.selectedInputs.zone,
      },
    }).then((res) => {
      logUserActivity(
        `optmization,CREATE,In Zone: ${this.state.selectedInputs.zone} optimize ${this.state.selectedInputs.carNumber} vehicles`
      );
      this.setState({ id_optimization: res.data.id_optimization });
      this.getOptimizationStatus();
    });
  };

  getGestionResult = (result) => {
    this.setState({
      status: "error gestion",
      gestionMessage: "[" + String(result) + "]",
    });
  };

  componentDidMount() {
    this.createNewOptimization();
  }

  render() {
    if (this.state.status === "running" || !this.state.isResultsLoaded) {
      return (
        <Fragment>
          <MyLoading />
          <h4 className="msg">Vehículos</h4>
        </Fragment>
      );
    } else if (this.state.status === "not results found") {
      return (
        <h5 className="myTableCenter">
          No encontramos resultados para la optimización.
        </h5>
      );
    } else if (this.state.status === "error gestion") {
      return (
        <h5 className="myTableCenter">
          Las siguientes patentes no pueden ser gestionadas{" "}
          {this.state.gestionMessage}, el resto de tu patentes estan en proceso
          de gestión.
        </h5>
      );
    } else {
      return (
        <TableView
          contentData={this.state.cars}
          idOptimization={this.state.id_optimization}
          getOptimizationResults={this.getOptimizationResults}
          selectQuantity={this.state.selectedInputs.carNumber}
          getGestionResult={this.getGestionResult}
        />
      );
    }
  }
}

export default ContainerTable;
