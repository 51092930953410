import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  SimpleForm,
  Edit,
  Create,
  Toolbar,
  SaveButton,
  DeleteButton
} from "react-admin";
import { withStyles } from "@material-ui/core";

const toolbarStyles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  }
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const ExpandEdit = props => (
  <Edit {...props} title=" ">
    <SimpleForm form={`centros_edit_${props.id}`} toolbar={<CustomToolbar />}>
      <h2 style={{ width: "auto" }}>
        Shortcut para ver detalles del registro, editar o eliminar
      </h2>
      <TextInput source="name" />
      <TextInput source="address" />
    </SimpleForm>
  </Edit>
);

export const CenterList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      title="Centros de Distribución"
      exporter={false}
      bulkActions={false}
      toolbar={<CustomToolbar />}
    >
      <Datagrid
        expand={permissions === "trafico_full" ? <ExpandEdit /> : null}
        rowClick={permissions === "trafico_full" ? "expand" : null}
      >
        <TextField source="id" label="Id" />
        <TextField source="name" label="Nombre" />
        <TextField source="address" label="Dirección" />
        {permissions === "trafico_full" ? <EditButton /> : null}
      </Datagrid>
    </List>
  );
};

export const CenterEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="name" />
      <TextInput source="address" />
    </SimpleForm>
  </Edit>
);

export const CenterCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
      <TextInput source="address" />
    </SimpleForm>
  </Create>
);
