import React, { Component } from "react";

import { Card, CardContent, Button } from "@material-ui/core/";

import Snack from "../../utils/snackbar/SnackBar";

import MotiveCharts from "../Motive/Container";
import OnCdCharts from "../OnCd/Container";
import ExitFlowCharts from "../ExitFlow/Container";
import MotiveUserCharts from "../UserMotive/Container";
import AdvancesUserCharts from "../Advance/Container";
import ColisionsCharts from "../Colision/Container";

import { GET_LIST } from "react-admin";

import ApiProvider from "../../../provider/apiProvider";

import Select from "react-select";

import scrollToComponent from "react-scroll-to-component";

import styled from "@emotion/styled";

const DashboardNav = styled("div")`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
  margin-top: 2em;
`;

const FilterContainer = styled("div")`
  display: flex;
  align-items: baseline;

  .select {
    margin-right: 1em;
  }
`;

const ButtonContainer = styled("div")`
  button {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0.15em;
    background-color: #3f51b5;
    padding: 0.5em 1.4em;
    border: none;

    &:hover {
      background-color: #526bd1;
    }
  }

  button:not(:last-child) {
    margin-right: 5px;
  }
`;

const apiUrl = process.env.REACT_APP_API_URL;

class DashboardChartsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: "",
      icon: "success",
      loadDashboadsBtnStyle: myStyle.otherDashboardsBtn,
      btnText: "Cargados",

      selectedZone: "RM",
      zoneSelect: [],
      zoneFilter: "zone=RM",
      zoneDropdown: "RM",

      motiveData: [],
      isMotiveDataLoaded: false,

      onCdData: [],
      isOnCdDataLoaded: false,

      departuresByHourData: [],
      isDeparturesByHourDataLoaded: false,

      departuresUserData: [],
      isDeparturesUserDataLoaded: false,

      advancesUsersData: [],
      isAdvancesUsersDataLoaded: false,

      colisionsData: [],
      isColisionsDataLoaded: false,

      tenderingData: 0,
      isTenderingDataLoaded: false,
    };
  }

  handleZoneFilter(zoneToFilter) {
    this.setState({
      selectedZone: zoneToFilter,
    });
    this.callApi_CountTrailersMotive();
    this.callApi_Colisions();
    this.callApi_CountAdvancesUsers();
    this.callApi_DeparturesByHour();
    this.callApi_DepartureMotiveUser();
    this.callApi_CountTrailersOnCd();
  }

  handleZoneChange(zoneToFilter) {
    let filterString = `zone=${zoneToFilter}`;
    if (zoneToFilter === "Todas") {
      filterString = "";
    }

    this.setState({
      zoneFilter: filterString,
      zoneDropdown: zoneToFilter,
    });
  }

  async callApi_Colisions() {
    let zoneFilter = this.state.zoneFilter;
    await fetch(`${apiUrl}/dashboard/collisions-chart?${zoneFilter}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          this.setState({
            colisionsData: res.data,
            isColisionsDataLoaded: true,
          });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cargar información de colisiones",
            isColisionsDataLoaded: false,
          });
        }
      });
  }

  async callApi_Tendering() {
    await fetch(`${apiUrl}/dashboard/tendering-percent?`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({
            tenderingData: res.data,
            isTenderingDataLoaded: true,
          });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cargar información de tendering",
            isTenderingDataLoaded: false,
          });
        }
      });
  }

  async callApi_CountTrailersMotive() {
    await fetch(
      `${apiUrl}/dashboard/count-trailers-motive?${this.state.zoneFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Authorization: `jwt ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({ motiveData: res.data, isMotiveDataLoaded: true });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cargar información",
            isMotiveDataLoaded: false,
          });
        }
      });
  }

  async callApi_CountTrailersOnCd() {
    this.setState({
      loadDashboadsBtnStyle: myStyle.otherDashboardsBtnPressed,
      btnText: "Cargando",
    });
    await fetch(
      `${apiUrl}/dashboard/count-trailers-on-cd?${this.state.zoneFilter}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Authorization: `jwt ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          const sortedData = res.data.sort((element_a, element_b) => {
            return element_a.name - element_b.name
          })
          this.setState({
            open: true,
            icon: "success",
            message: "Trailers on CD cargados con éxito.",
            onCdData: sortedData,
            isOnCdDataLoaded: true,
          });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cargar par de la información",
            isOnCdDataLoaded: false,
          });
        }
        this.setState({
          loadDashboadsBtnStyle: myStyle.otherDashboardsBtn,
          btnText: "Cargados",
        });
      });
  }

  async callApi_DeparturesByHour() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    };
    const distributionCenterUrls = this.state.distributionCenter.map(
      (cdIds) => ({
        distributionCenter: cdIds,
        url: `${apiUrl}/dashboard/count-departures-by-hour?center_distribution=${cdIds}&${[
          this.state.zoneFilter,
        ]}`
      })
    );
    distributionCenterUrls.push(
      {url: `${apiUrl}/dashboard/count-departures-by-hour?${[this.state.zoneFilter]}`}
    );

    Promise.all(
      distributionCenterUrls.map((cd) =>
        fetch(cd.url, options)
          .then((res) => res.json())
          .then((res) => ({...res, distributionCenter: cd.distributionCenter}))
          .catch((e) => console.log(e))
      )
    ).then((data) => {
      this.setState({
        departuresByHourData: data,
        isDeparturesByHourDataLoaded: true,
      });
    });
  }

  async callApi_DepartureMotiveUser() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    };

    const distributionCenterUrls = this.state.distributionCenter.map(
      (cdId) => ({
        distributionCenter: cdId,
        url: `${apiUrl}/dashboard/count-departures-motive-by-username?center_distribution=${cdId}&${this.state.zoneFilter}`
      })
    );

    Promise.all(
      distributionCenterUrls.map((cd) =>
        fetch(cd.url, options)
          .then((res) => res.json())
          .then((res) => ({...res, distributionCenter: cd.distributionCenter}))
          .catch((e) => console.log(e))
      )
    ).then((data) => {
      this.setState({
        departuresUserData: data,
        isDeparturesUserDataLoaded: true,
      });
    });
  }

  async callApi_CountAdvancesUsers() {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    };

    const distributionCenterUrls = this.state.distributionCenter.map(
      (cdId) => ({
        distributionCenter: cdId,
        url: `${apiUrl}/dashboard/count-advances-users?center_distribution=${cdId}&${this.state.zoneFilter}`
      })
    );

    Promise.all(
      distributionCenterUrls.map((cd) =>
        fetch(cd.url, options)
          .then((res) => res.json())
          .then((res) => ({...res, distributionCenter: cd.distributionCenter}))
          .catch((e) => console.log(e))
      )
    ).then((data) => {
      this.setState({
        advancesUsersData: data.sort((element_a, element_b) => element_a.status - element_b.status),
        isAdvancesUsersDataLoaded: true,
      });
    });
  }

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
  };

  callApi_Zones = () => {
    ApiProvider(GET_LIST, "zones", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let improvedZoneData = res.data.map((zone) => ({
        label: zone.name,
        value: zone.name,
        name: zone.name,
        id: zone.id,
      }));

      improvedZoneData.push({
        label: "Todas",
        value: "Todas",
        name: "Todas",
        id: "99",
      });

      this.setState({
        zoneSelect: improvedZoneData,
      });
    });
  };

  callApiDistributionCenter = async () => {
    await ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      const cds = res.data.map((cd) => cd.id);
      this.setState({ distributionCenter: cds });
    });
  };

  async componentDidMount() {
    await this.callApiDistributionCenter();
    this.callApi_Zones();
    this.callApi_CountTrailersMotive();
    this.callApi_Colisions();
    this.callApi_Tendering();
    this.callApi_CountAdvancesUsers();
    this.callApi_DeparturesByHour();
    this.callApi_DepartureMotiveUser();
    this.callApi_CountTrailersOnCd();
  }

  render() {
    return (
      <Card>
        <CardContent style={myStyle.hStyle}>
          <h1>{"Bienvenido(a) " + localStorage.getItem("name")}</h1>
          <p style={myStyle.pStyle}>
            Tu permiso de usuario es:{" "}
            <span style={myStyle.spanStyle}>
              {localStorage.getItem("role")}
            </span>
          </p>
          <DashboardNav>
            <FilterContainer>
              <Select
                defaultValue={{ label: "RM", value: "RM" }}
                id="ZoneSelect"
                placeholder="Filtro por zona"
                className="select"
                classNamePrefix="select"
                options={this.state.zoneSelect.map((opt) => ({
                  label: opt.label,
                  value: opt.value,
                }))}
                onChange={(opt) => this.handleZoneChange(opt.value)}
              />
              <Button
                variant="outlined"
                onClick={() => this.handleZoneFilter(this.state.zoneDropdown)}
              >
                Cambiar Zona
              </Button>
            </FilterContainer>
            <ButtonContainer>
              <button onClick={() => scrollToComponent(this.Cargados)}>
                Cargados
              </button>
              <button onClick={() => scrollToComponent(this.FlujoSalidas)}>
                Flujo Salidas
              </button>
              <button onClick={() => scrollToComponent(this.MotivosUsuarios)}>
                Motivos por Usuarios
              </button>
              <button onClick={() => scrollToComponent(this.AdelantosUsuarios)}>
                Adelantos por Usuario
              </button>
              <button onClick={() => scrollToComponent(this.Colisiones)}>
                Colisiones
              </button>
            </ButtonContainer>
          </DashboardNav>
          
          {this.state.isMotiveDataLoaded && this.state.isColisionsDataLoaded  && this.state.isTenderingDataLoaded ? (
            <MotiveCharts
              data={this.state.motiveData}
              colision={this.state.colisionsData}
              tendering={this.state.tenderingData}
              zone={
                this.state.selectedZone !== "Todas"
                  ? this.state.selectedZone
                  : ""
              }
            />
          ) : (
            <Card>
              <CardContent>
                <h3>Información no disponible, intente reiniciar su sesión.</h3>
              </CardContent>
            </Card>
          )}
          {this.state.isOnCdDataLoaded && this.state.isColisionsDataLoaded ? (
            <section
              className="cargados"
              ref={(section) => {
                this.Cargados = section;
              }}
            >
              <OnCdCharts
                data={this.state.onCdData}
                zone={
                  this.state.selectedZone !== "Todas"
                    ? this.state.selectedZone
                    : ""
                }
              />{" "}
            </section>
          ) : null}
          {this.state.isAdvancesUsersDataLoaded ? (
            <section
              className="adelantosUsuarios"
              ref={(section) => {
                this.AdelantosUsuarios = section;
              }}
            >
              <AdvancesUserCharts
                data={this.state.advancesUsersData}
                zone={
                  this.state.selectedZone !== "Todas"
                    ? this.state.selectedZone
                    : ""
                }
              />{" "}
            </section>
          ) : null}
          {this.state.isDeparturesByHourDataLoaded ? (
            <section
              className="flujoSalidas"
              ref={(section) => {
                this.FlujoSalidas = section;
              }}
            >
              <ExitFlowCharts
                data={this.state.departuresByHourData}
                zone={
                  this.state.selectedZone !== "Todas"
                    ? this.state.selectedZone
                    : ""
                }
              />{" "}
            </section>
          ) : null}
          {this.state.isDeparturesUserDataLoaded ? (
            <section
              className="motivosUsuario"
              ref={(section) => {
                this.MotivosUsuarios = section;
              }}
            >
              <MotiveUserCharts
                data={this.state.departuresUserData}
                distributionCenter={this.state.distributionCenter}
                zone={
                  this.state.selectedZone !== "Todas"
                    ? this.state.selectedZone
                    : ""
                }
              />
            </section>
          ) : null}
          {this.state.isColisionsDataLoaded ? (
            <section
              className="colisiones"
              ref={(section) => {
                this.Colisiones = section;
              }}
            >
              <ColisionsCharts
                data={this.state.colisionsData}
                zone={
                  this.state.selectedZone !== "Todas"
                    ? this.state.selectedZone
                    : ""
                }
              />{" "}
            </section>
          ) : null}
        </CardContent>
        <Snack
          open={this.state.open}
          icon={this.state.icon}
          message={this.state.message}
        />
      </Card>
    );
  }
}

const myStyle = {
  inputStyle: {
    display: "flex",
  },
  spanStyle: {
    border: "black 1px solid",
    padding: 5,
  },
  pStyle: {
    fontWeight: "bold",
  },
  hStyle: {
    textAlign: "center",
  },
  paStyle: {
    background: "rgb(63, 81, 181)",
    alignItems: "center",
    display: "inline-block",
    paddingLeft: 10,
    paddingRight: 10,
  },
  otherDashboardsBtn: {
    marginBottom: 20,
  },
  otherDashboardsBtnPressed: {
    marginBottom: 20,
    background: "red",
    pointerEvents: "none",
    cursor: "not-allowed",
  },
};

export default DashboardChartsProvider;
