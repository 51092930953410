import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";
import { GET_LIST, CREATE } from "react-admin";

import { Label } from "semantic-ui-react";
import Select from "react-select";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class FixplanningForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "FIXPLANNING",

      endpoint: "fixed-planning",

      center_distribution: 0,
      store: 0,
      ambient_desc: "",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,

      cdSelect: [],
      ambientSelect: [],
      is_SelectLoaded: false,
      is_SelectAmbientLoaded: false,
    };
  }

  callApi_Distribution() {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      this.setState({ cdSelect: res.data, is_SelectLoaded: true });
    });
  }

  callApi_Ambient() {
    ApiProvider(GET_LIST, "ambient-desc", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      if (res.data.status === 0) {
        this.setState({
          ambientSelect: res.data.data,
          is_SelectAmbientLoaded: true,
        });
      } else {
        console.log("No se puede cargar Tipos de mercaderia.");
      }
    });
  }

  handleAmbient = (value) => {
    if (value === undefined) {
      value = "";
    }
    return value;
  };

  handleCD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    this.setState({ center_distribution: id_cd });
  };

  handleInput = (state, value) => {
    if (value === "") {
      value = 0;
    }
    let myinput = parseInt(value);
    if (state === "store") {
      this.setState({ store: myinput });
    } else if (state === "monday") {
      this.setState({ monday: myinput });
    } else if (state === "tuesday") {
      this.setState({ tuesday: myinput });
    } else if (state === "wednesday") {
      this.setState({ wednesday: myinput });
    } else if (state === "thursday") {
      this.setState({ thursday: myinput });
    } else if (state === "friday") {
      this.setState({ friday: myinput });
    } else if (state === "saturday") {
      this.setState({ saturday: myinput });
    } else if (state === "sunday") {
      this.setState({ sunday: myinput });
    }
  };

  //Create Fixplanning
  handleCreate = () => {
    if (
      this.state.center_distribution === 0 ||
      this.state.store === 0 ||
      this.state.ambient_desc === ""
    ) {
      this.props.handleSnack(
        true,
        "warning",
        "Debe completar los datos faltantes."
      );
    } else {
      ApiProvider(CREATE, this.state.endpoint, {
        data: {
          center_distribution: this.state.center_distribution,
          store: this.state.store,
          ambient_desc: this.state.ambient_desc,
          monday: this.state.monday,
          tuesday: this.state.tuesday,
          wednesday: this.state.wednesday,
          thursday: this.state.thursday,
          friday: this.state.friday,
          saturday: this.state.saturday,
          sunday: this.state.sunday,
        },
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(
            `fixed-planning,CREATE,${this.state.center_distribution}/${this.state.store}/${this.state.ambient_desc}`
          );
          this.props.handleSnack(true, "success", "Se ha agregado bloque.");
          this.props.handleFormHide(false);
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No se ha podido agregar bloque."
          );
        }
      });
    }
  };

  componentDidMount() {
    this.callApi_Distribution();
    this.callApi_Ambient();
  }

  render() {
    if (this.state.is_SelectLoaded && this.state.is_SelectAmbientLoaded) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CD</Label>
              <Select
                id="cdSelect"
                className="select"
                classNamePrefix="select"
                options={this.state.cdSelect.map((opt) => ({
                  label: opt.id,
                  value: opt.id,
                }))}
                onChange={(opt) => this.handleCD(opt.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIENDA</Label>
              <input
                onChange={(event) =>
                  this.handleInput("store", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIPO MERCADERIA</Label>
              <Select
                className="select"
                classNamePrefix="select"
                options={this.state.ambientSelect.map((opt) => ({
                  label: opt.ambient_desc,
                  value: opt.ambient_desc,
                }))}
                onChange={(opt) =>
                  this.setState({ ambient_desc: this.handleAmbient(opt.value) })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">LUNES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("monday", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">MARTES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("tuesday", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">MIERCOLES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("wednesday", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">JUEVES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("thursday", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">VIERNES</Label>
              <input
                onChange={(event) =>
                  this.handleInput("friday", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">SABADO</Label>
              <input
                onChange={(event) =>
                  this.handleInput("saturday", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">DOMINGO</Label>
              <input
                onChange={(event) =>
                  this.handleInput("sunday", event.target.value)
                }
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleCreate()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                CREAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">{this.state.title}</h4>
        </CardContent>
      );
    }
  }
}

export default FixplanningForm;
