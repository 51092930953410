import React, { Component } from "react";

import { withRouter } from "react-router";


import { Card, CardContent, Button } from "@material-ui/core/";

import Snack from "../components/utils/snackbar/SnackBar";

import DashboardChartsProvider from "../components/dashboardCharts/DashboardProvider/DashboardChartsProvider";

import { UPDATE, Title } from "react-admin";

import ApiProvider from "../provider/apiProvider";

import {hasCorrectRole} from "../components/utils/roleManager/RoleManager"

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Dashboard",
      name: "",
      role: "",
      actualPassword: "",
      newPassword: "",
      open: false,
      message: "",
      icon: "success",
      is_FormCalled: false,
    };
  }

  componentDidMount() {
    this.updateName();
  }

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
  };

  handleNewPassword = () => {
    if (this.state.newPassword === this.state.repeatPassword) {
      const localId = localStorage.getItem("id");
      const userName = localStorage.getItem("name");
      ApiProvider(UPDATE, "user", {
        id: localId,
        data: {
          username: userName,
          actual_password: this.state.actualPassword,
          new_password: this.state.newPassword,
        },
      }).then((res) => {
        let msg = res.data.data;
        if (res.data.status === 0) {
          this.handleSnack(true, "success", "Se ha cambiado contraseña.");
          setTimeout(function destroy() {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            localStorage.removeItem("role");
            window.location.assign("/");
          }, 3500);
        } else {
          if (!res.data.data) {
            msg = "no se pudo cambiar contraseña, intente de nuevo";
          }
          this.handleSnack(true, "error", `Error: ${msg}`);
        }
      });
    } else {
      this.handleSnack(true, "error", "Contraseñas ingresadas no coinciden");
    }
  };

  requireAutomaticLogin = () => {};

  updateName = () => {
    this.setState({ name: localStorage.getItem("name") });
    this.setState({ role: localStorage.getItem("role") });
  };

  render() {
    if (this.state.role === "reset_password") {
      return (
        <Card>
          <Title title={this.state.title} />
          <CardContent style={myStyle.hStyle}>
            <h1>{"Bienvenido " + this.state.name}</h1>
            <br></br>
            <p style={myStyle.pStyle}>
              Debes cambiar tu contraseña para poder acceder al resto del
              sistema:
            </p>
            <p>
              Tu nueva contraseña debe tener entre 7 y 14 caracteres, contener
              al menos una mayúscula, una minúscula y un número. <br></br> No
              debe tener patrones repetidos y debe ser distinta de tu nombre de
              usuario o tus contraseñas anteriores.
            </p>
            <br></br>

            <div style={myStyle.inputStyle}>
              <h5>Contraseña actual</h5>
              <input
                onChange={(event) =>
                  this.setState({ actualPassword: event.target.value })
                }
                defaultValue=""
                className="select form-control"
                type="password"
                required
              />
            </div>
            <br></br>

            <div style={myStyle.inputStyle}>
              <h5>Nueva Contraseña</h5>
              <input
                onChange={(event) =>
                  this.setState({ newPassword: event.target.value })
                }
                defaultValue=""
                className="select form-control"
                type="password"
                required
              />
            </div>
            <br></br>

            <div style={myStyle.inputStyle}>
              <h5>Reingresar</h5>
              <input
                onChange={(event) =>
                  this.setState({ repeatPassword: event.target.value })
                }
                defaultValue=""
                className="select form-control"
                type="password"
                required
              />
            </div>
            <br></br>

            <Button
              onClick={() => this.handleNewPassword()}
              variant="contained"
              component="span"
              className="btn btn-info btn-circle3 btn-xl3"
            >
              CAMBIAR
            </Button>

            <Snack
              open={this.state.open}
              icon={this.state.icon}
              message={this.state.message}
            />
          </CardContent>
        </Card>
      );
    } 
    if (hasCorrectRole(["supervisor_tienda"])) {
      this.props.history.push("/agenda");
      return null;
    }
    if (hasCorrectRole(["transportista"])) {
      this.props.history.push("/resultados/regiones");
      return null;
    }
      
    return <DashboardChartsProvider />;
    
  }
}

const myStyle = {
  inputStyle: {
    display: "flex",
  },
  pStyle: {
    fontWeight: "bold",
  },
  hStyle: {
    textAlign: "center",
  },
};

export default withRouter(Dashboard);
