import  React, { Fragment } from 'react';

import './loadingStyle.css';


const Loading = ()=> ( 
    <Fragment>
        <div className="loading-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
        </div>
        <h2 className="msg1">Cargando...</h2>
    </Fragment>
)

export default Loading