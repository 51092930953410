import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";

import { GET_LIST } from "react-admin";
import { Label } from "semantic-ui-react";
import Select from "react-select";
import ApiProvider from "../../provider/apiProvider";

const apiUrl = process.env.REACT_APP_API_URL;
const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })

class TenderingFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: "tendering-dashboard/tendering-report",

      is_selectLoaded: false,

      allCommunes: [],

      regionSelect: [],
      is_regionSelectLoaded: false,
      region_number: 0,

      communeSelectRef: React.createRef(),
      communeSelect: [],
      is_communeSelectLoaded: false,
      commune_id: 0,

      carrierSelect: [],
      is_carrierSelectLoaded: false,
      carrier_id: 0,
      only_unknown_carriers: false
    };
  }

  /**
   * Filters commune data to get an unique array of regions, by region number.
   * @param {*} communes communes array [{id, name, num_region, name_region}]
   * @returns unique array of regions [{number, name}]
   */
  uniqueRegions = (communes) => {
    let map = {};
    for (let commune of communes) {

      map[commune.num_region] = {number: commune.num_region, name: commune.name_region};
    }
    
    return Object.values(map).sort((a, b) => sortAlphaNum(a.name, b.name));
  }

  loadCommunesSelect = (regionNumber) => {
    let communes = this.state.allCommunes;
    if(regionNumber) {
      communes = communes.filter(commune => commune.num_region === regionNumber);
    }

    let communeSelect = communes.map(commune => ({"value": commune.id, "label": commune.name})).sort((a, b) => sortAlphaNum(a.label, b.label));

    this.setState({
      commune_id: 0,
      communeSelect: communeSelect,
      is_communeSelectLoaded: true,
      is_selectLoaded: true,
    });
  }

  async callApi_Communes() {
    let res = await fetch(`${apiUrl}/communes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    });

    const { status, data } = await res.json();
    if (status === 0) {
      let regionSelect = this.uniqueRegions(data).map(region => ({"value": region.number, "label": region.name}));
      this.setState({
        allCommunes: data,
        regionSelect: regionSelect,
        is_regionSelectLoaded: true,
        is_selectLoaded: true
      });

      this.loadCommunesSelect(0);
    }
  }

  async callApi_Carriers() {
    let res = await fetch(`${apiUrl}/carrier`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    });

    const { status, data } = await res.json();
    if (status === 0) {
      let carriers = data.map(c => ({"id": c.id, "name": c.name}))
      carriers.push({"id": -1, "name": "NO INFO"})

      this.setState({
        carrierSelect: carriers,
        is_selectLoaded: true,
        is_carrierSelectLoaded: true,
      });
    }
  }

  async callApi_Endpoint() {
    let myFilter = {};

    if (this.state.carrier_id) {
      myFilter.carrier_id = this.state.carrier_id;
    }

    if (this.state.commune_id) {
      myFilter.commune_id = this.state.commune_id;
    }

    if (this.state.region_number) {
      myFilter.region_number = this.state.region_number;
    }

    if (this.state.only_unknown_carriers) {
      myFilter.only_unknown_carriers = this.state.only_unknown_carriers;
    }

    this.props.handleLoading(true);

    let res = await ApiProvider(GET_LIST, this.state.endpoint, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: myFilter,
    });

    let data = [];
    if (res.data.status === 0) {
      data = res.data.data;
    }

    this.props.handleFilter(data);
  };

  handleRegion = (value) => {
    let region_number = parseInt(value);
    this.loadCommunesSelect(region_number);
    this.state.communeSelectRef.current.select.clearValue();
    this.setState({ region_number: region_number });
  };

  handleCommune = (value) => {
    let commune_id = parseInt(value);
    this.setState({ commune_id: commune_id });
  };

  handleCarrier = (value) => {
    let carrier_id = parseInt(value);
    let only_unknown_carriers = carrier_id === -1
    this.setState({ carrier_id: carrier_id, only_unknown_carriers: only_unknown_carriers });
  };

  componentDidMount() {
    this.callApi_Carriers();
    this.callApi_Communes();
    this.callApi_Endpoint();
  }

  render() {
    const { is_selectLoaded, is_regionSelectLoaded, is_communeSelectLoaded, is_carrierSelectLoaded } = this.state;


    if (is_selectLoaded) {
      return (
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} className="inline">
            {is_regionSelectLoaded ?  (<Label className="myTableCenter myLabelSelect">REGION</Label>): null}
            {is_communeSelectLoaded ? (<Label className="myTableCenter myLabelSelect">LOCALIDAD</Label>): null}
            {is_carrierSelectLoaded ? (<Label className="myTableCenter myLabelSelect">TRANSPORTISTA</Label>): null }
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            {is_regionSelectLoaded ? (
              <Select
                id="regionSelect"
                className="select"
                classNamePrefix="select"
                isClearable="true"
                options={this.state.regionSelect.map((opt) => ({
                  label: opt.label,
                  value: opt.value,
                }))}
                onChange={(opt) => this.handleRegion(opt? opt.value: 0)}
              />
            ) : null}

            &nbsp;&nbsp;

            {is_communeSelectLoaded ? (
              <Select
                id="communeSelect"
                ref={this.state.communeSelectRef}
                className="select"
                classNamePrefix="select"
                isClearable="true"
                options={this.state.communeSelect.map((opt) => ({
                  label: opt.label,
                  value: opt.value,
                }))}
                onChange={(opt) => this.handleCommune(opt? opt.value: 0)}
              />
            ) : null}

            &nbsp;&nbsp;

            {is_carrierSelectLoaded ? (
              <Select
                id="carrierSelect"
                className="select"
                classNamePrefix="select"
                isClearable="true"
                options={this.state.carrierSelect.map((opt) => ({
                  label: opt.name,
                  value: opt.id,
                }))}
                onChange={(opt) => this.handleCarrier(opt? opt.value: 0)}
              />
            ) : null}
            
            &nbsp;&nbsp;

            <Button
              variant="outlined"
              style={filterStyles.fieldCorrection}
              onClick={() => this.callApi_Endpoint()}>

              Filtrar
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

const filterStyles = {
  fieldCorrection: {
    marginLeft: "10px",
  },
};

export default TenderingFilter;
