import React from "react";

import CardTitle from "../../utils/cardTitle/CardTitle";

import BuildTableRow from "./buildTableRow";

import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core/";

const keys = {
  "Cargado con plan": "Cargado ConPlan",
  "Cargado sin plan": "Cargado SinPlan",
  Adicionales: "Adicionales",
  "Bandera Blanca": "Bandera Blanca",
  Devoluciones: "Devoluciones",
  Perdidos: "Perdidos",
  Total: "total",
};

function onCdCharts({ data, zone }) {
  return (
    <div style={chartStyle.tableSection}>
      <Paper style={{ paddingTop: "20px" }}>
        <CardTitle main="CARGADOS" optional={zone} size="medium" />
        <hr />
        <Table style={chartStyle.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {data.map((distribution_center) => (
                <TableCell key={distribution_center.name} align="right">
                  {distribution_center.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <BuildTableRow data={data} keys={keys} />
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

const chartStyle = {
  tableSection: {
    marginTop: 20,
  },
  tableStyle: {
    width: 800,
    margin: "auto",
    paddingBottom: "20px",
  },
};

export default onCdCharts;
