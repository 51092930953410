import React, { Component } from "react";

import { CardContent, Grid, Button } from "@material-ui/core";

import { Label } from "semantic-ui-react";
import Select from "react-select";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

const apiUrl = process.env.REACT_APP_API_URL;

class CdForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: "distribution-center",

      id: 0,
      name: "",
      address: "",
      origin: "",
      lat: 0,
      lon: 0,

      communeList: [],
      isCommuneLoaded: false,
    };
  }

  async callApi_Communes() {
    await fetch(`${apiUrl}/communes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          const myArray = [];
          for (let i = 0; i < res.data.length; i++) {
            myArray.push({id: res.data[i].id, name:res.data[i].name});
          }
          this.setState({ communeList: myArray, isCommuneLoaded: true });
        } 
        else {
          this.props.handleSnack(
            true,
            "error",
            "No ha sido posible cargar datos de la comuna."
          );
          this.setState({ communeList: [] });
      }
    });
  }

  applyCreate = () => {
    let carrierData = {
      id: parseInt(this.state.id),
      name: this.state.name,
      address: this.state.address,
      origin: this.state.origin.toString(),
      lat: parseFloat(this.state.lat),
      lon: parseFloat(this.state.lon)
    }
    fetch(`${apiUrl}/${this.state.endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(carrierData),
    })
      .then((res) => {
        if (res.status === 201){
          this.props.handleSnack(
            true,
            "success",
            "Se ha creado el Centro de Distribución"
          );
          this.props.handleCreateCd({
            id: parseInt(this.state.id),
            name: this.state.name,
            address: this.state.address,
            origin: this.state.origin.toString(),
            lat: parseFloat(this.state.lat),
            lon: parseFloat(this.state.lon),
          });
          logUserActivity(`${this.state.endpoint},CREATE,${this.state.id}`);
        }
        else {
          this.props.handleSnack(
            true,
            "error",
            "No se ha podido crear el Centro de Distribución"
          );
        }
    });
  }

  handleCommuneChange = (label, value) => {
    this.setState({ origin: label })
  }

  componentDidMount() {
    this.callApi_Communes();
  }

  render() {
    return (
      <CardContent>
        <Grid container spacing={32}>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">ID</Label>
            <input
              type="text"
              onChange={(event) => this.setState({ id: event.target.value })}
              defaultValue={this.state.id}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">NOMBRE</Label>
            <input
              type="text"
              onChange={(event) => this.setState({ name: event.target.value })}
              defaultValue={this.state.name}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">DIRECCIÓN</Label>
            <input
              type="text"
              onChange={(event) =>
                this.setState({ address: event.target.value })
              }
              defaultValue={this.state.address}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">COMUNA</Label>
            <Select
                defaultValue={{ label: "Seleccione Comuna", value: "0" }}
                id="CommuneSelect"
                placeholder="Comuna"
                className="select"
                classNamePrefix="select"
                options={
                  this.state.communeList.map((opt) => ({
                  label: opt.name,
                  value: opt.id,
                }))
                }
                onChange={(opt) => this.handleCommuneChange(opt.label, opt.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">LATITUD</Label>
            <input
              type="number"
              onChange={(event) => this.setState({ lat: event.target.value })}
              defaultValue={this.state.lat}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">LONGITUD</Label>
            <input
              type="number"
              onChange={(event) => this.setState({ lon: event.target.value })}
              defaultValue={this.state.lon}
              className="select form-control"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="myTableCenter">
            <Button
              onClick={() => this.applyCreate()}
              variant="contained"
              component="span"
              className="btn btn-info btn-circle3 btn-xl3"
            >
              CREAR
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}

export default CdForm;
