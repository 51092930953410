import React from "react";
import { connect } from "react-redux";
import { Layout } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import MyAppBar from "./MyAppbar";
import MyMenu from "./MyMenu";

import { darkTheme, lightTheme } from "./Themes";

const styles = theme => ({
  content: {
    "& th": {
      fontSize: "1rem",
      "& span": { fontSize: "1rem" }
    },
    "& td": { fontSize: ".9rem" },
    "& span": { fontSize: ".9rem" }
  }
});

const CustomLayout = props => (
  <Layout {...props} appBar={MyAppBar} menu={MyMenu} />
);

export default connect(
  state => ({
    theme: state.theme === "dark" ? darkTheme : lightTheme
  }),
  {}
)(withStyles(styles)(CustomLayout));
