import React, { Component } from "react";
import { Divider, CardContent, Card, Button, Grid} from "@material-ui/core";
import { ExcelRenderer } from "react-excel-renderer";
import CdTable from "./CdTable";
import ControllerTable from "./ControllerTable";
import StoreMasterTable from "./StoreMasterTable";

import CdEdit from "./CdEdit";
import CdForm from "./CdForm";
import StoreMasterEdit from "./StoreMasterEdit";
import StoreMasterForm from "./StoreMasterForm";

import NotFound from "../utils/notFound/NotFound";
import Snack from "../utils/snackbar/SnackBar";

import styled from "@emotion/styled";

const ButtonsContainer = styled("div")`
  max-width: 800px;
  display: flex;
  justify-content: space-around;
`;

export const apiUrl = process.env.REACT_APP_API_URL;

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cdData: [],
      isCdDataLoaded: false,

      controllerData: [],
      isControllerDataLoaded: false,

      storeMasterData: [],
      isStoreMasterDataLoaded: false,

      sapData: [],

      displayOption: 0,

      open: false,
      message: "",
      icon: "success",

      showCdOrStores: true,

      passedObjToEdit: {},
    };
  }

  /**
   * Sets what component to display and pass data if necessary
   *
   * 0: Both tables
   * 1: Create Cd form
   * 2: Edit Cd form
   * 3: Create Store form
   * 4: Edit Store form
   * 5: Edit Controller
   * 6: Not found
   */
  setDisplayOption = (value, obj) => {
    if (obj === 0) {
      this.setState({ displayOption: value });
    } else {
      this.setState({ displayOption: value, passedObjToEdit: obj });
    }
  };

  /**
   * Sets if boolean to show CD table or StoreMaster table
   *
   * true: CD table
   * false: StoreMaster table
   *
   */
  setShowCdOrStores = () => {
    let newShowTableValue = !this.state.showCdOrStores;
    this.setState({ showCdOrStores: newShowTableValue });
  };

  setShowControllerStatus = () => {
    let newShowTableValue = !this.state.showControllerStatus;
    this.setState({ showControllerStatus: newShowTableValue });
  };

  setShowCdStatus = () => {
    let newShowTableValue = !this.state.showCdStatus;
    this.setState({ showCdStatus: newShowTableValue });
  };

  handleEditCd = (editedCd) => {
    let updatedCdData = this.state.cdData.map((cd) => {
      if (cd.id === editedCd.id) {
        cd = editedCd;
      }
      return cd;
    });

    this.setState({ displayOption: 0, cdData: updatedCdData });
  };

  handleCreateCd = (createdCd) => {
    let newCdData = this.state.cdData;
    newCdData.push(createdCd);
    this.setState({ displayOption: 0, cdData: newCdData });
  };

  handleEditController = (editedController) => {
    let updatedControllerData = this.state.controllerData.map((controller) => {
      if (controller.id === editedController.id) {
        controller = editedController;
      }
      return controller;
    });

    this.setState({ displayOption: 0, controllerData: updatedControllerData });
  };

  handleEditStore = (editedStore) => {
    let updatedStoreData = this.state.storeMasterData.map((store) => {
      if (store.store_id === editedStore.store_id) {
        store = editedStore;
      }
      return store;
    });

    this.setState({ displayOption: 0, storeMasterData: updatedStoreData });
  };

  handleCreateStore = (createdStore) => {
    let newStoreData = this.state.storeMasterData;
    newStoreData.push(createdStore);
    this.setState({ displayOption: 0, storeMasterData: newStoreData });
  };

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
  };

  handleFilter = (sapData) => {
    this.setState({ sapData: sapData });
  };

  async callApi_CenterDistribution() {
    await fetch(`${apiUrl}/distribution-center`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.length > 1) {
          this.setState({
            cdData: res,
            isCdDataLoaded: true,
          });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cargar información de CDs",
            isCdDataLoaded: false,
          });
        }
      });
  }

  async callApi_StoreMaster() {
    await fetch(`${apiUrl}/store-master`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.length > 1) {
          this.setState({
            storeMasterData: res,
            isStoreMasterDataLoaded: true,
          });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cargar información de Tiendas",
            isStoreMasterDataLoaded: false,
          });
        }
      });
  }

  async callApi_Controller() {
    await fetch(`${apiUrl}/configurations`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({
            controllerData: res.data,
            isControllerDataLoaded: true,
          });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cargar información de Controlador",
            isControllerDataLoaded: false,
            controllerData: [],
          });
        }
      });
  }

  updateConfiguration = (id, status) => {
    fetch(`${apiUrl}/configurations/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ status: !status }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.callApi_Controller();
          this.setState({
            open: true,
            icon: "success",
            message: "Algoritmo modificado",
          });
        } else {
          this.setState({
            open: true,
            icon: "error",
            message: "Error: no se ha podido cambiar algoritmo",
          });
        }
      });
  };

  handleDelete = (id) => {
    let content = this.state.storeMasterData.filter(
      (row) => row.store_id !== id
    );
    this.setState({
      open: true,
      icon: "success",
      myCounter: "Se ha eliminado tienda.",
      storeMasterData: content,
    });
  };

  handleDeleteError = () => {
    this.setState({
      open: true,
      icon: "error",
      myCounter: "No ha sido posible eliminar tienda.",
    });
  };

  convertDate = (excelDate) =>{
    var newdate = new Date(parseInt(excelDate - (25567 + 1)) * 86400 * 1000);

    let year = newdate.getFullYear();
    let month = newdate.getMonth()+1;
    let dt = newdate.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    return dt+'-' + month + '-'+year;
  };

  handleUpload = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        try {
          let rows = resp.rows;
          let checkHead = ["Destinatario", 
                           "N_Transporte", 
                           "Fecha_Salida_CD", 
                           "Hora_Salida_CD",
                           "Transportista",
                           "Nombre_Transportista",
                           "Chofer",
                           "Rut_Chofer",
                           "Localidad",
                           "trailer_plate",
                           "CD",
                           "Chasis"];

          if (
            resp.rows[0][0].trim() === checkHead[0] &&
            resp.rows[0][1].trim() === checkHead[1] &&
            resp.rows[0][2].trim() === checkHead[2] &&
            resp.rows[0][3].trim() === checkHead[3] &&
            resp.rows[0][4].trim() === checkHead[4] &&
            resp.rows[0][5].trim() === checkHead[5] &&
            resp.rows[0][6].trim() === checkHead[6] &&
            resp.rows[0][7].trim() === checkHead[7] &&
            resp.rows[0][8].trim() === checkHead[8] &&
            resp.rows[0][9].trim() === checkHead[9] &&
            resp.rows[0][10].trim() === checkHead[10] &&
            resp.rows[0][11].trim() === checkHead[11]
          ) {
            rows.shift();
            let array = [];
            for (var key in rows) {
              let myJson = {};
              if (rows[key][0] === undefined) {
                break;
              }

              myJson["route"] = parseInt(rows[key][0]);
              myJson["n_carrier"] = parseInt(rows[key][1]);
              myJson["date_exit"] = this.convertDate(rows[key][2]);
              myJson["hour"] = rows[key][3];
              myJson["carrier"] = parseInt(rows[key][4]);
              myJson["carrier_name"] = rows[key][5];
              myJson["driver"] = rows[key][6];
              myJson["driver_id"] = parseInt(rows[key][7]);
              myJson["origin"] = rows[key][8];
              myJson["trailer_plate"] = rows[key][9];
              myJson["center_distribution"] = parseInt(rows[key][10]);
              myJson["is_chasis"] = rows[key][11];

              array.push(myJson);
            }

            fetch(`${apiUrl}/departures-sap`, {
              method: "PATCH",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
                Authorization: `jwt ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify(array),
            })
              .then((res) => res.json())
              .then((res) => {
                if (res.status === 0) {
                  this.setState({
                    open: true,
                    icon: "success",
                    message: "Datos actualizados",
                  });
                } else {
                  this.setState({
                    open: true,
                    icon: "error",
                    message: "Error al actualizar los datos",
                  });
                }
              })
              .catch((error) => {
                
              });
          }
          else{
            this.setState({
              open: true,
              icon: "error",
              message: "Error en el formato del archivo",
            });
          }
        } catch (error) {     
          this.setState({
            open: true,
            icon: "error",
            message: "Error de conexión con el servidor"
          });     
          console.log(error);
        }
      }
    });
  };

  componentDidMount() {
    this.callApi_StoreMaster();
    this.callApi_CenterDistribution();
    this.callApi_Controller();
  }

  render() {
    if (this.state.displayOption === 0) {
      return (
        <Card>
          <CardContent>
            <h1 className="myTitle">PANEL DE CONTROL</h1>
            <Divider />
            <br></br>

            {!this.state.showCdOrStores ? (
              <ButtonsContainer>
                <Button
                  onClick={() => this.setShowCdOrStores()}
                  variant="contained"
                  component="span"
                  color="primary"
                >
                  Mostrar Centros de Distribución
                </Button>
                <Button
                  onClick={() => this.setDisplayOption(3, 0)}
                  variant="contained"
                  component="span"
                  color="primary"
                >
                  Crear Tienda
                </Button>
              </ButtonsContainer>
            ) : (
              <ButtonsContainer>
                <Button
                  onClick={() => this.setShowCdOrStores()}
                  variant="contained"
                  component="span"
                  color="primary"
                >
                  Mostrar Maestro de tiendas
                </Button>
                <Button
                  onClick={() => this.setDisplayOption(1, 0)}
                  variant="contained"
                  component="span"
                  color="primary"
                >
                  Crear Centro de Distribución
                </Button>
                <Button
                  onClick={() => this.setDisplayOption(5, 0)}
                  variant="contained"
                  component="span"
                  color="primary"
                >
                  CONTROLADOR
                </Button>                
              </ButtonsContainer>             
              
            )}
            <br></br>
            &nbsp;&nbsp;
                <label htmlFor="contained-button-file" id="labelSap">
                    Cargar SAP: &nbsp;
                </label>
                <input
                    id="contained-button-file"
                    className=""
                    multiple
                    type="file"
                    onChange={this.handleUpload}
                  />
            <br></br>            
            <br></br>
            <br></br>

            {this.state.showCdOrStores ? ( 
              this.state.isCdDataLoaded ? (
                <CdTable
                  cdData={this.state.cdData}
                  setDisplayOption={this.setDisplayOption}
                />
              ) : null
            ) : this.state.isStoreMasterDataLoaded ? (
              <StoreMasterTable
                storeMasterData={this.state.storeMasterData}
                setDisplayOption={this.setDisplayOption}
                handleDelete={this.handleDelete}
                handleDeleteError={this.handleDeleteError}
              />
            ) : null}
            <br></br>
            <br></br>
          </CardContent>
          <Snack
            open={this.state.open}
            icon={this.state.icon}
            message={this.state.message}
          />
        </Card>
      );
    } else if (this.state.displayOption === 1) {
      return (
        <Card>
          <CardContent>
            <h1 className="myTitle">Crear CD</h1>
            <Divider />
            <br></br>

            <CdForm
              handleCreateCd={this.handleCreateCd}
              handleSnack={this.handleSnack}
            />

            <Snack
              open={this.state.open}
              icon={this.state.icon}
              message={this.state.message}
            />
          </CardContent>
        </Card>
      );
    } else if (this.state.displayOption === 2) {
      return (
        <Card>
          <CardContent>
            <h1 className="myTitle">EDITAR CD</h1>
            <Divider />
            <br></br>

            <CdEdit
              cdToEdit={this.state.passedObjToEdit}
              handleEditCd={this.handleEditCd}
              handleSnack={this.handleSnack}
            />

            <Snack
              open={this.state.open}
              icon={this.state.icon}
              message={this.state.message}
            />
          </CardContent>
        </Card>
      );
    } else if (this.state.displayOption === 3) {
      return (
        <Card>
          <CardContent>
            <h1 className="myTitle">Crear Tienda</h1>
            <Divider />
            <StoreMasterForm
              handleCreateStore={this.handleCreateStore}
              handleSnack={this.handleSnack}
            />
            <Snack
              open={this.state.open}
              icon={this.state.icon}
              message={this.state.message}
            />
          </CardContent>
        </Card>
      );
    } else if (this.state.displayOption === 4) {
      return (
        <Card>
          <CardContent>
            <h1 className="myTitle">EDITAR TIENDA</h1>
            <Divider />
            <br></br>

            <StoreMasterEdit
              storeToEdit={this.state.passedObjToEdit}
              handleEditStore={this.handleEditStore}
              handleSnack={this.handleSnack}
            />

            <Snack
              open={this.state.open}
              icon={this.state.icon}
              message={this.state.message}
            />
          </CardContent>
        </Card>
      );
    } else if (this.state.displayOption === 5) {
      return (
        <Card>
          <CardContent>
            <h1 className="myTitle">PANEL DE CONTROL</h1>
            <Divider />
            <br></br>
            {this.state.isControllerDataLoaded ? (
              <ControllerTable
                controllerData={this.state.controllerData}
                setDisplayOption={this.setDisplayOption}
                updateConfiguration={this.updateConfiguration}
              />
            ) : null}
            <Grid item xs={12} sm={12} className="myTableRight">
              <Button
                onClick={() => this.setDisplayOption(0)}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                Volver
              </Button>
            </Grid>
            <Snack
              open={this.state.open}
              icon={this.state.icon}
              message={this.state.message}
            />
          </CardContent>
        </Card>
      );
   

    } else {
      return <NotFound />;
    }
  }
}

export default Container;
