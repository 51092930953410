import React, { useState } from "react";
import {
  Button,
  Divider,
  CardContent,
  Card,
  Grid,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import { Title } from "react-admin";

import Filter from "../utils/filter/TableFilter";
import CardTitle from "../utils/cardTitle/CardTitle";
import ResultsRegionsTable from "./ResultsRegionsTable";
import MyLoading from "../utils/loading/Loading";
import ResultsRegionsLegend from "./ResultsRegionsLegend";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";
import { handleTimeExitFormat } from "./utils";
import Workbook from "react-excel-workbook";

export default function Container() {
  const [zoneFilter, setZoneFilter] = useState("");
  const [tableContent, setTableContent] = useState([]);
  const [isLoadedTable, setIsLoadedTable] = useState(false);
  const [open, setOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [icon, setIcon] = useState("success");
  const [status, setStatus] = useState(3);
  const permission_Gestion = [
    "operacion_trafico_1_supervisor",
    "operacion_trafico_1_full",
    "operacion_trafico_2_supervisor",
    "operacion_trafico_2_full",
    "operacion_1_supervisor",
    "operacion_1_full",
    "operacion_2_supervisor",
    "operacion_2_full",
    "trafico",
    "trafico_full",
    "transportista",
  ];
  const permission_Visibility = [];
  const title = "RESULTADOS";
  const endpoint = "departures";
  const defaultZone = "REGIONES";
  const permission = localStorage.getItem("role");

  const handleZoneFilter = (zoneName) => {
    setZoneFilter(zoneName);
  };

  const handleFilter = (tableContent) => {
    setTableContent(tableContent);
    setIsLoadedTable(true);
  };

  const handleTableHide = (status) => {
    setStatus(status);
  };

  const handleDelete = (id) => {
    setOpen(true);
    setIcon("success");
    setFeedbackMessage("Se ha eliminado salida.");
  };

  const handleDeleteError = () => {
    setOpen(true);
    setIcon("error");
    setFeedbackMessage("No ha sido posible eliminar salida.");
  };

  const handleEdit = () => {
    setOpen(true);
    setIcon("success");
    setFeedbackMessage("Se ha editado salida.");
    setIsLoadedTable(true);
  };

  const handleEditError = () => {
    setOpen(true);
    setIcon("error");
    setFeedbackMessage("No ha sido posible editar la salida.");
  };

  const handleStyleSnack = () => {
    if (icon === "success") {
      return "mySnackBarSuccess";
    } else if (icon === "error") {
      return "mySnackBarError";
    } else if (icon === "warning") {
      return "mySnackBarWarning";
    }
  };

  const handleIconSnack = () => {
    if (icon === "success") {
      return <CheckCircleIcon />;
    } else if (icon === "error") {
      return <ErrorIcon />;
    } else if (icon === "warning") {
      return <WarningIcon />;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns_data = () => {
    return tableContent.reduce((acc, content) => {
      if (content.hasOwnProperty("visits") && Array.isArray(content.visits)) {
        content.visits.forEach((visit) => {
          acc.push({
            trailer_plate: content.trailer_plate,
            center_distribution: content.center_distribution,
            time_exit: content.time_exit,
            hour: content.hour,
            carrier_name: content.carrier_name,
            username: content.username,
            route: content.route,
            motive: content.motive,
            id_store: visit.id_store,
            schedule_start: visit.schedule_start,
            sequence: visit.sequence,
            arrival: visit.arrival,
          });
        });
      }
      return acc;
    }, []);
  };

  return (
    (permission_Gestion.includes(permission) ||
      permission_Visibility.includes(permission)) && (
      <Card style={{ minHeight: "400px" }}>
        <CardContent>
          <Title title={title} />
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <CardTitle optional={defaultZone} main={title} />
            </Grid>
            <Grid item>
              <Workbook
                filename="RESULTADOS_REGIONES.xlsx"
                element={
                  <Button variant="outlined" color="secondary">
                    DESCARGAR
                  </Button>
                }
              >
                <Workbook.Sheet data={() => columns_data()} name="RESULTADOS">
                  <Workbook.Column label="PATENTE" value="trailer_plate" />
                  <Workbook.Column label="CD" value="center_distribution" />
                  <Workbook.Column
                    label="SALIDA GLS"
                    value={(row) => handleTimeExitFormat(row.time_exit, row.hour)}
                  />
                  <Workbook.Column label="TRANSPORTISTA" value="carrier_name" />
                  <Workbook.Column label="RUTA" value="route" />
                  <Workbook.Column label="USUARIO" value="username" />
                  <Workbook.Column label="MOTIVO" value="motive" />
                  <Workbook.Column label="LOCAL" value="id_store" />
                  <Workbook.Column label="AGENDA" value="schedule_start" />
                  <Workbook.Column label="ORDEN" value="sequence" />
                  <Workbook.Column label="FH LLEGADA" value="arrival" />
                </Workbook.Sheet>
              </Workbook>
            </Grid>
          </Grid>
          <Divider />
          <Grid container style={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <Filter
                name={title}
                endpoint={endpoint}
                defaultZone={defaultZone}
                handleFilter={handleFilter}
                handleTableHide={handleTableHide}
                handleZoneFilter={handleZoneFilter}
                specialModes='["REGIONS", "MULTIZONES"]'
              />
              <ResultsRegionsLegend></ResultsRegionsLegend>
              {status === 0 && (
                <ResultsRegionsTable
                  tableContent={tableContent}
                  isLoadedTable={isLoadedTable}
                  handleDelete={handleDelete}
                  handleDeleteError={handleDeleteError}
                  handleEdit={handleEdit}
                  handleEditError={handleEditError}
                />
              )}
              {status === 1 && (
                <center style={{ paddingTop: 50 }}>
                  <h1>No hay resultados disponibles</h1>
                </center>
              )}
              {status === 3 && (
                <center>
                  <MyLoading /> <h4 className="msg">{title}</h4>
                </center>
              )}
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                autoHideDuration={5000}
                onClose={handleClose}
                open={open}
              >
                <SnackbarContent
                  className={handleStyleSnack()}
                  message={
                    <span id="message-id" className="colorSpan">
                      {handleIconSnack()}
                      &nbsp;&nbsp;&nbsp;
                      {feedbackMessage}
                    </span>
                  }
                ></SnackbarContent>
              </Snackbar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  );
}
