import React, { useEffect, useState } from "react";

import { GET_LIST } from "react-admin";
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
} from "@material-ui/core/";
import Select from "react-select";

import ApiProvider from "../../provider/apiProvider";
import CardTitle from "../utils/cardTitle/CardTitle";
import StoreWorkbook from "./StoreWorkbook";
import {extractDateAndTime} from "../utils/date/DateUtils"

export default function StoreSaturation() {
  const [stores, setStores] = useState([]);
  const [regions, setRegions] = useState([]);
  const [numRegion, setNumRegion] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const chartStyle = {
    tableSection: {
      marginTop: 20,
    },
    tableStyle: {
      width: 800,
      margin: "auto",
      paddingBottom: "20px",
    },
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getRegions();
    getStores(numRegion);
  }, [numRegion]);

  const sortStores = (data) => {
    return data.sort((store_a, store_b) => {
      return store_b.saturation - store_a.saturation;
    });
  };

  const leadTimeFormat = (leadtime) => {
    if (leadtime){
      const {date, time} = extractDateAndTime(leadtime)
      return `${date} ${time}`
    }
  }

  const selectOnChange = (value) => {
    setNumRegion(value);
  };

  const getStores = (numRegion) => {
    setStores([]);
    ApiProvider(GET_LIST, "fulfillment-store", {
      filter: { num_region: numRegion },
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      res.data.status === 0 && setStores(sortStores(res.data.data));
    });
  };

  const regionSelect = (regions) => {
    return [
      ...regions.map((region) => ({
        value: region.num_region,
        label: region.name,
      })),
      { value: 0, label: "REGIONES" },
    ];
  };

  const getRegions = () => {
    setRegions([]);
    ApiProvider(GET_LIST, "regions", {
      filter: {},
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      res.data.status === 0 && setRegions(regionSelect(res.data.data));
    });
  };

  return (
    <div style={chartStyle.tableSection}>
      <Paper style={{ paddingTop: "20px" }}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={8} style={{ textAlign: "right", paddingRight: "5%" }}>
            <CardTitle main={"Saturación por Tienda"} size="medium" />
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <StoreWorkbook tableContent={stores} />
          </Grid>
        </Grid>
        <br />
        <div style={chartStyle.tableStyle}>
          <Select
            className="select"
            placeholder="Regiones"
            onChange={(opt) => selectOnChange(opt.value)}
            options={regions}
          />
        </div>
        <Table style={chartStyle.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell align="right"></TableCell>
              <TableCell align="right">% de Saturación</TableCell>
              <TableCell align="right">Cupos restantes</TableCell>
              <TableCell align="right">Leadtime último agendamiento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stores
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((store) => (
                <TableRow key={store.id}>
                  <TableCell align="right">
                    <strong>{store.id}</strong> - {store.name}
                  </TableCell>
                  <TableCell align="right">{store.saturation}%</TableCell>
                  <TableCell align="right">{store.capacity}</TableCell>
                  <TableCell align="right">{leadTimeFormat(store.last_schedule_start)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          style={chartStyle.tableStyle}
          labelRowsPerPage="Filas por página:"
          rowsPerPageOptions={[15, 25, 100]}
          component="div"
          count={stores.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
