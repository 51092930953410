import React, { Component } from "react";
import {
  Divider, CardContent, Card, Grid, Button,
  Dialog, DialogTitle, DialogActions
} from "@material-ui/core";
import { Title, DELETE, CREATE } from "react-admin";

import Table from "./FlagTable";
import Form from "./FlagForm";

import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";

import Filter from "../utils/filter/TableFilter";
import Snack from "../utils/snackbar/SnackBar";

import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import { ExcelRenderer } from "react-excel-renderer";

import CardTitle from "../utils/cardTitle/CardTitle";

import FlagWorkbook from "../whiteFlags/FlagWorkbook";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico", "trafico_full"],
      permission_Visibility: [
        "operacion_2_supervisor",
        "operacion_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
      ],

      title: "BANDERAS BLANCAS",
      endpoint: "white-flags",
      create: "BANDERA BLANCA",

      tableContent: [],
      status: 3,

      zoneFilter: "",
      deleteModal: false,
      open: false,
      message: "",
      icon: "success",

      is_FormCalled: false,
    };
  }

  handleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    })
  }

  handleZoneFilter = (zoneName) => {
    this.setState({
      zoneFilter: zoneName,
    });
  };

  handleFilter = (tableContent) => {
    this.setState({ tableContent: tableContent });
  };

  handleTableHide = (status) => {
    this.setState({ status: status });
  };

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
    if (icon === "success") {
      this.handleFormHide(true);
      this.handleFormHide(false);
    }
  };

  handleFormHide = (is_FormCalled) => {
    this.setState({ is_FormCalled: is_FormCalled });
  };

  replaceDate = (date) => {
    if(date){
      let dateSplit = date.split('/')
      if (dateSplit.length === 3){
        return`${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`
      }
    }
    return null
  } 

  handleDeleteAll = () => {
    this.handleDeleteModal();
    ApiProvider(DELETE, this.state.endpoint, { id: 0 }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`white-flags,DELETE,ALL`);
        this.setState({
          open: true,
          icon: "success",
          message: "Se han eliminado todos los bloques.",
        });
        this.handleFormHide(true);
        this.handleFormHide(false);
      }
    });
  };

  upload = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        try {
          let rows = resp.rows;
          let checkHead = [
            "Tienda", "CD", "Tipo de mercaderia",
            "Fecha inicio", "Fecha termino"
          ];
          if (
            resp.rows[0][0] === checkHead[0] &&
            resp.rows[0][1] === checkHead[1] &&
            resp.rows[0][2] === checkHead[2] &&
            resp.rows[0][3] === checkHead[3] &&
            resp.rows[0][4] === checkHead[4]
          ) {
            rows.shift();
            let array = [];
            for (var key in rows) {
              let myJson = {
                center_distribution: resp.rows[key][1],
                store: resp.rows[key][0],
                ambient_desc: resp.rows[key][2],
                start_date: this.replaceDate(resp.rows[key][3]),
                end_date: this.replaceDate(resp.rows[key][4])
              }
              array.push(myJson);
            }
            ApiProvider(CREATE, "white-flags", {
              data: { array },
            }).then((res) => {
              if (!res.data.status) {
                logUserActivity(`white-flags,CREATE,EXCEL UPLOAD`);
                this.setState({
                  open: true,
                  icon: "success",
                  message:
                    String(rows.length) +
                    " banderas blancas han sido cargadas con exito.",
                });
                this.handleFormHide(true);
                this.handleFormHide(false);
              } else {
                let errorMessage = "Error en linea ";
                for (var linea in res.data.data) {
                  let campo = res.data.data[linea];
                  let stringCampo = JSON.stringify(campo).substr(2);
                  stringCampo = stringCampo
                    .substr(0, stringCampo.length - 3)
                    .split('":["');
                  errorMessage +=
                    String(linea) +
                    " en " +
                    stringCampo[0] +
                    ": " +
                    stringCampo[1];
                  this.setState({
                    open: true,
                    icon: "error",
                    message: errorMessage,
                  });
                  break;
                }
              }
            });
          } else {
            this.setState({
              open: true,
              icon: "error",
              message: "Error en la estructura del archivo.",
            });
          }
        } catch (error) {
          this.setState({
            open: true,
            icon: "error",
            message: "Archivo no soportado.",
          });
        }
      }
    });
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (
      this.state.permission_Gestion.includes(this.state.permission) ||
      this.state.permission_Visibility.includes(this.state.permission)
    ) {
      if (this.state.is_FormCalled) {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.title} />
              <h1 className="myTitle">CREAR {this.state.create}</h1>
              <Divider />
              <br></br>

              <Form
                handleSnack={this.handleSnack}
                handleFormHide={this.handleFormHide}
              />

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      } else {
        return (
          <>
          <Dialog
            open={this.state.deleteModal}
            onClose={this.handleDeleteModal}
          >
            <DialogTitle id="alert-dialog-title">
              ¿Está seguro que desea borrar todos los registros?
              <br />
            </DialogTitle>
            <DialogActions>
              <Button onClick={this.handleDeleteModal} color="primary">
                Cancelar
              </Button>
              <Button onClick={this.handleDeleteAll} color="primary">
                Aceptar
              </Button>
            </DialogActions>
          </Dialog>
          <Card>
            <CardContent>
              <Title title={this.state.title} />
              <CardTitle
                optional={this.state.zoneFilter}
                main={this.state.title}
              />
              <Divider />
              <br></br>

              <Filter
                name={this.state.title}
                endpoint={this.state.endpoint}
                handleFilter={this.handleFilter}
                handleTableHide={this.handleTableHide}
                handleZoneFilter={this.handleZoneFilter}
              />
              <br></br>
              <br></br>

              {this.state.permission_Gestion.includes(this.state.permission) ? (
                <Grid container justify="flex-start">
                  <Grid>
                    <input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      id="contained-button-file"
                      className="inputButton"
                      multiple
                      type="file"
                      onChange={this.upload}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        component="span"
                        variant="outlined"
                        color="secondary"
                      >
                        SUBIR
                      </Button>
                    </label>
                    &nbsp;&nbsp;
                    <Button
                      onClick={() => {
                        this.setState({ is_FormCalled: true });
                      }}
                      variant="outlined"
                      component="span"
                      color="secondary"
                    >
                      AGREGAR
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      onClick={() => {
                        this.handleDeleteModal();
                      }}
                      variant="outlined"
                      component="span"
                      color="secondary"
                    >
                      BORRAR
                    </Button>
                    &nbsp;&nbsp;
                    <FlagWorkbook tableContent={this.state.tableContent}/>
                  </Grid>
                </Grid>
              ) : null}

              {this.state.status === 0 ? (
                <Table
                  tableContent={this.state.tableContent}
                  handleSnack={this.handleSnack}
                />
              ) : null}
              {this.state.status === 1 ? (
                <center>
                  <h1>No hay resultados disponibles.</h1>
                </center>
              ) : null}
              {this.state.status === 3 ? (
                <center>
                  <MyLoading /> <h4 className="msg">{this.state.title}</h4>
                </center>
              ) : null}

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
          </>
        );
      }
    } else {
      return <NotFound />;
    }
  }
}

export default Container;
