import React, { Component } from "react";

import { CardContent, Grid } from "@material-ui/core";

import { Label } from "semantic-ui-react";

import Select from "react-select";

import BlueButton from "../../reusableStyles/buttons/BlueButton";
import RedButton from "../../reusableStyles/buttons/DeleteButton";

const apiUrl = process.env.REACT_APP_API_URL;

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissionToOptimizeData: [],
      carNumber: 1,
      stages: ["selectZone", "selectableDrivers", "unselectableDrivers"],
      stageToShow: "selectZone"
    };
  }

  componentDidMount() {
    this.handleZone(this.props.zone);
  }

  handleZone = (value) => {
    let tempStage = "";

    if (value === "1") {
      tempStage = this.state.stages[1];
    } else if (value === "3") {
      tempStage = this.state.stages[2];
      this.getPermissionToOptimize("ZONA 0");
    } else {
      return;
    }

    this.setState({
      zone: value,
      stageToShow: tempStage,
    });
  };

  handleStage = (stage) => {
    this.setState({
      stageToShow: stage,
    });
  };

  getPermissionToOptimize = (zone) => {
    let data = {
      number_trailers: 5,
      zone: zone,
    };

    fetch(`${apiUrl}/optimization?validate=true`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((permissionToOptimizeData) => {
        this.setState({
          permissionToOptimizeData,
        });
      });
  };

  render() {
    const { onSubmit } = this.props;
    const { stageToShow, stages, permissionToOptimizeData } = this.state;

    if (stageToShow === "selectZone") {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">Seleccionar Zona: </Label>
            </Grid>

            <Grid item xs={12} sm={12} className="inline">
              <Select
                id="optimizerZone"
                name="optimizerZone"
                options={this.props.zones.map((opt) => ({
                  label: opt.name,
                  value: String(opt.id),
                }))}
                onChange={(opt) => this.handleZone(opt.value)}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <br />
        </CardContent>
      );
    } else if (stageToShow === "selectableDrivers") {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">Zona RM </Label>
            </Grid>
          </Grid>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">Conductores: </Label>
              <input
                onChange={(event) =>
                  this.setState({ carNumber: parseInt(event.target.value) })                  
                }
                id="optimizerCar"
                defaultValue= {this.state.carNumber}
                min="1"
                max="100"
                className="form-control myTableCenter"
                type="number"
                name="optimizerCar"
                required
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <BlueButton onClick={() => onSubmit("RM", this.state.carNumber)}>
            Optimizar
          </BlueButton>
          {"  "}
          <RedButton onClick={() => this.handleStage(stages[0])}>
            Volver
          </RedButton>
          <br />
          <br />
          <br />
          <br />
        </CardContent>
      );
    } else if (stageToShow === "unselectableDrivers") {
      return (
        <div>
          <CardContent>
            <Label className="myLabelLong">Zona 0</Label>
            {permissionToOptimizeData ? (
              <Label className="myLabelLong">
                Minutos de espera: {permissionToOptimizeData.waiting_time}
              </Label>
            ) : null}
            <Label className="myLabelLong">
              Conductores disponibles:{" "}
              {permissionToOptimizeData
                ? permissionToOptimizeData.available_drivers
                : "Sin información"}
            </Label>
            <br />
            <br />
            {permissionToOptimizeData &&
            permissionToOptimizeData.can_optimize === true && 
            permissionToOptimizeData.available_drivers > 0 ? (
              <BlueButton onClick={() => onSubmit("ZONA 0", 5)}>
                Optimizar
              </BlueButton>
            ) : null}
            {"  "}
            <RedButton onClick={() => this.handleStage(stages[0])}>
              Volver
            </RedButton>
            <br />
            <br />
            <br />
            <br />
          </CardContent>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Form;
