import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { GET_LIST, CREATE } from "react-admin";
import { Label } from "semantic-ui-react";
import Select from "react-select";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";
import {extractDateAndTime} from "../utils/date/DateUtils";

import "bootstrap/dist/css/bootstrap.css";

class FlagForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titte: "BANDERAS BLANCAS",

      endpoint: "white-flags",

      center_distribution: 0,
      store: 0,
      ambient_desc: "",
      start_date: null,
      end_date: null,

      ambient_descSelect: [],
      cdSelect: [],
      isLoadedSelect: false,
      isLoadedSelectAmbient: false,
    };
  }

  callApi_Distribution = () => {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      this.setState({ cdSelect: res.data, isLoadedSelect: true });
    });
  };

  callApi_Ambient() {
    ApiProvider(GET_LIST, "ambient-desc", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      if (res.data.status === 0) {
        this.setState({
          ambient_descSelect: res.data.data,
          isLoadedSelectAmbient: true,
        });
      }
    });
  }

  handleCD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    this.setState({ center_distribution: id_cd });
  };

  handleStore = (value) => {
    if (value === "") {
      value = 0;
    }
    let id_store = parseInt(value);
    this.setState({ store: id_store });
  };

  handleChangeDate = ({name, value}) => {
    this.setState({
      ...this.state,
      [name] : value
    })
  }

  handleAmbient = (value) => {
    if (value === undefined) {
      value = "";
    }
    return value;
  };

  handleCreate = () => {
    if (
      this.state.center_distribution === 0 ||
      this.state.store === 0 ||
      this.state.ambient_desc === ""
    ) {
      this.props.handleSnack(
        true,
        "warning",
        "Debe completar los datos faltantes."
      );
    } else if(
      this.state.start_date && this.state.end_date &&
      this.state.start_date > this.state.end_date
    ){
      this.props.handleSnack(
        true,
        "warning",
        "La fecha de inicio debe ser menor a la fecha de termino"
      );
    }else {
      ApiProvider(CREATE, this.state.endpoint, {
        data: {
          center_distribution: this.state.center_distribution,
          store: this.state.store,
          ambient_desc: this.state.ambient_desc,
          start_date: (this.state.start_date) ? 
          extractDateAndTime(this.state.start_date)['date']
          : null,
          end_date: (this.state.end_date) ?
          extractDateAndTime(this.state.end_date)['date']
          : null
        },
      }).then((res) => {
        if (!res.data.status) {
          logUserActivity(`white-flags,CREATE,${this.state.store}`);
          this.props.handleSnack(true, "success", "Se ha agregado bloque.");
          this.props.handleFormHide(false);
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No se ha podido agregar bloque."
          );
        }
      });
    }
  };

  componentDidMount() {
    this.callApi_Distribution();
    this.callApi_Ambient();
  }

  render() {
    if (this.state.isLoadedSelect) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CD</Label>
              <Select
                id="cdSelect"
                className="select"
                classNamePrefix="select"
                options={this.state.cdSelect.map((opt) => ({
                  label: opt.id,
                  value: opt.id,
                }))}
                onChange={(opt) => this.handleCD(opt.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIENDA</Label>
              <input
                onChange={(event) => this.handleStore(event.target.value)}
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">FECHA INICIO</Label>
              <DatePicker
                selected={this.state.start_date}
                isClearable
                className="select form-control"
                onChange={(date) => this.handleChangeDate({
                  name: 'start_date',
                  value: date
                })}
                dateFormat="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">FECHA TERMINO</Label>
              <DatePicker
                selected={this.state.end_date}
                isClearable
                className="select form-control"
                onChange={(date) => this.handleChangeDate({
                  name: 'end_date',
                  value: date
                })}
                dateFormat="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIPO MERCADERIA</Label>
              <Select
                className="select"
                classNamePrefix="select"
                maxMenuHeight="120"
                options={this.state.ambient_descSelect.map((opt) => ({
                  label: opt.ambient_desc,
                  value: opt.ambient_desc,
                }))}
                onChange={(opt) =>
                  this.setState({ ambient_desc: this.handleAmbient(opt.value) })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleCreate()}
                variant="contained"
                component="span"
                color="primary"
                size="large"
              >
                AGREGAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">{this.state.title}</h4>
        </CardContent>
      );
    }
  }
}

export default FlagForm;
