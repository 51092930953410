import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";
import { GET_LIST, CREATE } from "react-admin";

import { Label } from "semantic-ui-react";
import Select from "react-select";

import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";
import { getAllRoles } from "../utils/roleManager/RoleManager"

import "react-datepicker/dist/react-datepicker.css";

class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "USUARIOS",

      username: "",
      center_distribution: 0,
      carrier: "",
      role: "",
      name: "",
      lastname: "",
      email: "",
      isCarrier: false,
      cdSelect: [],
      carrierSelect: [],
      roleSelect: getAllRoles(),
      is_SelectCDLoaded: false,
    };
  }

  callApi_Carrier = () => {
    ApiProvider(GET_LIST, "carrier", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let carrierOptions = []
      if (res.data && res.data.data && res.data.status === 0){
        carrierOptions = res.data.data.map(carrier => {
          return {
            label: carrier.name,
            value: carrier.id
          };
        });
      }
      this.setState({carrierSelect: carrierOptions});
    });
  };

  callApi_Distribution() {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      this.setState({ cdSelect: res.data, is_SelectLoaded: true });
    });
  }

  handle_CD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    this.setState({ center_distribution: id_cd });
  };

  handle_ROLE = (option) => {
    if (option.value === "transportista") this.setState({ isCarrier: true });
    else {
      this.setState({ isCarrier: false });
      this.setState({ carrier: "" });
    }
    this.setState({ role: option.value });
  };

  handle_Carrier = (option) => {
    this.setState({ carrier: option });
  };

  handleCreate = () => {
    if (
      this.state.username === "" ||
      this.state.center_distribution === 0 ||
      this.state.role === "" ||
      this.state.name === "" ||
      this.state.lastname === "" ||
      this.state.email === "" ||
      (this.state.role === "transportista" && this.state.carrier === "")
    ) {
      this.props.handleSnack(
        true,
        "warning",
        "Debe completar los datos faltantes."
      );
    } else {
      let dataUser = {
        username: this.state.username,
        center_distribution: this.state.center_distribution,
        role: this.state.role,
        name: this.state.name,
        last_name: this.state.lastname,
        email: this.state.email,
      };
      if (this.state.carrier) dataUser.carrier = this.state.carrier;
      
      ApiProvider(CREATE, "user", {
        data: dataUser,
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(`user,CREATE,${this.state.username}`);
          this.props.handleSnack(true, "success", "Se ha creado usuario.");
          this.props.handleFormHide(
            false,
            this.state.username,
            res.data.password
          );
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No se ha podido crear usuario."
          );
        }
      });
    }
  };

  componentDidMount() {
    this.callApi_Distribution();
    this.callApi_Carrier();
  }

  render() {
    return (
      <div>
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">USUARIO</Label>
              <input
                onBlur={(event) =>
                  this.setState({ username: event.target.value })
                }
                defaultValue=""
                className="select form-control"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CD</Label>
              <Select
                className="select"
                classNamePrefix="select"
                options={this.state.cdSelect.map((opt) => ({
                  label: opt.id,
                  value: opt.id,
                }))}
                onChange={(opt) => this.handle_CD(opt.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">ROL</Label>
              <Select
                className="select"
                classNamePrefix="select"
                options={this.state.roleSelect.map((opt) => ({
                  label: opt,
                  value: opt,
                }))}
                onChange={(opt) => this.handle_ROLE(opt)}
              />
            </Grid>
            {this.state.isCarrier && (
              <Grid item xs={12} sm={12} className="inline">
                <Label className="myLabel">TRANSPORTISTA</Label>
                <Select
                  className="select"
                  classNamePrefix="select"
                  options={this.state.carrierSelect}
                  maxMenuHeight="200"
                  onChange={(opt) => this.handle_Carrier(opt.value)}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">NOMBRE</Label>
              <input
                onBlur={(event) => this.setState({ name: event.target.value })}
                defaultValue=""
                className="select form-control"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">APELLIDO</Label>
              <input
                onBlur={(event) =>
                  this.setState({ lastname: event.target.value })
                }
                defaultValue=""
                className="select form-control"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">EMAIL</Label>
              <input
                onBlur={(event) => this.setState({ email: event.target.value })}
                defaultValue=""
                className="select form-control"
                type="text"
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleCreate()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                AGREGAR
              </Button>
            </Grid>

            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.props.handleFormHide(false, "", "")}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                VOLVER
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    );
  }
}

export default UserCreate;
