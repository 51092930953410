import React, { Component } from "react";
import { Divider, CardContent, Card, Grid, Button } from "@material-ui/core";
import { Title } from "react-admin";

import Table from "./ReturnTable";
import Form from "./ReturnForm";

import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";

import Filter from "../utils/filter/TableFilter";
import Snack from "../utils/snackbar/SnackBar";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: [
        "trafico",
        "trafico_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_2_supervisor",
        "operacion_2_full",
        "operacion_trafico_1_full",
        "operacion_1_full",
        "operacion_trafico_1_supervisor",
        "operacion_1_supervisor"
      ],
      permission_Visibility: [],

      tittle: "DEVOLUCION",
      create: "DEVOLUCION",

      endpoint: "return",

      tableContent: [],
      status: 3,

      open: false,
      message: "",
      icon: "success",

      is_FormCalled: false
    };
  }

  handleFilter = tableContent => {
    this.setState({ tableContent: tableContent });
  };

  handleTableHide = status => {
    if (status !== 3) {
      this.setState({ open: false });
    }
    this.setState({ status: status });
  };

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
    if (icon === "success") {
      this.handleFormHide(true);
      this.handleFormHide(false);
    }
  };

  handleFormHide = is_FormCalled => {
    this.setState({ is_FormCalled: is_FormCalled });
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (
      this.state.permission_Gestion.includes(this.state.permission) ||
      this.state.permission_Visibility.includes(this.state.permission)
    ) {
      if (this.state.is_FormCalled) {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">CREAR {this.state.create}</h1>
              <Divider />
              <br></br>

              <Form
                handleSnack={this.handleSnack}
                handleFormHide={this.handleFormHide}
              />

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      } else {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">{this.state.tittle}</h1>
              <Divider />
              <br></br>

              <Filter
                name={this.state.tittle}
                endpoint={this.state.endpoint}
                handleFilter={this.handleFilter}
                handleTableHide={this.handleTableHide}
              />
              <br></br>
              <br></br>

              {this.state.status === 0 ? (
                <Table
                  tableContent={this.state.tableContent}
                  handleSnack={this.handleSnack}
                />
              ) : null}
              {this.state.status === 1 ? (
                <center>
                  <h1>No hay resultados disponibles.</h1>
                </center>
              ) : null}
              {this.state.status === 3 ? (
                <center>
                  <MyLoading /> <h4 className="msg">{this.state.tittle}</h4>
                </center>
              ) : null}

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
              {this.state.permission_Gestion.includes(this.state.permission) ? (
                <Grid className="myTableCenter">
                  <Button
                    onClick={() => {
                      this.setState({ is_FormCalled: true });
                    }}
                    variant="contained"
                    component="span"
                    className="btn btn-info btn-circle3 btn-xl3"
                  >
                    AGREGAR
                  </Button>
                </Grid>
              ) : null}
            </CardContent>
          </Card>
        );
      }
    } else {
      return <NotFound />;
    }
  }
}

export default Container;
