const allRoles = [
  "trafico",
  "trafico_full",
  "operacion_2_supervisor",
  "operacion_2_full",
  "operacion_1_supervisor",
  "operacion_1_full",
  "operacion_trafico_2_supervisor",
  "operacion_trafico_2_full",
  "operacion_trafico_1_supervisor",
  "operacion_trafico_1_full",
  "supervisor_tienda",
  "transportista"
];


export const hasValidRole = () => {
  const currentRole = localStorage.getItem("role");

  return allRoles.includes(currentRole);
};

/**
 * Check if user role has permissión for some action
 *
 * @param {array} strings with names of accepted roles (example: ["trafico", "trafico_full"])
 * @returns {boolean} if roles has access
 */
export const hasCorrectRole = rolesNeeded => {
  const currentRole = localStorage.getItem("role");

  return rolesNeeded.includes(currentRole);
};

export const getAllRoles = () => {
  return [...allRoles];
};

export const getAllRolesLogin = () => {
  return [...allRoles, "reset_password"];
}

