import React, { useState } from "react";
import dayjs from "dayjs";
import { DELETE, UPDATE } from "react-admin";
import ApiProvider from "../../provider/apiProvider";
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Paper,
  IconButton
} from "@material-ui/core";

import Grid from '@material-ui/core/Grid';

import Collapse from '@material-ui/core/Collapse';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { hasCorrectRole } from "../utils/roleManager/RoleManager";
import { logUserActivity } from "../utils/logger/Logger";
import { handleTimeExitFormat } from "./utils";
import FulfillmentField from "./FulfillmentField";
import ScheduleField from "./ScheduleField";

import { applyTimeZoneOffset, extractDateAndTime } from "../utils/date/DateUtils";


const getStyle = (param) => {
  return {
    color: param === "En proceso" ? "crimson" : "black",
  };
};

const getStyleTable = (param) => {
  return {
    backgroundColor: param === "En proceso" ? "lightgreen" : "",
  };
};
const tableStyles = {
  cellHeader: {
    textAlign: 'center'
  },
  cell: {
    paddingRight: 5,
    maxWidth: '40px',
    textAlign: 'center'
  },
  collapsed: {
    row: {
      height: 0
    },
    cell: {
      paddingBottom: 0,
      paddingTop: 0,
      borderBottom: 'none'
    }
  },
  container: {
    padding: "10px 20px"
  }
}
const subTableStyles = {
  cellHeader: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '0',
    borderBottom: 'none'
  },
  cell: {
    fontSize: '14px',
    padding: '0',
    borderBottom: 'none'
  },
  cellCentered: {
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '0',
    borderBottom: 'none',
    textAlign: 'center'
  },
  row: {
    height: 28
  }
};

const MANUAL_MOTIVE = 'Manual';

function RegionRow(props) {
  const { handleDelete, handleDeleteError, handleEdit, handleEditError, row, schedule_type } = props;
  const { carrier_name, center_distribution, id, hour, route, time_exit, trailer_plate, visits } = row;

  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState(row.username);
  const [motive, setMotive] = React.useState(row.motive);
  let timezoneOffset = - (new Date().getTimezoneOffset());

  const start_date = visits && visits[0] && visits[0].schedule_start ? applyTimeZoneOffset(new Date(visits[0].schedule_start), timezoneOffset) : '';
  // Fer : Tito se compromete a arreglar esto en la BD -- 2021-05-14
  // const hour_local = hour ? applyTimeZoneOffset(new Date(hour), timezoneOffset):'';
  const deleteDeparture = (id) => {
    ApiProvider(DELETE, "departures", { id: id }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`departures,DELETE,${id}`);
        handleDelete(id);
      } else {
        handleDeleteError();
      }
    });
  };

  const editVistsAndDeparture = (visits) => {
    editVisits(visits);
    const departureData = { username: localStorage.getItem("username"), motive: MANUAL_MOTIVE };
    editDeparture(departureData);
    updateDeparture(departureData)
  }

  const updateDeparture = (departureData) => {
    setUsername(departureData.username);
    setMotive(departureData.motive);
  }

  const editDeparture = (departureData) => {
    const data = { data: { username: departureData.username, motive: departureData.motive } };
    ApiProvider(UPDATE, `departures/${id}`, data).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`departures/${id}, UPDATE username and motive`);
        handleEdit();
      } else {
        handleEditError();
      }
    });
  }

  const editVisits = (visits) => {
    ApiProvider(UPDATE, "departures/visits", { data: { visits: visits } }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`departures/visits, UPDATE ${visits.map(visit => visit.id).join(' - ')}`);
        handleEdit();
      } else {
        handleEditError();
      }
    });
  };

  const openDetail = () => setOpen(!open);
  return (
    <React.Fragment>
      <TableRow style={getStyleTable(time_exit)} >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={openDetail}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={tableStyles.cell}>{trailer_plate}</TableCell>
        <TableCell style={tableStyles.cell}>{center_distribution}</TableCell>
        <TableCell style={Object.assign({}, getStyle(time_exit), tableStyles.cell)}>
          {handleTimeExitFormat(time_exit, hour)}
        </TableCell>
        <TableCell style={tableStyles.cell}>{carrier_name}</TableCell>
        <TableCell style={tableStyles.cell}>{motive}</TableCell>
        <TableCell style={tableStyles.cell}>{username}</TableCell>
        <TableCell style={tableStyles.cell}>{route}</TableCell>
        <TableCell style={tableStyles.cell}>{start_date !== '' && <ScheduleField scheduleType={schedule_type} startTime={start_date}></ScheduleField>}</TableCell>
        <TableCell className="deleteDepartures" style={tableStyles.cell}>
          {hasCorrectRole(["trafico", "trafico_full"]) && (
              <Grid container>
                <Grid item xs={6}>
                  <EditDialog id={id} visits={visits} editVisitsAndDeparture={editVistsAndDeparture} />
                </Grid>
                <Grid item xs={6}>
                  <DeleteDialog id={id} actionFunction={deleteDeparture} />
                </Grid>
              </Grid>)
          }
        </TableCell>
      </TableRow>
      <TableRow style={tableStyles.collapsed.row}>
        <TableCell style={tableStyles.collapsed.cell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper style={tableStyles.container}>
              <Table size="small">
                <TableHead>
                  <TableRow style={subTableStyles.row}>
                    <TableCell style={subTableStyles.cellHeader}>TIENDA</TableCell>
                    <TableCell style={subTableStyles.cellHeader}>AGENDA</TableCell>
                    <TableCell style={subTableStyles.cellHeader}>FH LLEGADA</TableCell>
                    <TableCell style={subTableStyles.cellCentered}>CUMPLIMIENTO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visits.map((visit) => {
                    visit.scheduleFormatted = { date: '', time: '' }
                    if (visit.schedule_start) {
                      visit.scheduleStartTimeZone = applyTimeZoneOffset(new Date(visit.schedule_start), timezoneOffset);
                      visit.scheduleFormatted = extractDateAndTime(visit.scheduleStartTimeZone);
                    }

                    visit.arrivalFormatted = { date: '', time: '' }
                    if (visit.arrival) {
                      visit.arrivalTimeZone = applyTimeZoneOffset(new Date(visit.arrival), timezoneOffset);
                      visit.arrivalFormatted = extractDateAndTime(visit.arrivalTimeZone);
                    }
                    return (
                      <TableRow key={id + visit.id} style={subTableStyles.row}>
                        <TableCell style={subTableStyles.cell}>{visit.id_store}</TableCell>
                        <TableCell style={subTableStyles.cell}>{visit.schedule_start && <ScheduleField scheduleType={schedule_type} startTime={visit.scheduleStartTimeZone}></ScheduleField>}</TableCell>
                        <TableCell style={subTableStyles.cell}>{visit.arrivalTimeZone && dayjs(visit.arrivalTimeZone).format("DD/MM/YYYY HH:mm")}</TableCell>
                        <TableCell style={subTableStyles.cell}>
                          <FulfillmentField
                            datetimeArrival={visit.arrivalTimeZone}
                            schedule={visit.scheduleStartTimeZone}
                            scheduleType={schedule_type}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ResultsRegionsTable(props) {
  const { handleDelete, handleDeleteError, handleEdit, handleEditError, tableContent } = props;
  const [tableContentRegion, setTableContentRegion] = useState(tableContent)

  const handleDeleteTable = (id) => {
    setTableContentRegion([...tableContentRegion.filter((row) => row.id !== id)]);
    handleDelete(id);
  };
  return (
    <Table padding="none">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell style={tableStyles.cellHeader}>PATENTE</TableCell>
          <TableCell style={tableStyles.cellHeader}>CD</TableCell>
          <TableCell style={tableStyles.cellHeader}>SALIDA GLS</TableCell>
          <TableCell style={tableStyles.cellHeader}>TRANSPORTISTAS</TableCell>
          <TableCell style={tableStyles.cellHeader}>MOTIVO</TableCell>
          <TableCell style={tableStyles.cellHeader}>USUARIO</TableCell>
          <TableCell style={tableStyles.cellHeader}>TIENDA(S)</TableCell>
          <TableCell style={tableStyles.cellHeader}>AGENDA</TableCell>
          {hasCorrectRole(["trafico", "trafico_full"]) &&
            !hasCorrectRole(["transportista"]) && <TableCell></TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableContentRegion.map((obj, index) => {
          const { id, schedule_type } = obj;
          var zero = "0";
          if (obj.time_exit === "") {
            obj.time_exit = "En proceso";
          } else if (obj.time_exit.search(":") === 1) {
            obj.time_exit = zero.concat(obj.time_exit);
          }
          if (obj.time_to_exit.search(":") === 1) {
            obj.time_to_exit = zero.concat(obj.time_to_exit);
          }
          if (obj.time_arrangment.search(":") === 1) {
            obj.time_arrangment = zero.concat(obj.time_arrangment);
          }

          return (
            <RegionRow
              key={id}
              row={obj}
              handleEdit={handleEdit}
              handleEditError={handleEditError}
              handleDelete={handleDeleteTable}
              handleDeleteError={handleDeleteError}
              schedule_type={schedule_type}
            ></RegionRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default ResultsRegionsTable;
