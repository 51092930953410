import React, { Component } from "react";
import {
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
} from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Cancel";

import "bootstrap/dist/css/bootstrap.css";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: "",
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleListItemClick = (value) => {
    this.props.handleDelete(value);
    this.handleClose();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open_dialog,
      message_dialog: nextProps.message_dialog,
    });
  }

  render() {
    return (
      <CardContent>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.open}
        >
          <DialogTitle>{this.state.message_dialog}</DialogTitle>
          <Divider style={{ backgroundColor: "black" }} />
          <Grid container justify="center">
            <Grid item xs={12} sm={6} align="center">
              <Button
                onClick={() => this.handleListItemClick(true)}
                variant="contained"
                component="span"
                className={"GridPadding mySnackBarSuccess"}
              >
                <CheckCircleIcon />
                &nbsp;&nbsp;Confirmar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} align="center">
              <Button
                onClick={() => this.handleListItemClick(false)}
                variant="contained"
                component="span"
                className={"GridPadding mySnackBarError"}
              >
                <ErrorIcon />
                &nbsp;&nbsp;Cancelar
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </CardContent>
    );
  }
}

export default Container;
