import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { GET_LIST, GET_ONE } from "react-admin";
import {Card, CardContent, Grid} from "@material-ui/core";

import Filter from "./Filter";
import VisitsCalendar from "./VisitsCalendar";
import VisitsLegend from "./VisitsLegend";
import CardTitle from "../utils/cardTitle/CardTitle";
import ApiProvider from "../../provider/apiProvider";
import { applyTimeZoneOffset } from "../utils/date/DateUtils";
import { getFulfillmentColor } from "../resultsRegions/FulfillmentField";
import { getDailyWhiteFlags } from "./utils";

/**
 * Return color visit/event according their sequence value
 * @param {Object} event 
 * @returns string hex color
 */
export const getColorVisit = (event) => {
  if (event.schedule_type === "STRICT") {
    if (event.sequence === 1) return "#40CB34";
    if (event.sequence === 2) return "#F0C92D";
    else return "#FA6565";
  } else return "#B0B6C3";
};

/**
 * Return event schedule according to visit times
 * @param {Object} visit 
 * @returns 
 */
export const setEventScheduleStartEnd = (visit) => {
  var dateEvent = dayjs(visit.schedule_start).format("HH");

  if(dateEvent>="18"){
    return {start: "18:00", end: "23:59"};
  }
  if(dateEvent>="12"){
    return {start: "12:00", end: "17:59"};
  }
  if(dateEvent>="06"){
    return {start: "06:00", end: "11:59"};
  }
  return {start: "00:00", end: "05:59"}
}

export default function Agenda() {
  const [ambientFilter, setAmbientFilter] = useState("");
  const [distCenterFilter, setDistCenterFilter] = useState("");
  const [distCenterValues, setDistCenterValues] = useState([]);
  const [storeFilter, setStoreFilter] = useState("");
  const [currentStoreInfo, setCurrentStoreInfo] = useState();
  const [currentStoreTW, setCurrentStoreTW] = useState([]);
  const [startDate, setStartDate] = useState();
  const [visits, setVisits] = useState([]);
  const [visitStatus, setVisitStatus] = useState({});
  const [whiteFlags, setWhiteFlags] = useState({});

  const title = "AGENDA";

  const setCDValues = (cdValues) => {
    setDistCenterValues(cdValues);
  };
  
  useEffect(()=>{
    // conseguir la info de la tienda
    if (storeFilter) {
      ApiProvider(GET_ONE, "store-master", {
        id: storeFilter,
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
      }).then((res) => {
        if (res.data && res.data.length > 0) {
          setCurrentStoreInfo(res.data[0]);
        }
      });
    }
  } ,[storeFilter]);

  useEffect(()=>{
    // conseguir la info de ventana horaria de la tienda
    if (storeFilter) {
      ApiProvider(GET_LIST, "shop", {
        filter: {
          store: storeFilter,
          center_distribution: distCenterFilter ? distCenterFilter : -1,
        },
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
      }).then((res) => {
        let timeWindowStart = [];
        let timeWindowEnd = [];
        if (res.data && res.data.data && res.data.data.length > 0 && res.data.status === 0) {
          res.data.data.forEach((store) => {
            if (store.time_window_ini_1 && store.time_window_end_1) {
              timeWindowStart.push(store.time_window_ini_1);
              timeWindowEnd.push(store.time_window_end_1);
            }
          });

          timeWindowStart.sort();
          timeWindowEnd.sort();
          setCurrentStoreTW(
            [
              {
                startTime: "00:00:00",
                endTime: timeWindowStart[0] + ":00",
                color: "lightGray",
                display: "background",
              },
              {
                startTime: timeWindowEnd[timeWindowEnd.length - 1] + ":00",
                endTime: "24:00:00",
                color: "lightGray",
                display: "background",
              },
            ]
          );
        } else {
          setCurrentStoreTW([]);
        }
      });
    }
  } ,[storeFilter, distCenterFilter]);


  useEffect(()=>{
    updateEvents(ambientFilter, distCenterFilter, startDate, storeFilter);
    getWhiteFlags(distCenterFilter, startDate, storeFilter);
  }, [ambientFilter, distCenterFilter, startDate, storeFilter]);

  /**
   * Update events/visits according to date
   * @param {Date} newDate 
   */
  const updateDate = (newDate) => {
    setStartDate(dayjs(newDate.start));
  };

  /**
   * Update events/visits according to filter values
   * @param {*} ambientFilter 
   * @param {*} distCenterFilter 
   * @param {*} storeFilter 
   */
  const updateEventsFilter = (newAmbientFilter, newDistCenterFilter, newStoreFilter) => {
    setAmbientFilter(newAmbientFilter);
    setDistCenterFilter(newDistCenterFilter);
    setStoreFilter(newStoreFilter);
  };

  /**
   * Receives filter values to return their respective visits
   * @param {string} ambientFilter 
   * @param {string} distCenterFilter 
   * @param {string} startDateFilter 
   * @param {string} storeFilter 
   */
  const updateEvents =  (ambientFilter, distCenterFilter, startDateFilter, storeFilter) => {
    setVisits([]);
    setVisitStatus({});
    if(startDateFilter && storeFilter){
      let startDay = dayjs(startDateFilter);
      let endDay = startDay.add(6, 'day');
      ApiProvider(GET_LIST, "visits", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "id", order: "ASC" },
        filter: {
          centerDistribution: distCenterFilter,
          store: storeFilter,
          ambient: ambientFilter,
          zones: ["REGIONES"],
          requiredVisitStatus: true,
          scheduleStart: startDay.format('YYYY-MM-DD'),
          scheduleEnd: endDay.hour(23).minute(59).second(59).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        }
      }).then(res => {
        if (res.data.status === 0){
          setVisits(formatVisitEvent(res.data.data));
          setVisitStatus(formatExtra(res.data.visits_status || {}));
        }
      });
    }
  }

  const getWhiteFlags = (distCenterFilter, startDateFilter, storeFilter)=>{
    setWhiteFlags({})
    if (!storeFilter && !startDateFilter) return;

    const weekStartDay = dayjs(startDateFilter);
    const weekEndDay = weekStartDay.add(6, 'day');

    ApiProvider(GET_LIST, 'white-flags', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: {
        center_distribution: distCenterFilter,
        store: storeFilter,
        start_date: weekStartDay.format('YYYY-MM-DD'),
        end_date: weekEndDay.format('YYYY-MM-DD')
      },
    }).then((res) => {
        if (res.data.status !== 0) return;
        setWhiteFlags(getDailyWhiteFlags(
          weekStartDay, weekEndDay, res.data.data
        ))
    });
  }

  /**
   * 
   * @param {Object} eventsVisit 
   * @returns Object to be displayed in calendar as event
   */
  const formatVisitEvent = (eventsVisit) => {
    let formatedEvents = [];
    eventsVisit.forEach((event,i) => {
      var currentCD = distCenterValues.find((val) => {
        return val.id === event.center_distribution;
      });

      let timezoneOffset = - (new Date().getTimezoneOffset());
      event.schedule_start = applyTimeZoneOffset(new Date(event.schedule_start), timezoneOffset);
      let startEndSchedule = setEventScheduleStartEnd(event);
      formatedEvents.push({
        title: event.trailer_plate + " - " + event.center_distribution,
        start: dayjs(event.schedule_start).format("YYYY-MM-DD")+" "+startEndSchedule.start,
        end: dayjs(event.schedule_start).format("YYYY-MM-DD")+" "+startEndSchedule.end,
        color: getColorVisit(event),
        extendedProps: {
          ambient: event.ambient,
          carrier: event.carrier? event.carrier.name: "",
          center_distribution: event.center_distribution,
          cd: currentCD,
          motive: event.motive,
          route: event.route,
          trailer_plate: event.trailer_plate,
          arrival: event.arrival,
          id_departures: event.id_departures
        },
      });
    });
    return formatedEvents;
  }

  const formatExtra = (visitStatus) => {
    let timezoneOffset = - (new Date().getTimezoneOffset());
    return Object.keys(visitStatus).reduce((acc, key) => {
      let color = getFulfillmentColor(
        applyTimeZoneOffset(
          new Date(visitStatus[key]['schedule_start']), timezoneOffset
        ),
        applyTimeZoneOffset(new Date(), timezoneOffset)
      )
      acc[key] = color === 'lawngreen' ? <span role="img" aria-label="heavy check mark">✔️</span>
      : color === 'gold' ? <span role="img" aria-label="warning">⚠️</span>
      : <span role="img" aria-label="cross mark">&#10060;</span> 
      return acc
    }, {})
  }
  return (
    <Card style={{ minHeight: "400px" }}>
      <CardContent>
        <CardTitle main={title} />
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Filter
              updateEventsFilter={updateEventsFilter}
              setCDValues={setCDValues}
              storeCapacity={currentStoreInfo ? currentStoreInfo.capacity : ""}
            ></Filter>
          </Grid>
          <Grid item container xs={12}>
            <Grid item>
              <div
                className="rectangle"
                style={{
                  color: "#FFFFFF",
                  background: "#303F62",
                  borderRadius: "12px",
                  textAlign: "center",
                  height: "25px",
                  width: "25px",
                  marginRight: "10px",
                }}
              >
                {currentStoreInfo ? currentStoreInfo.capacity : ""}
              </div>
            </Grid>
            <Grid item style={{ marginRight: 20 }}>
              CAPACIDAD
            </Grid>
            <Grid item>
              <VisitsLegend></VisitsLegend>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <VisitsCalendar
              updateDate={updateDate}
              visits={[...visits, ...currentStoreTW]}
              whiteFlags={whiteFlags}
              cdValues={distCenterValues}
              visitStatus={visitStatus}
            ></VisitsCalendar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
