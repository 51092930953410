import React, { Component } from "react";

import { UPDATE } from "react-admin";
import { CardContent, Grid, Button } from "@material-ui/core";

import { Label } from "semantic-ui-react";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class CdEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: "distribution-center",

      cdToEdit: this.props.cdToEdit,

      id: 0,
      name: 0,
      address: "",
      lat: 0,
      lon: "",
    };
  }

  applyEdit = () => {
    ApiProvider(UPDATE, this.state.endpoint, {
      id: this.state.id,
      data: {
        id: this.state.id,
        name: this.state.name,
        address: this.state.address,
        lat: this.state.lat,
        lon: this.state.lon,
      },
    }).then((res) => {
      if (res.data.status === 0) {
        this.props.handleSnack(
          true,
          "success",
          "Se ha editado el Centro de Distribución"
        );
        this.props.handleEditCd({
          id: this.state.id,
          name: this.state.name,
          address: this.state.address,
          lat: this.state.lat,
          lon: this.state.lon,
        });
        logUserActivity(`${this.state.endpoint},PATCH,${this.state.id}`);
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No se ha podido editar el Centro de Distribución"
        );
      }
    });
  };

  fillFields = () => {
    this.setState({
      id: this.state.cdToEdit.id,
      name: this.state.cdToEdit.name,
      address: this.state.cdToEdit.address,
      lat: this.state.cdToEdit.lat,
      lon: this.state.cdToEdit.lon,
    });
  };

  componentDidMount() {
    this.fillFields();
  }

  render() {
    if (this.state.cdToEdit) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">ID</Label>
              <Label className="select">{this.state.cdToEdit.id}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">NOMBRE</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ name: event.target.value })
                }
                defaultValue={this.state.cdToEdit.name}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">DIRECCIÓN</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ address: event.target.value })
                }
                defaultValue={this.state.cdToEdit.address}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">LATITUD</Label>
              <input
                type="number"
                onChange={(event) => this.setState({ lat: event.target.value })}
                defaultValue={this.state.cdToEdit.lat}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">LONGITUD</Label>
              <input
                type="number"
                onChange={(event) => this.setState({ lon: event.target.value })}
                defaultValue={this.state.cdToEdit.lon}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.applyEdit()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                EDITAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">Problema al cargar información</h4>
        </CardContent>
      );
    }
  }
}

export default CdEdit;
