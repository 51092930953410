import React, { Component } from "react";

import { hasValidRole } from "../utils/roleManager/RoleManager";

import { Divider, CardContent, Card } from "@material-ui/core";

import CardTitle from "../utils/cardTitle/CardTitle";
import CarrierContainer from "./CarrierContainer";
import TenderingContainer from "./TenderingContainer";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Gestión de transportistas",
      showTendering: false,
    };
  }

  handleShowTendering = (newState) => {
    this.setState({
      showTendering: newState,
    });
  };

  render() {
    const { title, showTendering } = this.state;

    if (hasValidRole()) {
      return (
        <Card>
          <CardContent>
            <CardTitle main={title} /> <Divider />
            <br></br>
            {showTendering ? (
              <TenderingContainer
                handleShowTendering={this.handleShowTendering}
              />
            ) : (
              <CarrierContainer
                handleShowTendering={this.handleShowTendering}
              />
            )}
          </CardContent>
        </Card>
      );
    } else {
      return (
        <Card>
          <CardContent>
            <CardTitle main={title} /> <Divider />
            <br></br>
            Ha ocurrido un error en autenticación
          </CardContent>
        </Card>
      );
    }
  }
}

export default Container;
