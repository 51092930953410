import React, { Component } from "react";

import { UPDATE } from "react-admin";
import { CardContent, Grid, Button } from "@material-ui/core";

import { Label } from "semantic-ui-react";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class StoreEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: "store-master",

      storeToEdit: this.props.storeToEdit,

      store_id: 0,
      address: "",
      capacity: 0,
      commune: "",
      format: "",
      latitude: 0,
      longitude: 0,
      name: "",
      observations: 0,
      region: "",
      zone: "",
    };
  }

  handleEdit = () => {
    let data = {};
    if (this.state.address) {
      data.address = this.state.address;
    }
    if (this.state.capacity) {
      data.capacity = parseInt(this.state.capacity);
    }
    if (this.state.commune) {
      data.commune = this.state.commune;
    }
    if (this.state.format) {
      data.format = this.state.format;
    }
    if (this.state.latitude) {
      data.latitude = parseFloat(this.state.latitude);
    }
    if (this.state.longitude) {
      data.longitude = parseFloat(this.state.longitude);
    }
    if (this.state.name) {
      data.name = this.state.name;
    }
    if (this.state.observations) {
      data.observations = this.state.observations;
    }
    if (this.state.region) {
      data.region = this.state.region;
    }
    if (this.state.zone) {
      data.zone = this.state.zone;
    }
    
    ApiProvider(UPDATE, this.state.endpoint, {
      id: this.state.store_id,
      data: data,
    }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`${this.state.endpoint},PATCH,${this.state.store_id}`);
        this.props.handleSnack(true, "success", "Se ha editado la Tienda");
        this.props.handleEditStore({
          store_id: this.state.store_id,
          address: this.state.address,
          capacity: this.state.capacity,
          commune: this.state.commune,
          format: this.state.format,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          name: this.state.name,
          observations: this.state.observations,
          region: this.state.region,
          zone: this.state.zone,
        });
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No se ha podido editar la Tienda"
        );
      }
    });
  };

  fillFields = () => {
    this.setState({
      store_id: this.state.storeToEdit.store_id,
      address: this.state.storeToEdit.address,
      capacity: this.state.storeToEdit.capacity,
      commune: this.state.storeToEdit.commune,
      format: this.state.storeToEdit.format,
      latitude: this.state.storeToEdit.latitude,
      longitude: this.state.storeToEdit.longitude,
      name: this.state.storeToEdit.name,
      observations: this.state.storeToEdit.observations,
      region: this.state.storeToEdit.region,
      zone: this.state.storeToEdit.zone
    });
  };

  componentDidMount() {
    this.fillFields();
  }

  render() {
    if (this.state.storeToEdit) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">ID TIENDA</Label>
              <Label className="select">
                {this.state.storeToEdit.store_id}
              </Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">NOMBRE</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ name: event.target.value })
                }
                defaultValue={this.state.storeToEdit.name}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">DIRECCIÓN</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ address: event.target.value })
                }
                defaultValue={this.state.storeToEdit.address}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">LATITUD</Label>
              <input
                type="number"
                onChange={(event) =>
                  this.setState({ latitude: event.target.value })
                }
                defaultValue={this.state.storeToEdit.latitude}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">LONGITUD</Label>
              <input
                type="number"
                onChange={(event) =>
                  this.setState({ longitude: event.target.value })
                }
                defaultValue={this.state.storeToEdit.longitude}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">ZONA</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ zone: event.target.value })
                }
                defaultValue={this.state.storeToEdit.zone}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">COMUNA</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ commune: event.target.value })
                }
                defaultValue={this.state.storeToEdit.commune}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">FORMATO</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ format: event.target.value })
                }
                defaultValue={this.state.storeToEdit.format}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">REGION</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ region: event.target.value })
                }
                defaultValue={this.state.storeToEdit.region}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CAPACIDAD</Label>
              <input
                type="number"
                onChange={(event) =>
                  this.setState({ capacity: event.target.value })
                }
                defaultValue={this.state.storeToEdit.capacity}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">OBSERVACIONES</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ observations: event.target.value })
                }
                defaultValue={this.state.storeToEdit.observations}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleEdit()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                EDITAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">Problema al cargar información</h4>
        </CardContent>
      );
    }
  }
}

export default StoreEdit;
