import React from "react";
import { Paper, Grid } from "@material-ui/core/";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import CardTitle from "../../utils/cardTitle/CardTitle";

function ExitFlow({ data, zone }) {
  const dataWithoutTotal = data
    .slice(0, data.length - 1)
    .sort((element_a, element_b) => element_a.status - element_b.status);
  const total = data.slice(data.length - 1);
  const buildGraphData = (data) => {
    if (data.op.length === 0) {
      return [];
    }

    let inicio =
      data.op[0].hora <= data.simpli[0].hora
        ? data.op[0].hora
        : data.simpli[0].hora;
    let fin =
      data.op[data.op.length - 1].hora <=
      data.simpli[data.simpli.length - 1].hora
        ? data.op[data.op.length - 1].hora
        : data.simpli[data.simpli.length - 1].hora;
    let result = [];

    for (let i = inicio; i <= fin; i++) {
      let tempOp = 0;
      let tempSimpli = 0;

      for (let j = 0; j <= 24; j++) {
        if (data.op[j] && data.op[j].hora === i) {
          tempOp = data.op[j].cantidad;
        }
      }
      for (let k = 0; k <= 24; k++) {
        if (data.simpli[k] && data.simpli[k].hora === i) {
          tempSimpli = data.simpli[k].cantidad;
        }
      }

      result.push({
        hora: i,
        cantidadOp: tempOp,
        cantidadSimpli: tempSimpli,
      });
    }
    return result;
  };

  const buildGraph = (graphData) => (
    <LineChart
      width={500}
      height={300}
      data={graphData}
      style={flowStyle.chartsAlign}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <XAxis dataKey="hora" />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Legend />
      <Line
        type="monotone"
        dataKey="cantidadOp"
        stroke="#c91029"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="cantidadSimpli" stroke="#82ca9d" />
    </LineChart>
  );

  return (
    <Paper style={{ paddingTop: "20px", marginTop: "20px" }}>
      <CardTitle main="FLUJO DE SALIDA" optional={zone} size="medium" />
      <hr />
      <Grid container>
        {dataWithoutTotal &&
          dataWithoutTotal.map((element, index) => (
            <Grid item xs={6} key={index}>
              <p style={flowStyle.chartName}>{element.distributionCenter}</p>
              {element.status === 0 && buildGraph(buildGraphData(element.data))}
            </Grid>
          ))}
      </Grid>
      <hr />
      <div>
        <p style={flowStyle.chartName}>Total</p>
        {total[0].status === 0 && buildGraph(buildGraphData(total[0].data))}
        <hr />
      </div>
    </Paper>
  );
}

const flowStyle = {
  chartsAlign: {
    margin: "auto",
  },
  chartName: {
    fontWeight: "bold",
    fontSize: 30,
  },
};

export default ExitFlow;
