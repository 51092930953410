import React, { Component } from "react";

import Workbook from "react-excel-workbook";

import ButtonContainer from "../../reusableStyles/buttons/ButtonContainer.js";

import CardTitle from "../utils/cardTitle/CardTitle";

import { hasCorrectRole } from "../utils/roleManager/RoleManager";

import { CardContent } from "@material-ui/core";

import TableCarrier from "./TableCarrier";
import CreateCarrier from "./CreateCarrier";
import EditCarrier from "./EditCarrier";

const apiUrl = process.env.REACT_APP_API_URL;

class CarrierContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Transportistas",
      carriersEndpoint: "carrier",
      carriersData: [],

      sections: ["TABLE", "ADD", "EDIT"],
      sectionToShow: "TABLE",
      carrierToEdit: {},
    };
  }

  async callApi_Carriers() {
    await fetch(`${apiUrl}/${this.state.carriersEndpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({ carriersData: res.data });
        }
      });
  }

  async callApi_DeleteCarrier(id) {
    await fetch(`${apiUrl}/${this.state.carriersEndpoint}/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.handleDeleteCarrierFromState(id);
        }
      });
  }

  handleAddCarrier = () => {
    this.callApi_Carriers();
    this.handleSectionToShow(this.state.sections[0]);
  };

  getCarrierToEdit = (carrier) => {
    this.setState({ carrierToEdit: carrier });
    this.handleSectionToShow(this.state.sections[2]);
  };

  handleEditCarrier = (editedCarrier) => {
    let newCarriersData = this.state.carriersData.map((carrier) => {
      if (carrier.id === editedCarrier.id) {
        return editedCarrier;
      } else {
        return carrier;
      }
    });

    this.setState({ carriersData: newCarriersData });
    this.handleSectionToShow(this.state.sections[0]);
  };

  handleDeleteCarrierFromState = (id) => {
    let newCarriersData = this.state.carriersData.filter(
      (carrier) => carrier.id !== id
    );

    this.setState({ carriersData: newCarriersData });
    this.handleSectionToShow(this.state.sections[0]);
  };

  handleSectionToShow = (sectionName) => {
    this.setState({
      sectionToShow: sectionName,
    });
  };

  componentDidMount() {
    this.callApi_Carriers();
  }

  render() {
    const { title, carriersData, sectionToShow, sections } = this.state;
    const { handleShowTendering } = this.props;

    if (carriersData && sectionToShow === "TABLE") {
      return (
        <CardContent>
          <ButtonContainer>
            <button onClick={() => handleShowTendering(true)}>
              MOSTRAR TENDERING
            </button>
            {hasCorrectRole(["trafico_full", "trafico"]) ? (
              <button onClick={() => this.handleSectionToShow(sections[1])}>
                CREAR TRANSPORTISTA
              </button>
            ) : null}
            {carriersData ? (
              <Workbook
                filename="Transportistas.xlsx"
                element={<button>DESCARGAR</button>}
              >
                <Workbook.Sheet data={carriersData} name="Transportistas">
                  <Workbook.Column label="Nombre" value="name" />
                  <Workbook.Column label="Rut" value="rut" />
                  <Workbook.Column label="Tipo" value="type" />
                  <Workbook.Column label="Vehículo" value="vehicle" />
                  <Workbook.Column label="Spot" value="is_spot" />
                </Workbook.Sheet>
              </Workbook>
            ) : null}
          </ButtonContainer>
          <br></br>
          <CardTitle main={title} size="medium" />
          <br></br>
          <TableCarrier
            carriersData={carriersData}
            deleteCarrier={(id) => this.callApi_DeleteCarrier(id)}
            getCarrierToEdit={this.getCarrierToEdit}
          />
        </CardContent>
      );
    } else if (sectionToShow === "ADD") {
      return (
        <CreateCarrier
          handleSectionToShow={this.handleSectionToShow}
          sections={sections}
          handleAddCarrier={this.handleAddCarrier}
        />
      );
    } else if (sectionToShow === "EDIT") {
      return (
        <EditCarrier
          handleSectionToShow={this.handleSectionToShow}
          sections={sections}
          handleEditCarrier={this.handleEditCarrier}
          carrier={this.state.carrierToEdit}
        />
      );
    } else {
      return <p>Error</p>;
    }
  }
}

export default CarrierContainer;
