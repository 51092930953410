import React, { Component } from "react";
import { Divider, CardContent, Card } from "@material-ui/core";

import { Title, GET_LIST } from "react-admin";

import Form from "./Form";
import Table from "./Table";

import ApiProvider from "../../provider/apiProvider";
import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";

import "./optimizerStyle.css";

class ContainerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: [
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_2_supervisor",
        "operacion_2_full"
      ],
      permission_Visibility: ["trafico_full", "trafico"],

      zones: [],
      isLoadedForm: false,
      selectedInputs: {},
      toTableView: false
    };
  }

  getRole = () => localStorage.getItem("role");

  callApi() {
    ApiProvider(GET_LIST, "zones", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" }
    }).then(res => {
      this.setState({ zones: res.data, isLoadedForm: true });
    });
  }

  componentDidMount() {
    this.callApi();
    this.setState({ permission: this.getRole() });
  }

  getFormInputs = (zone, carNumber) => {
    this.setState({
      selectedInputs: { zone: zone, carNumber: carNumber },
      toTableView: true
    });
  };

  render() {
    const { isLoadedForm, toTableView } = this.state;

    if (
      this.state.permission_Gestion.includes(this.state.permission) ||
      this.state.permission_Visibility.includes(this.state.permission)
    ) {
      if (isLoadedForm) {
        if (!toTableView) {
          return (
            <Card>
              <CardContent>
                <Title title="Optimizador" />
                <h1 className="myTitle">Optimizador</h1>
                <Divider />
                <br></br>
                <Form zones={this.state.zones} zone={this.props.match.params.zoneId} onSubmit={this.getFormInputs} />
              </CardContent>
            </Card>
          );
        } else {
          return (
            <Card>
              <CardContent>
                <Title title="Optimizador" />
                <h1 className="myTitle">Optimizador</h1>
                <Divider />
                <br></br>
                <Table selectedInputs={this.state.selectedInputs} />
              </CardContent>
            </Card>
          );
        }
      } else {
        return (
          <Card>
            <CardContent>
              <MyLoading />
              <h4 className="msg">Zonas</h4>
            </CardContent>
          </Card>
        );
      }
    } else {
      return <NotFound />;
    }
  }
}

export default ContainerForm;
