import React, { Component } from "react";
import { DELETE } from "react-admin";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Button,
  CardContent
} from "@material-ui/core";

import { logUserActivity } from "../utils/logger/Logger";
import { resolveDate } from "../utils/date/DateUtils";
import ApiProvider from "../../provider/apiProvider";

class FlagTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico", "trafico_full"],
      permission_Visibility: [
        "operacion_2_supervisor",
        "operacion_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
      ],

      endpoint: "white-flags",

      tableContent: {},
      is_TableLoaded: false,
    };
  }

  handleDelete = (id) => {
    this.props.handleSnack(true, "success", "Se ha eliminado bandera.");
    ApiProvider(DELETE, this.state.endpoint, { id: id }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`white-flags,DELETE,${id}`);
        this.props.handleSnack(true, "success", "Se ha eliminado bandera.");
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No ha sido posible eliminar bandera."
        );
      }
    });
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({
      tableContent: this.props.tableContent,
      is_TableLoaded: true,
    });
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (this.state.is_TableLoaded) {
      return (
        <CardContent>
          <Table className="itemAlign">
            <TableHead>
              <TableRow>
                <TableCell className="myTableBorder">CD</TableCell>
                <TableCell className="myTableBorder">TIENDA</TableCell>
                <TableCell className="myTableBorder">TIPO MERCADERIA</TableCell>
                <TableCell className="myTableBorder">FECHA INICIO</TableCell>
                <TableCell className="myTableBorder">FECHA TERMINO</TableCell>
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessFlag"></TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableContent.map((obj, index) => {
                const {
                  id, center_distribution, store, ambient_desc,
                  start_date, end_date
                } = obj;
                return (
                  <TableRow key={id} id={id}>
                    <TableCell className="myTableLeft">
                      {center_distribution}
                    </TableCell>
                    <TableCell className="myTableLeft">{store}</TableCell>
                    <TableCell className="myTableLeft">
                      {ambient_desc}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {resolveDate(start_date)}
                    </TableCell>
                    <TableCell className="myTableLeft">
                    {resolveDate(end_date)}
                    </TableCell>
                    {this.state.permission_Gestion.includes(
                      this.state.permission
                    ) ? (
                      <TableCell className="myTableBorderLessFlag">
                        <Button
                          id="create"
                          variant="contained"
                          component="span"
                          className="btn btn-info btn-circle3"
                          onClick={() => {
                            this.handleDelete(id);
                          }}
                        >
                          ELIMINAR
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

export default FlagTable;
