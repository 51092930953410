import React, { Component } from "react";
import { Button, CardContent, FormControl, Grid, Tooltip } from "@material-ui/core";

import {Label} from "semantic-ui-react";
import { GET_LIST } from "react-admin";
import Select from "react-select";
import ApiProvider from "../../../provider/apiProvider";
import Dialog from "./FilterDeleteByCdDialog";
import DatePicker from "react-datepicker";
import { logUserActivity } from "../logger/Logger";
import {extractDateAndTime} from "../date/DateUtils";

const apiUrl = process.env.REACT_APP_API_URL;
const MAXIMUM_DATE = new Date(10000,1,1);
const ZONE_ALL = "TODAS";
const ZONE_REGIONS_ID = 5;
const ZONE_REGIONS = "REGIONES";
const ZONE_RM = "RM";
const ZONE_0 = "ZONA 0";

function hasSpecialMode(modes, mode) {
  if(!modes){
    return false;
  }

  return JSON.parse(modes).includes(mode);
}

class TableFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierSelect: [],
      cdSelect: [],
      id_carrier: 0,
      id_cd: 0,
      id_store: 0,
      trailer_plate: "",
      motive: "",
      scheduleState: "",
      is_SelectLoaded: false,
      endpoint: this.props.endpoint,
      name: this.props.name,
      endpointTranslation: {
        shop: "tiendas",
        "fixed-planning": "fixplanning",
      },

      zones: [
        "gls",
        "departures",
        "lock-store",
        "fixed-planning",
        "white-flags",
      ],
      defaultZone:
        "defaultZone" in this.props ? this.props.defaultZone : ZONE_RM,
      includeRegions:
        "includeRegions" in this.props ? this.props.includeRegions : true,
      selectedZone: [
        "defaultZone" in this.props ? this.props.defaultZone : ZONE_RM,
      ],
      zoneSelect: [],

      currentDate: new Date(),
      isInitDatepickerClearable: false,
      isFinDatepickerClearable: false,
      maximumStartDate: MAXIMUM_DATE,
      minimumEndDate: new Date(),
      scheduleDateStart: "",
      scheduleDateEnd: "",
      timezoneOffset: new Date().getTimezoneOffset(),

      noStores: ["user"],
      trailerPlate: ["gls"],
      deleteByCd: ["shop", "fixed-planning"],
      deleteByCdMessage: "",

      specialModes:
        "specialModes" in this.props ? this.props.specialModes : "[]",
      motives: [
        { value: "Todos", label: "Todos" },
        { value: "Modelo", label: "Modelo" },
        { value: "Fuera", label: "Fuera" },
        { value: "Manual", label: "Manual" },
        { value: "Chasis", label: "Chasis" },
      ],
      scheduleStates: [
        { value: "Todos", label: "Todos" },
        { value: "offtime", label: "Off Time" },
        { value: "late", label: "Retrasado" },
        { value: "ontime", label: "On Time" },
      ],
    };
  }

  callApi_Distribution = () => {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let improvedCdData = res.data.map((center) => ({
        label: center.id,
        value: center.id,
        name: center.name,
        id: center.id,
      }));
      improvedCdData.push({
        label: "Todos",
        value: "-1",
        name: "",
      });

      this.setState({ cdSelect: improvedCdData, is_SelectLoaded: true });
    });
  };

  callApi_Carrier = () => {
    ApiProvider(GET_LIST, "carrier", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let carrierOptions = []
      
      if (res.data && res.data.data && res.data.status === 0){
        
        carrierOptions = res.data.data.map(carrier => {
          return {
            label: carrier.name,
            value: carrier.id
          };
        });
        carrierOptions.push({
          label: "Todos",
          value: "-1",
        });
      }
      this.setState({carrierSelect: carrierOptions});
    });
  };

  callApi_Zones = () => {
    ApiProvider(GET_LIST, "zones", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let improvedZoneData = res.data.map((zone) => ({
        label: zone.name,
        value: [zone.name],
        name: zone.name,
        id: zone.id,
      }));
      let zoneAllValue = [];
      if (!this.state.includeRegions) {
        improvedZoneData = improvedZoneData.filter(function (element) {
          return element.id !== ZONE_REGIONS_ID;
        });
        zoneAllValue = [ZONE_RM, ZONE_0];
      }
      if (this.state.endpoint !== "gls") {
        improvedZoneData.push({
          label: ZONE_ALL,
          value: zoneAllValue,
          name: ZONE_ALL,
          id: 0,
        });
      }

      this.setState({
        zoneSelect: improvedZoneData,
      });
    });
  };

  deleteByCD = (deleteId) => {
    let query = "";
    if (this.state.deleteByCd.includes(this.state.endpoint)) {
      query = `?center_distribution=${deleteId}`;
    }

    fetch(`${apiUrl}/${this.state.endpoint}${query}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          logUserActivity(`${this.state.endpoint},DELETE,${deleteId}`);
          this.setState({ id_cd: "" });
          this.callApi_Endpoint();
        } else {
        }
      });
  };

  callApi_Endpoint = () => {
    this.props.handleTableHide(3);
    let myFilter = {};
    const {
      selectedZone, id_carrier, id_cd, id_store, trailer_plate,
      specialModes, scheduleDateStart, scheduleDateEnd,
      timezoneOffset, motive, scheduleState
    } = this.state;
    
    if (selectedZone.length > 0) {
      myFilter.zone = selectedZone;
    }

    if (this.props.handleZoneFilter) {
      this.props.handleZoneFilter(selectedZone);
    }

    if (id_carrier) {
      myFilter.carrier = id_carrier;
    }
    if (localStorage.getItem("role")==="transportista"){
      myFilter.carrier = localStorage.getItem("carrier")
    }
    if (id_cd && id_cd !== -1) {
      myFilter.center_distribution = id_cd;
    }

    if (id_store) {
      myFilter.store = id_store;
    }

    if (trailer_plate !== "") {
      myFilter.trailer_plate = trailer_plate;
    }

    if (hasSpecialMode(specialModes, 'MULTIZONES')) {
      // TODO: For more logical behavior, this should create an array
      // from the selected zones. However, this is already the default
      // way of passing the parameters. All we need is to change the name.
      //
      // Due to a bug present on short notice, only the minimal change 
      // necessary is applied.

      myFilter.zones = myFilter.zone;
      delete myFilter.zone
    }

    if (hasSpecialMode(specialModes, 'REGIONS')) {
      if (scheduleDateStart === "") {
        let date = new Date();
        // By default only show two weeks behind
        date.setDate(date.getDate() - 14);
        myFilter.scheduleDateStart = formatDate(date);
      } else {
        myFilter.scheduleDateStart = formatDate(scheduleDateStart);
      }
      if (scheduleDateEnd !== "") {
        myFilter.scheduleDateEnd = formatDate(scheduleDateEnd);
      }

      myFilter.timezoneOffset = -timezoneOffset; // js offset is positive for GMT-XXX and negative for GMT+XXX
      myFilter.ignoreCreatedDateTime = true;

      if (!scheduleDateStart && !scheduleDateEnd) {
        myFilter.filterDatetimeArrival = true;
      }

      if (motive && motive !== "Todos") {
        myFilter.motive = motive;
      }

      if (scheduleState && scheduleState !== "Todos") {
        myFilter.scheduleState = scheduleState;
      }
    }

    ApiProvider(GET_LIST, this.state.endpoint, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: myFilter,
    }).then((res) => {
      if (res.data.status === 0) {
        this.props.handleFilter(res.data.data);
        this.props.handleTableHide(0);
      } else {
        this.props.handleTableHide(1);
      }
    });
  };

  handleCarrier = (value) => {
    this.setState({id_carrier: value});
  };

  handleCD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    let message = "";

    let toDelete = value === "-1" ? "Todos" : value;
    message = `Confirme eliminar ${
      this.state.endpointTranslation[this.state.endpoint]
    }: ${toDelete}`;

    this.setState({ id_cd: id_cd, deleteByCdMessage: message });
  };

  handleZone = (value) => {
    this.setState({ selectedZone: value });
  };

  handleStore = (event) => {
    let id_store = parseInt(event.target.value);
    this.setState({ id_store: id_store });
  };

  handlePlate = (event) => {
    this.setState({ trailer_plate: event.target.value });
  };

  handleMotive = (event) => {
    this.setState({ motive: event });
  };

  handleScheduleState = (event) => {
    this.setState({ scheduleState: event });

  };

  componentDidMount() {
    this.callApi_Endpoint();
    this.callApi_Distribution();
    this.callApi_Carrier();
    if (this.state.zones.includes(this.state.endpoint)) {
      this.callApi_Zones();
    }
  }

  handleScheduleStartDate = (date) => {
    let selectedDate = date !== null ? date : "";
    let isInitDatepickerClearable = date !== null;
    let minimumEndDate = date !== null ? date : this.state.currentDate;
    this.setState({
      scheduleDateStart: selectedDate,
      isInitDatepickerClearable: isInitDatepickerClearable,
      minimumEndDate: minimumEndDate
    });
  };

  handleScheduleEndDate = (date) => {
    let selectedDate = date !== null ? date : "";
    let isFinDatepickerClearable = date !== null;
    let maximumStartDate = date !== null ? date : MAXIMUM_DATE;
    this.setState({
      scheduleDateEnd: selectedDate,
      isFinDatepickerClearable: isFinDatepickerClearable,
      maximumStartDate: maximumStartDate
    });
  };

  render() {
    if (this.state.is_SelectLoaded) {
      return (
        <Grid container spacing={8}  alignItems="flex-end">
          {(this.state.zones.includes(this.state.endpoint) && this.state.defaultZone === ZONE_RM) && (
            <Grid item>
                <FormControl>
                  <Label className="labelFormItem">Zona</Label>
                  <Select
                    defaultValue={{ label: this.state.defaultZone, value: this.state.defaultZone }}
                    id="ZoneSelect"
                    className="select"
                    classNamePrefix="select"
                    options={this.state.zoneSelect.map((opt) => ({
                      label: opt.label,
                      value: opt.value,
                    }))}
                    onChange={(opt) => this.handleZone(opt.value)}
                  />
                </FormControl>
            </Grid>
          )}
          <Grid item>
            <FormControl>
              <Label className="myTableCenter labelFormItem">CD</Label>
              <Select
                id="CDSelect"
                className="select"
                classNamePrefix="select"
                maxMenuHeight="200"
                options={this.state.cdSelect.map((opt) => ({
                  label: opt.label,
                  value: opt.value,
                }))}
                onChange={(opt) => this.handleCD(opt.value)}
              />
            </FormControl>
          </Grid>
          {!this.state.noStores.includes(this.state.endpoint) && (
            <Grid item>
              <Label
                className="myTableCenter labelFormItem"
              >
                Tienda
              </Label>
              <input
                type="number"
                value={this.state.id_store}
                onChange={this.handleStore}
                min="0"
                id="storeSelect"
                className="select form-control"
                required
              />
            </Grid>
          )}
          {this.state.trailerPlate.includes(this.state.endpoint) && (
            <Grid item>
              <FormControl>
                <Label
                  className="myTableCenter labelFormItem"
                >
                  Patente
                </Label>
                <input
                  type="string"
                  className="select form-control"
                  required
                  style={filterStyles.fieldCorrection}
                  value={this.state.trailer_plate}
                  onChange={this.handlePlate}
                />
              </FormControl>
            </Grid>)}
            {(this.state.defaultZone === ZONE_REGIONS && localStorage.getItem("role")!== "transportista") && (
              <Grid item>
                <FormControl>
                  <Label className="myTableCenter labelFormItem">Transportista</Label>
                  <Select
                    id="CDSelect"
                    className="select"
                    classNamePrefix="select"
                    options={this.state.carrierSelect}
                    maxMenuHeight="200"
                    onChange={(opt) => this.handleCarrier(opt.value)}
                  />
                </FormControl>
              </Grid>
            )}
            {this.state.defaultZone === ZONE_REGIONS && (
              <Grid item>
                <FormControl>
                  <Label className="myTableCenter labelFormItem">Agenda inicio</Label>
                  <DatePicker
                      isClearable={this.state.isInitDatepickerClearable}
                      maxDate={this.state.maximumStartDate}
                      selected={this.state.scheduleDateStart}
                      className="select form-control"
                      onChange={this.handleScheduleStartDate}
                      timeIntervals={30}
                      dateFormat="yyyy-MM-dd"
                  />
                </FormControl>
              </Grid>
            )}
            {this.state.defaultZone === ZONE_REGIONS && (
              <Grid item>
                <FormControl>
                  <Label className="myTableCenter labelFormItem">Agenda fin</Label>
                  <DatePicker
                      isClearable={this.state.isFinDatepickerClearable}
                      selected={this.state.scheduleDateEnd}
                      className="select form-control"
                      onChange={this.handleScheduleEndDate}
                      timeIntervals={30}
                      dateFormat="yyyy-MM-dd"
                  />
                </FormControl>
              </Grid>
            ) }
            {this.state.defaultZone === ZONE_REGIONS && (
              <Grid item>
              <FormControl>
                <Label className="myTableCenter labelFormItem">Motivo</Label>
                <Select
                  id="MotiveSelect"
                  className="select"
                  classNamePrefix="select"
                  defaultValue={{ value: "Todos", label: "Todos" }}
                  options={this.state.motives}
                  maxMenuHeight="200"
                  onChange={(opt) => this.handleMotive(opt.value)}
                />
              </FormControl>
            </Grid>
            )}
            {this.state.defaultZone === ZONE_REGIONS && (
              <Grid item>
              <FormControl>
                <Label className="myTableCenter labelFormItem">Cumplimiento</Label>
                <Select
                  id="CDSelect"
                  className="select"
                  classNamePrefix="select"
                  defaultValue={{ value: "Todos", label: "Todos" }}
                  options={this.state.scheduleStates}
                  maxMenuHeight="200"
                  onChange={(opt) => this.handleScheduleState(opt.value)}
                />
              </FormControl>
            </Grid>
            )}
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => this.callApi_Endpoint()}
            >
              Filtrar
            </Button>
          </Grid>
            
          <Grid item>
            {this.state.deleteByCd.includes(this.state.endpoint) && (
              <Tooltip title="Borrar datos por CD">
                <Dialog
                  function={this.deleteByCD}
                  id={this.state.id_cd}
                  message={this.state.deleteByCdMessage}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

const filterStyles = {
  fieldCorrection: {
    marginLeft: "10px",
  },
};

function formatDate(date) {
  return String(date.getFullYear()) + "-" + String(date.getMonth() + 1) + "-" + String(date.getDate());
}

export default TableFilter;
