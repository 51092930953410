import React, { Component } from "react";
import { DELETE } from "react-admin";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Button,
  CardContent,
  Grid,
} from "@material-ui/core";

import Dialog from "../utils/dialog/Dialog";
import Workbook from "react-excel-workbook";

import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

class FeriasTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico", "trafico_full"],
      permission_Visibility: [
        "operacion_2_supervisor",
        "operacion_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
      ],

      endpoint: "ferias",

      open_dialog: false,
      confirm: false,
      message_dialog: "",
      message_deleteOne: "¿Esta seguro de eliminar FERIA?",

      delete_id: 0,

      tableContent: {},
      is_TableLoaded: false,
    };
  }

  handle_dialog = (id, message_dialog) => {
    this.setState({
      delete_id: id,
      open_dialog: true,
      message_dialog: message_dialog,
    });
  };

  handleDelete = (value) => {
    this.setState({ open_dialog: false });
    if (value) {
      ApiProvider(DELETE, this.state.endpoint, {
        id: this.state.delete_id,
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(`ferias,DELETE,${this.state.delete_id}`);
          this.props.handleSnack(true, "success", "Se ha eliminado la feria.");
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No ha sido posible eliminar feria."
          );
        }
      });
    }
  };

  handleEdit(id) {
    this.props.handleEditHide(true, id);
  }

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
    this.setState({
      tableContent: this.props.tableContent,
      is_TableLoaded: true,
    });
  }

  render() {
    if (this.state.is_TableLoaded) {
      return (
        <CardContent>
          <Grid container justify="flex-end">
            <Workbook
              filename="Ferias.xlsx"
              element={
                <Button
                  variant="contained"
                  component="span"
                  className="btn btn-info btn-circle2 btn-xl2"
                >
                  DESCARGAR
                </Button>
              }
            >
              <Workbook.Sheet data={this.props.tableContent} name="Ferias">
                <Workbook.Column label="CD" value="center_distribution" />
                <Workbook.Column label="tienda" value="store" />
                <Workbook.Column label="lunes_ini" value="monday_ini" />
                <Workbook.Column label="lunes_fin" value="monday_end" />
                <Workbook.Column label="martes_ini" value="tuesday_ini" />
                <Workbook.Column label="martes_fin" value="tuesday_end" />
                <Workbook.Column label="miercoles_ini" value="wednesday_ini" />
                <Workbook.Column label="miercoles_fin" value="wednesday_end" />
                <Workbook.Column label="jueves_ini" value="thursday_ini" />
                <Workbook.Column label="jueves_fin" value="thursday_end" />
                <Workbook.Column label="viernes_ini" value="friday_ini" />
                <Workbook.Column label="viernes_fin" value="friday_end" />
                <Workbook.Column label="sabado_ini" value="saturday_ini" />
                <Workbook.Column label="sabado_fin" value="saturday_end" />
                <Workbook.Column label="domingo_ini" value="sunday_ini" />
                <Workbook.Column label="domingo_fin" value="sunday_end" />
              </Workbook.Sheet>
            </Workbook>
          </Grid>
          <Table className="itemAlign">
            <TableHead>
              <TableRow>
                <TableCell className="myTableBorder">CD</TableCell>

                <TableCell className="myTableBorder">TIENDA</TableCell>

                <TableCell className="myTableBorderFixplanning">
                  LUNES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  MARTES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  MIÉRCOLES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  JUEVES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  VIERNES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  SÁBADO
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  DOMINGO
                </TableCell>
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessFlag"></TableCell>
                ) : null}
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessFlag"></TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableContent.map((obj, index) => {
                const {
                  id,
                  center_distribution,
                  store,
                  monday_ini,
                  monday_end,
                  tuesday_ini,
                  tuesday_end,
                  wednesday_ini,
                  wednesday_end,
                  thursday_ini,
                  thursday_end,
                  friday_ini,
                  friday_end,
                  saturday_ini,
                  saturday_end,
                  sunday_ini,
                  sunday_end,
                } = obj;
                return (
                  <TableRow key={id} id={id}>
                    <TableCell className="myTableLeft">
                      {center_distribution}
                    </TableCell>
                    <TableCell className="myTableLeft">{store}</TableCell>
                    <TableCell className="myTableLeft">
                      {monday_ini ? `${monday_ini}/${monday_end}` : "-"}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {tuesday_ini ? `${tuesday_ini}/${tuesday_end}` : "-"}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {wednesday_ini
                        ? `${wednesday_ini}/${wednesday_end}`
                        : "-"}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {thursday_ini ? `${thursday_ini}/${thursday_end}` : "-"}
                    </TableCell>

                    <TableCell className="myTableLeft">
                      {friday_ini ? `${friday_ini}/${friday_end}` : "-"}
                    </TableCell>

                    <TableCell className="myTableLeft">
                      {saturday_ini ? `${saturday_ini}/${saturday_end}` : "-"}
                    </TableCell>

                    <TableCell className="myTableLeft">
                      {sunday_ini ? `${sunday_ini}/${sunday_end}` : "-"}
                    </TableCell>
                    {this.state.permission_Gestion.includes(
                      this.state.permission
                    ) ? (
                      <TableCell className="myTableBorderLessAdditional">
                        <Button
                          onClick={() => {
                            this.handle_dialog(
                              id,
                              this.state.message_deleteOne
                            );
                          }}
                          variant="contained"
                          component="span"
                          className="btn btn-info btn-circle6 btn-xl6"
                        >
                          ELIMINAR
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Dialog
            open_dialog={this.state.open_dialog}
            message_dialog={this.state.message_dialog}
            handleDelete={this.handleDelete}
          />
        </CardContent>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}
export default FeriasTable;
