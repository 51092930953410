import React, { Component } from "react";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Button,
  CardContent,
  Grid,
} from "@material-ui/core";

import { DELETE } from "react-admin";

import Workbook from "react-excel-workbook";
import Dialog from "../utils/dialog/Dialog";

import { logUserActivity } from "../utils/logger/Logger";

import ApiProvider from "../../provider/apiProvider";

class FixplanningTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico", "trafico_full"],
      permission_Visibility: [
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_2_supervisor",
        "operacion_2_full",
      ],

      endpoint: "fixed-planning",

      open_dialog: false,
      confirm: false,
      message_dialog: "",
      message_deleteOne: "¿Esta seguro de eliminar FIXPLANNING?",

      delete_id: 0,

      tableContent: {},
      is_TableLoaded: false,
    };
  }

  handleEdit(id) {
    this.props.handleEditHide(true, id);
  }

  handle_dialog = (id, message_dialog) => {
    this.setState({
      delete_id: id,
      open_dialog: true,
      message_dialog: message_dialog,
    });
  };

  handleDelete = (value) => {
    this.setState({ open_dialog: false });
    if (value) {
      ApiProvider(DELETE, this.state.endpoint, {
        id: this.state.delete_id,
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(`fixed-planning,DELETE,${this.state.delete_id}`);
          this.props.handleFormHide(true);
          this.props.handleSnack(
            true,
            "success",
            "Se ha eliminado fixplanning."
          );
          this.props.handleFormHide(false);
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No ha sido posible eliminar fixplanning."
          );
        }
      });
    }
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
    this.setState({
      tableContent: this.fillTotals(this.props.tableContent),
      is_TableLoaded: true,
    });
  }

  sumFixedTotals = (fixed) => {
    if (!fixed) {
      return null;
    }
    const {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
    } = fixed;
    let total =
      monday + tuesday + wednesday + thursday + friday + saturday + sunday;
    return total;
  };

  fillTotals = (data) => {
    for (let fixplanning of data) {
      fixplanning.total = this.sumFixedTotals(fixplanning);
    }
    return data;
  };

  render() {
    if (this.state.is_TableLoaded) {
      return (
        <CardContent>
          <Grid container justify="flex-end">
            <Workbook
              filename="Fixplanning.xlsx"
              element={
                <Button
                  variant="contained"
                  component="span"
                  className="btn btn-info btn-circle2 btn-xl2"
                >
                  DESCARGAR
                </Button>
              }
            >
              <Workbook.Sheet data={this.props.tableContent} name="Fixplanning">
                <Workbook.Column label="Local" value="store" />
                <Workbook.Column label="Lunes" value="monday" />
                <Workbook.Column label="Martes" value="tuesday" />
                <Workbook.Column label="Miercoles" value="wednesday" />
                <Workbook.Column label="Jueves" value="thursday" />
                <Workbook.Column label="Viernes" value="friday" />
                <Workbook.Column label="Sabado" value="saturday" />
                <Workbook.Column label="Domingo" value="sunday" />
                <Workbook.Column label="Total" value="total" />
                <Workbook.Column
                  label="Tipo de Mercaderia"
                  value="ambient_desc"
                />
                <Workbook.Column label="CD" value="center_distribution" />
                <Workbook.Column label="Fecha Actulizacion" value="" />
              </Workbook.Sheet>
            </Workbook>
          </Grid>
          <Table className="itemAlign">
            <TableHead>
              <TableRow>
                <TableCell className="myTableBorderFixplanningHeaderCD">
                  CD
                </TableCell>
                <TableCell className="myTableBorderFixplanningHeader">
                  TIENDA
                </TableCell>
                <TableCell className="myTableBorderFixplanningHeader">
                  TIPO MERCADERIA
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  LUNES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  MARTES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  MIERCOLES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  JUEVES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  VIERNES
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  SABADO
                </TableCell>
                <TableCell className="myTableBorderFixplanning">
                  DOMINGO
                </TableCell>
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessFix"></TableCell>
                ) : null}
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessFix"></TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableContent.map((obj, index) => {
                const {
                  id,
                  center_distribution,
                  store,
                  ambient_desc,
                  monday,
                  tuesday,
                  wednesday,
                  thursday,
                  friday,
                  saturday,
                  sunday,
                } = obj;
                return (
                  <TableRow key={id} id={id}>
                    <TableCell className="myTableLeftCD">
                      {center_distribution}
                    </TableCell>
                    <TableCell className="myTableLeft">{store}</TableCell>
                    <TableCell className="myTableLeft">
                      {ambient_desc}
                    </TableCell>
                    <TableCell className="myTableLeft">{monday}</TableCell>
                    <TableCell className="myTableLeft">{tuesday}</TableCell>
                    <TableCell className="myTableLeft">{wednesday}</TableCell>
                    <TableCell className="myTableLeft">{thursday}</TableCell>
                    <TableCell className="myTableLeft">{friday}</TableCell>
                    <TableCell className="myTableLeft">{saturday}</TableCell>
                    <TableCell className="myTableLeft">{sunday}</TableCell>
                    {this.state.permission_Gestion.includes(
                      this.state.permission
                    ) ? (
                      <TableCell className="myTableBorderLessFix">
                        <Button
                          onClick={() => {
                            this.handleEdit(id);
                          }}
                          variant="contained"
                          component="span"
                          className="btn btn-info btn-circle6 btn-xl6"
                        >
                          EDITAR
                        </Button>
                      </TableCell>
                    ) : null}
                    {this.state.permission_Gestion.includes(
                      this.state.permission
                    ) ? (
                      <TableCell className="myTableBorderLessFix">
                        <Button
                          onClick={() => {
                            this.handle_dialog(
                              id,
                              this.state.message_deleteOne
                            );
                          }}
                          variant="contained"
                          component="span"
                          className="btn btn-info btn-circle6 btn-xl6"
                        >
                          ELIMINAR
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Dialog
            open_dialog={this.state.open_dialog}
            message_dialog={this.state.message_dialog}
            handleDelete={this.handleDelete}
          />
        </CardContent>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}
export default FixplanningTable;
