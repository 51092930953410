import React, { useEffect, useState } from "react";
import {
  Grid,
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  CardContent,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import styled from "@emotion/styled";

import { DELETE, UPDATE } from "react-admin";
import Workbook from "react-excel-workbook";
import { ExcelRenderer } from "react-excel-renderer";

import Dialog from "./Dialog";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";
import { hasCorrectRole } from "../utils/roleManager/RoleManager";
import Snack from "../utils/snackbar/SnackBar";

const StoresFilter = styled("div")`
  margin-bottom: 2rem;
  input {
    margin-right: 1rem;
    height: 36px;
  }

  button {
    margin-right: 1rem;
  }
`;

function StoreMasterTable({
  storeMasterData,
  handleDelete,
  handleDeleteError,
  setDisplayOption,
}) {
  const [tableData, setTableData] = useState([]);
  const [storeFilter, setStoreFilter] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [iconMessage, setIconMessage] = useState("success");
  const [message, setMessage] = useState("");
  const endpoint = "store-master";

  useEffect(() => {
    setTableData(storeMasterData);
  }, [storeMasterData]);

  const deleteDeparture = (id) => {
    ApiProvider(DELETE, "store-master", { id: id }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`store-master,DELETE,${id}`);
        handleDelete(id);
      } else {
        handleDeleteError();
      }
    });
  };

  const filterStore = (storeId) => {
    let filterResult = [];

    if (storeId) {
      filterResult = storeMasterData.filter((store) => {
        return String(store.store_id) === storeId;
      });
    }

    setTableData(filterResult);
  };

  const handleUploadCapacity = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        setOpenMessage(true);
        setIconMessage("error");
        setMessage("Ha ocurrido un error.");
      } else {
        try {
          let rows = resp.rows;
          if (
            resp.rows[0][0].trim().toLowerCase() === "tienda" &&
            resp.rows[0][9].trim().toLowerCase() === "capacidad"
          ) {
            var capacities = [];
            var storesUpdated = []
            rows.shift();
            rows.forEach((row, index) => {
              if (row[0] && row[9]) {
                capacities.push({
                  id: row[0],
                  data: {capacity: row[9]}
                });

                storesUpdated.push({
                  index: tableData.findIndex((store) => {
                    return store.store_id === row[0];
                  }),
                  capacity: row[9],
                });
              }
            });
            if (capacities.length > 0) {
              ApiProvider(UPDATE, endpoint, {data: capacities}).then((res) => {
                if(res.data.status === 0){
                  var newStoreMasterData = [... tableData];
                  storesUpdated.forEach(store=>{
                    newStoreMasterData[store.index].capacity = store.capacity;
                  });
                  setTableData(newStoreMasterData);
                  setOpenMessage(true);
                  setIconMessage("success");
                  setMessage("Capacidades actualizadas.");
                } else {
                  setOpenMessage(true);
                  setIconMessage("error");
                  setMessage("No se actualizaron las capacidades.");
                }
              });
            }
          } else {
            setOpenMessage(true);
            setIconMessage("error");
            setMessage("Error en la estructura del archivo.");
          }
        } catch (error) {
          setOpenMessage(true);
          setIconMessage("error");
          setMessage("Archivo no soportado.");
        }
      }
    });
  };

  if (tableData.length > 0) {
    return (
      <CardContent>
        <h1>Tiendas</h1>
        <Grid container justify="space-between" alignItems="center">
          <Grid container item spacing={16} sm={8} alignItems="flex-end">
            <Grid item>
              <input
                type="number"
                onChange={(event) => setStoreFilter(event.target.value)}
                value={storeFilter}
              />
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                onClick={() => filterStore(storeFilter)}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={16}
            sm={4}
            alignItems="flex-end"
            justify="flex-end"
          >
            <Grid item>
              <Workbook
                filename="MaestroTiendas.xlsx"
                element={
                  <Button variant="outlined" color="secondary">
                    Descargar
                  </Button>
                }
              >
                <Workbook.Sheet data={tableData} name="Fixplanning">
                  <Workbook.Column label="Tienda" value="store_id" />
                  <Workbook.Column label="Nombre" value="name" />
                  <Workbook.Column label="Latitud" value="latitude" />
                  <Workbook.Column label="Longitud" value="longitude" />
                  <Workbook.Column label="Zona" value="zone" />
                  <Workbook.Column label="Dirección" value="address" />
                  <Workbook.Column label="Comuna" value="commune" />
                  <Workbook.Column label="Región" value="region" />
                  <Workbook.Column label="Formato" value="format" />
                  <Workbook.Column label="Capacidad" value="capacity" />
                  <Workbook.Column label="Observaciones" value="observations" />
                </Workbook.Sheet>
              </Workbook>
            </Grid>
            <Grid item>
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                id="input-load-capacity"
                className="inputButton"
                multiple
                type="file"
                onChange={handleUploadCapacity}
              />
              <label htmlFor="input-load-capacity" style={{ marginBottom: 0 }}>
                <Button variant="outlined" color="secondary" component="span">
                  Editar capacidades
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>

        <Table className="itemAlign" padding="none">
          <TableHead>
            <TableRow>
              <TableCell>TIENDA</TableCell>
              <TableCell>NOMBRE</TableCell>
              <TableCell>LATITUD</TableCell>
              <TableCell>LONGITUD</TableCell>
              <TableCell>ZONA</TableCell>
              <TableCell>DIRECCIÓN</TableCell>
              <TableCell>COMUNA</TableCell>
              <TableCell>REGION</TableCell>
              <TableCell>FORMATO </TableCell>
              <TableCell>CAPACIDAD </TableCell>
              <TableCell>OBSERVACIONES </TableCell>
              {hasCorrectRole(["trafico_full"]) && (
                <React.Fragment>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((obj, index) => {
              const {
                store_id,
                name,
                latitude,
                longitude,
                address,
                commune,
                region,
                format,
                zone,
                observations,
                capacity,
              } = obj;
              return (
                <TableRow key={store_id} id={store_id}>
                  <TableCell>{store_id}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{latitude}</TableCell>
                  <TableCell>{longitude}</TableCell>
                  <TableCell>{zone}</TableCell>
                  <TableCell>{address}</TableCell>
                  <TableCell>{commune}</TableCell>
                  <TableCell>{region}</TableCell>
                  <TableCell>{format}</TableCell>
                  <TableCell>{capacity}</TableCell>
                  <TableCell>{observations}</TableCell>
                  {hasCorrectRole(["trafico_full"]) && (
                    <React.Fragment>
                      <TableCell>
                        <Button
                          component="span"
                          onClick={() => setDisplayOption(4, obj)}
                        >
                          <EditIcon></EditIcon>
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Dialog id={store_id} function={deleteDeparture} />
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Snack open={openMessage} icon={iconMessage} message={message} />
      </CardContent>
    );
  } else {
    return <CardContent> Error al cargar información </CardContent>;
  }
}

export default StoreMasterTable;
