import React, { Component } from "react";
import { Divider, CardContent, Card, Grid, Button } from "@material-ui/core";
import { Title } from "react-admin";

import List from "./UserList";
import Create from "./UserCreate";
import Edit from "./UserEdit";

import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";

import Filter from "../utils/filter/TableFilter";
import Snack from "../utils/snackbar/SnackBar";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico_full"],

      tittle: "USUARIOS",
      endpoint: "user",
      create: "CREAR USUARIO",
      edit: "EDITAR USUARIO",
      newUser: { username: "", password: "" },

      tableContent: [],
      status: 3,

      open: false,
      message: "",
      icon: "success",

      is_FormCalled: false,
      is_EditCalled: false,
      id_toEdit: 0,
      showNewUserInfo: false
    };
  }

  handleFilter = tableContent => {
    this.setState({ tableContent: tableContent });
  };

  handleTableHide = status => {
    if (status !== 3) {
      this.setState({ open: false });
    }
    this.setState({ status: status });
  };

  handleEditHide = (is_EditCalled, id) => {
    if (id === 0) {
      this.setState({ is_EditCalled: is_EditCalled });
    } else {
      this.setState({ is_EditCalled: is_EditCalled, id_toEdit: id });
    }
  };

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
    if (icon === "success") {
      this.handleFormHide(true);
      this.handleFormHide(false);
    }
  };

  handleFormHide = (is_FormCalled, username, password) => {
    if (username) {
      this.setState({
        is_FormCalled: is_FormCalled,
        newUser: {
          username: username,
          password: password
        },
        showNewUserInfo: true
      });
    } else {
      this.setState({
        is_FormCalled: is_FormCalled
      });
    }
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (this.state.permission === "trafico_full") {
      if (this.state.showNewUserInfo) {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">Información de nuevo usuario</h1>
              <Divider />
              <br></br>
              <h4>NOMBRE DE USUARIO: {this.state.newUser.username}</h4>
              <br></br>
              <h4>PASSWORD: {this.state.newUser.password}</h4>
              <br></br>
              <p>
                IMPORTANTE: Una vez salga de esta ventana no podrá volver a ver
                esta contraseña. Debe utilizarla para entrar por primera vez a
                la nueva cuenta
              </p>
              <br></br>
              <Button
                onClick={() => {
                  this.setState({ showNewUserInfo: false });
                }}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                Volver
              </Button>
            </CardContent>
          </Card>
        );
      } else if (this.state.is_EditCalled) {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">{this.state.edit}</h1>
              <Divider />
              <br></br>

              <Edit
                handleSnack={this.handleSnack}
                handleEditHide={this.handleEditHide}
                id={this.state.id_toEdit}
              />

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      } else if (this.state.is_FormCalled) {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">{this.state.create}</h1>
              <Divider />
              <br></br>

              <Create
                handleSnack={this.handleSnack}
                handleFormHide={this.handleFormHide}
              />

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      } else {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">{this.state.tittle}</h1>
              <Divider />
              <br></br>

              <Filter
                name={this.state.tittle}
                endpoint={this.state.endpoint}
                handleFilter={this.handleFilter}
                handleTableHide={this.handleTableHide}
              />
              <br></br>

              <Grid className="myTableCenter">
                <Button
                  onClick={() => {
                    this.setState({ is_FormCalled: true });
                  }}
                  variant="contained"
                  component="span"
                  className="btn btn-info btn-circle3 btn-xl3"
                >
                  {this.state.create}
                </Button>
              </Grid>
              <br></br>

              {this.state.status === 0 ? (
                <List
                  tableContent={this.state.tableContent}
                  handleSnack={this.handleSnack}
                  handleEditHide={this.handleEditHide}
                />
              ) : null}
              {this.state.status === 1 ? (
                <center>
                  <h1>No hay resultados disponibles.</h1>
                </center>
              ) : null}
              {this.state.status === 3 ? (
                <center>
                  <MyLoading /> <h4 className="msg">{this.state.tittle}</h4>
                </center>
              ) : null}

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      }
    } else {
      return <NotFound />;
    }
  }
}

export default Container;
