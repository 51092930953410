import React, { Component } from "react";
import {
  CardContent,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CheckCircle from "../../../images/CheckCircle.svg";
import { getStyleCheckbox } from "./utils";
import EditCarrierDialog from "./EditCarrierDialog";
import ApiProvider from "../../../provider/apiProvider";

import { GET_LIST, CREATE } from "react-admin";

class OptimizerRegionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableLoaded: false,
      tableContent: [],
      carrierSelect: [],
    };
  }

  setGLSValueCarrier = (value, trailer) => {
    ApiProvider(CREATE, "trailer/carrier", {
      data: {
        plate: trailer,
        id_carrier: value,
      },
    }).then((res) => {
      if ((res.data || {}).status === 0) {
        const currentCenterDistribution = parseInt(localStorage.getItem("cd"));
        const carrier = this.state.carrierSelect.find(
          (elem) => elem.value === value
        );
        this.setState({
          tableContent: this.state.tableContent.map((content) =>
            content.trailer_plate !== trailer
              ? content
              : {
                  ...content,
                  carrier: { name: carrier.label },
                  canCheck:
                    content.center_distribution === currentCenterDistribution,
                }
          ),
        });
      }
    });
  };

  loadCarrierSelect = () => {
    ApiProvider(GET_LIST, "carrier", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let carrierOptions = [];
      if (res.data && res.data.data && res.data.status === 0) {
        carrierOptions = res.data.data.map((carrier) => {
          return {
            label: carrier.name,
            value: carrier.id,
          };
        });
      }
      this.setState({ carrierSelect: carrierOptions });
    });
  };

  componentDidMount() {
    let tableContent = this.props.tableContent.map((element) =>
      Object.assign(element, { checked: false })
    );

    this.setState({
      isTableLoaded: true,
      tableContent: tableContent,
      canCheckAll:
        tableContent.filter((trailer) => trailer.canCheck).length > 0,
      areAllTrailersChecked: false,
    });
    this.loadCarrierSelect();
  }

  handleAllTrailersCheckboxChange = (areAllTrailersChecked, tableContent) => {
    tableContent
      .filter((trailer) => trailer.canCheck)
      .forEach((trailer) => (trailer.checked = areAllTrailersChecked));

    this.setState({
      areAllTrailersChecked: areAllTrailersChecked,
      tableContent: tableContent,
    });

    this.handleTrailerCheckboxChangeCallback(
      tableContent.filter((trailer) => trailer.checked)
    );
  };

  handleTrailerCheckboxChange = (index, isChecked, tableContent) => {
    tableContent[index].checked = isChecked;

    const areAllTrailersChecked =
      tableContent.filter((trailer) => trailer.canCheck && !trailer.checked)
        .length === 0;
    this.handleTrailerCheckboxChangeCallback(
      tableContent.filter((item) => item.checked)
    );
    this.setState({
      areAllTrailersChecked: areAllTrailersChecked,
      tableContent: tableContent,
    });
  };

  orderS2S = (tableContent) =>
    tableContent.sort((content_a, content_b) => {
      return content_b.s2s - content_a.s2s;
    });

  handleTrailerCheckboxChangeCallback = (checkedItems) => {
    if (!this.props.handleTrailerCheckboxChange) return;

    this.props.handleTrailerCheckboxChange(checkedItems);
  };

  render() {
    if (this.state.isTableLoaded) {
      return (
        <Grid item xs={12}>
          <Table className="itemAlign">
            <TableHead>
              <TableRow>
                <TableCell className="myTableLeft">
                  {this.state.canCheckAll && (
                    <Checkbox
                      checked={this.state.areAllTrailersChecked}
                      color="default"
                      onChange={(event) =>
                        this.handleAllTrailersCheckboxChange(
                          event.target.checked,
                          this.state.tableContent
                        )
                      }
                      style={getStyleCheckbox()}
                    />
                  )}
                </TableCell>
                <TableCell className="myTableBorder">CD</TableCell>
                <TableCell className="myTableBorder">Trailer</TableCell>
                <TableCell className="myTableBorder">Tienda(s)</TableCell>
                <TableCell className="myTableBorder">
                  Leadtime a primera tienda
                </TableCell>
                <TableCell className="myTableBorder">Estado</TableCell>
                <TableCell className="myTableBorder">s2s</TableCell>
                <TableCell className="myTableBorder">Transportista</TableCell>
                <TableCell className="myTableBorder"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.orderS2S(this.state.tableContent).map((obj, index) => {
                const {
                  center_distribution,
                  checked,
                  route,
                  bussiness_status,
                  trailer_plate,
                  lead_time,
                  canCheck,
                  carrier,
                  s2s,
                } = obj;

                return (
                  <TableRow key={index} id={index}>
                    <TableCell className="myTableLeft">
                      {canCheck && (
                        <Checkbox
                          checked={checked}
                          color="default"
                          onChange={(event) =>
                            this.handleTrailerCheckboxChange(
                              index,
                              event.target.checked,
                              this.state.tableContent
                            )
                          }
                          style={getStyleCheckbox()}
                        />
                      )}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {center_distribution}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {trailer_plate}
                    </TableCell>
                    <TableCell className="myTableLeft">{route}</TableCell>
                    <TableCell className="myTableLeft">
                      {lead_time && lead_time[0]
                        ? Math.round(lead_time[0] / 60, 0)
                        : ""}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {bussiness_status}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {s2s && <img src={CheckCircle} width={25} height={25} alt="checkCircle"/>}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {carrier && carrier.name}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      <EditCarrierDialog
                        trailer={trailer_plate}
                        setGLSValueCarrier={this.setGLSValueCarrier}
                        carrier={carrier}
                        carrierSelect={this.state.carrierSelect}
                      ></EditCarrierDialog>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

export default OptimizerRegionsTable;
