import React, {Component} from "react";
import {Button, Card, CardContent, Divider, Grid} from "@material-ui/core";
import {Title} from "react-admin";
import Table from "./Table";
import Filter from "./Filter";

import CardTitle from "../../utils/cardTitle/CardTitle";
import MyLoading from "../../utils/loading/Loading";
import "./styles.css"

const GLS_ENDPOINT = "gls";
const STATUS_LOADED = 0;
const STATUS_EMPTY = 1;
const STATUS_LOADING = 3;

const BUTTON_STYLE_OPAQUE = 'schedule_button_opaque';
/* {
    opacity: "0.5",
}; */
const BUTTON_STYLE_NOT_OPAQUE = 'schedule_button_not_opaque';
/* {
    opacity: "1",
}; */
const BUTTON_STYLE = 'schedule_button';
/* {
    backgroundColor: "#3f51b5",
    color: "#FFFFFF"
} */
class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingStatus: STATUS_LOADING,
            tableContent: [],
            title: "Optimizador",
            schedulingDisabled: true
        };
    }

    handleSnackCallback = (icon, message) => {
        if (!this.props.handleSnack) {
            return;
        }

        this.props.handleSnack(icon, message);
    }

    handleFilter = (tableContent) => {
        let currentCenterDistribution = parseInt(localStorage.getItem("cd"));
        tableContent.forEach(trailer => 
            trailer.canCheck = (
                currentCenterDistribution === trailer.center_distribution && trailer.carrier
            )
        );

        this.handleSnackCallback(
            "success",
            "Se han cargado " + String(tableContent.length) + " filas."
        );

        this.setState({ 
            tableContent: tableContent
        });
    };

    handleLoadingStatus = (loadingStatus) => {
        if (loadingStatus !== STATUS_LOADED) {
            this.setState({ open: false });
        }
        this.setState({ loadingStatus: loadingStatus });
    };

    handleTrailerCheckboxChange = (checkedItems) => {
        this.setState({checkedItems: checkedItems});
        this.setState({schedulingDisabled: checkedItems.length === 0});
    }

    getScheduleButtonStyle = () => {
        return this.state.schedulingDisabled ? BUTTON_STYLE_OPAQUE : BUTTON_STYLE_NOT_OPAQUE;
    }

    schedule = () => {
        if(!this.props.schedule) {
            return;
        }

        this.props.schedule(this.state.checkedItems);
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Title title={this.state.title} />
                    <CardTitle main={this.state.title} />
                    <Divider />
                    <Grid container style={{marginTop: "10px"}}>
                        <Grid item xs={12}>
                            <h4>Resultados disponibles para gestionar</h4>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Filter
                                endpoint={GLS_ENDPOINT}
                                handleFilter={this.handleFilter}
                                handleTableHide={this.handleLoadingStatus}
                                name={this.state.title}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={4} alignItems="flex-end" justify="flex-end">
                            <Button
                                disabled={this.state.schedulingDisabled}
                                onClick={this.schedule}
                                className={BUTTON_STYLE + " " + this.getScheduleButtonStyle()}>
                                Agendar
                            </Button>
                        </Grid>
                        <Grid container item xs={12}>
                            {this.state.loadingStatus === STATUS_LOADED && (
                                <Table
                                    handleFilter={this.handleFilter}
                                    handleTableHide={this.handleLoadingStatus}
                                    handleTrailerCheckboxChange={this.handleTrailerCheckboxChange}
                                    schedulerWasCalled={this.state.schedulerWasCalled}
                                    tableContent={this.state.tableContent}
                                />
                            )}

                            {this.state.loadingStatus === STATUS_LOADING && (
                                <Grid container item justify="center">
                                    <Grid item>
                                        <MyLoading /> 
                                    </Grid>
                                </Grid>
                            )}
                            {this.state.loadingStatus === STATUS_EMPTY && (
                                <Grid container item justify="center">
                                    <h1>No hay resultados disponibles.</h1>
                                </Grid>
                            )}

                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        );
    }
}

export default Container;
