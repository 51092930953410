import styled from "@emotion/styled";

const FormContainer = styled("div")`
  label {
    font-size: 1.2em;
    display: block;
  }

  input {
    diplay: block;
    margin-bottom: 0.5em;
    margin-right: 10em;
  }

  select {
    diplay: block;
    padding: 4px;
    margin-bottom: 5px;
  }

  button {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0.15em;
    padding: 0.5em 1.4em;
    border: none;

    &:hover {
      opacity: 0.7;
    }
  }

  button.submit {
    margin-right: 1em;
    background-color: #3f51b5;
  }

  button.goBack {
    background-color: #bd5044;
  }
`;

export default FormContainer;
