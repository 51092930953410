import styled from "@emotion/styled";

const ButtonContainer = styled("div")`
  button {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #ffffff;
    border-radius: 0.15em;
    background-color: #3f51b5;
    padding: 0.5em 1.4em;
    border: none;

    &:hover {
      background-color: #526bd1;
    }
  }

  button:not(:last-child) {
    margin-right: 5px;
  }
`;

export default ButtonContainer;
