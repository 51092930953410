import * as firebase from "firebase/app";
import "firebase";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const configAnalitics = () => {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
};

/**
 *
 * activity param must contain a string with 3 values separated my commas:
 *    ROUTE,METHOD,AFFECTED IDs. i.e: "shops,POST,345"
 *
 */

const activityFormatter = (activity) => {
  let name = localStorage.getItem("name");
  let username = localStorage.getItem("username");
  let role = localStorage.getItem("role");
  let time = new Date();

  return `${username},${name},${role},${activity},${time}`;
};

export const logUserActivity = (activityInfo) => {
  const analytics = firebase.analytics();
  let activityToLog = activityFormatter(activityInfo);

  analytics.logEvent("user_activity", {
    activity: activityToLog,
  });
};
