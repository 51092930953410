import React, { Component } from "react";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { Checkbox } from "@material-ui/core";
import BlockDialog from "./BlockDialog";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";

const getStyleCheckBox = (param) => {
  return {
    color: param === 2 ? red[400] : green[400],
    "&$checked": {
      color: param === 2 ? red[600] : green[600],
    },
  };
};

const getStyleRowTable = (param) => {
  if (param !== undefined) {
    return {
      backgroundColor: param === 3 ? "lightgrey" : "",
    };
  }
};

class OptimizerTableBody extends Component {
  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="myTableBorderLess"></TableCell>
            <TableCell className="myTableBorder">Patente</TableCell>
            <TableCell className="myTableBorder">CD</TableCell>
            <TableCell className="myTableBorder">Hora de Gestión</TableCell>
            <TableCell className="myTableBorder">Hora de Salida</TableCell>
            <TableCell className="myTableBorder">Tienda(s)</TableCell>
            <TableCell className="myTableBorder">Conductor</TableCell>
            <TableCell className="myTableBorder">Transportista</TableCell>
            <TableCell className="myTableBorderLessAdditional"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.tableContent.map((obj, index) => {
            const {
              id_results,
              id_optimization,
              trailer_plate,
              time_to_exit,
              time_to_arrangement,
              route,
              center_distribution,
              is_alternative,
              driver_name,
              carrier
            } = obj;

            return (
              <TableRow
                key={index}
                id={id_results}
                style={getStyleRowTable(is_alternative)}
              >
                <TableCell className="myTableBorderLess">
                  <Checkbox
                    color="default"
                    style={getStyleCheckBox(is_alternative)}
                    onChange={() =>
                      this.props.handleChange(
                        id_results,
                        id_optimization,
                        is_alternative
                      )
                    }
                    value={String(id_results)}
                    id={String(id_results)}
                    checked={this.props.ifChecked}
                    disabled={is_alternative === 3}
                  />
                </TableCell>
                <TableCell className="myTableLeft">{trailer_plate}</TableCell>
                <TableCell className="myTableLeft">
                  {center_distribution}
                </TableCell>
                <TableCell className="myTableLeft">
                  {time_to_arrangement}
                </TableCell>
                <TableCell className="myTableLeft">{time_to_exit}</TableCell>
                <TableCell className="myTableLeft">{route}</TableCell>
                <TableCell className="myTableLeft">{driver_name}</TableCell>
                <TableCell className="myTableLeft">{carrier}</TableCell>
                <TableCell className="myTableBorderLess">
                  <BlockDialog
                    data={this.props.data[id_results]}
                    function={this.props.handleChangeToUnavailable}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default OptimizerTableBody;
