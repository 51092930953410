import React, {Component} from "react";
import dayjs from "dayjs";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {formatMinutesAsHoursString} from "../../utils/date/DateUtils";

class OptimizerRegionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableContent: [],
        };
    }

    componentDidMount() {
        this.setState({
            tableContent: this.props.tableContent,
        });
    }

    render() {
        return (
            <Grid>
                <Table className="itemAlign">
                    <TableHead>
                        <TableRow>
                            <TableCell className="myTableBorder">CD</TableCell>
                            <TableCell className="myTableBorder">Trailer</TableCell>
                            <TableCell className="myTableBorder">Tienda(s)</TableCell>
                            <TableCell className="myTableBorder">Leadtime</TableCell>
                            <TableCell className="myTableBorder">Transportista</TableCell>
                            <TableCell className="myTableBorder">Agenda</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.tableContent.map((obj, index) => {
                            const {
                                carrier,
                                center_distribution,
                                route,
                                trailer_plate,
                                lead_time,
                                formattedSchedule,
                                visits
                            } = obj;
                            
                            var agendas = '';
                            var leadTimes = '';
                            if(visits){
                                var agendasValues = []
                                var leadTimeValues = []
                                visits.forEach(visit => {
                                    agendasValues.push(dayjs(visit.schedule_start).format('DD/MM/YYYY HH:mm'));
                                    leadTimeValues.push(Math.round(visit.lead_time, 0));
                                });
                                agendas = agendasValues.join('\n');
                                leadTimes = leadTimeValues.join('\n');
                            }                  
                            return (
                                <TableRow key={index} id={index}>
                                    <TableCell className="myTableLeft">{center_distribution}</TableCell>
                                    <TableCell className="myTableLeft">{trailer_plate}</TableCell>
                                    <TableCell className="myTableLeft">{route}</TableCell>
                                    <TableCell className="myTableLeft" style={{whiteSpace:'pre'}}>{leadTimes}</TableCell>
                                    <TableCell className="myTableLeft">{carrier}</TableCell>
                                    <TableCell className="myTableLeft" style={{whiteSpace:'pre'}}>{agendas}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        );
    }
}


export default OptimizerRegionsTable;
