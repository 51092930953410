import React, { Component } from "react";
import { CardContent, Grid, Button, Select, MenuItem } from "@material-ui/core";

import { UPDATE, GET_ONE, GET_LIST } from "react-admin";

import { Label } from "semantic-ui-react";

import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";
import { getAllRoles } from "../utils/roleManager/RoleManager"

import "react-datepicker/dist/react-datepicker.css";

class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "USUARIOS",
      isCarrier: false,
      endpoint: "user",
      username: "",
      center_distribution: 0,
      role: "",
      name: "",
      lastname: "",
      email: "",
      carrier: "",
      id: this.props.id,

      cdSelect: [6009, 6011, 6012, 6020],
      carrierSelect: [],
      roleSelect: getAllRoles(),
      is_SelectCDLoaded: false,
    };
  }

  callApi_fillStates = () => {
    ApiProvider(GET_ONE, this.state.endpoint, {
      id: this.state.id,
    }).then((res) => {
      if (res.data.status === 0) {
        this.setState({
          username: res.data.data.username,
          center_distribution: res.data.data.center_distribution,
          role: res.data.data.role,
          name: res.data.data.name,
          lastname: res.data.data.last_name,
          email: res.data.data.email,
          carrier: res.data.data.carrier? res.data.data.carrier: "",
          isCarrier: res.data.data.role === "transportista"
        });
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No se ha podido cargar usuario."
        );
      }
    });
  };

  handle_CD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    this.setState({ center_distribution: id_cd });
  };

  handle_ROLE = (value) => {
    if (value === "transportista") this.setState({ isCarrier: true });
    else {
      this.setState({ isCarrier: false });
      this.setState({ carrier: "" });
    }
    this.setState({ role: value });
  };

  handleEdit = () => {
    if (
      this.state.username === "" ||
      this.state.center_distribution === 0 ||
      this.state.role === "" ||
      this.state.name === "" ||
      this.state.lastname === "" ||
      this.state.email === "" ||
      (this.state.role === "transportista" && this.state.carrier === "")
    ) {
      this.props.handleSnack(
        true,
        "warning",
        "Debe completar los datos faltantes."
      );
    } else {
      let dataUser = {
        center_distribution: this.state.center_distribution,
        role: this.state.role,
        name: this.state.name,
        last_name: this.state.lastname,
        email: this.state.email
      };
      if (this.state.carrier) dataUser.carrier = this.state.carrier;

      ApiProvider(UPDATE, "user", {
        id: this.state.id,
        data: dataUser,
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(`user,EDIT,${this.state.id}`);
          this.props.handleSnack(true, "success", "Se ha editado usuario.");
          this.props.handleEditHide(false);
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No se ha podido editar usuario."
          );
        }
      });
    }
  };

  handle_Carrier = (option) => {
    this.setState({ carrier: option.target.value });
  };

  callApi_Carrier = () => {
    ApiProvider(GET_LIST, "carrier", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let carrierOptions = []
      if (res.data && res.data.data && res.data.status === 0){
        carrierOptions = res.data.data.map(carrier => {
          return {
            label: carrier.name,
            value: carrier.id
          };
        });
      }
      this.setState({carrierSelect: carrierOptions});
    });
  };

  componentDidMount() {
    this.callApi_Carrier();
    this.callApi_fillStates();
  }

  render() {
    return (
      <CardContent>
        <Grid container spacing={32}>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">USER</Label>
            <Label className="select">{this.state.username}</Label>
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">CD</Label>
            <Select
              value={this.state.center_distribution}
              onChange={(e) =>
                this.setState({ center_distribution: e.target.value })
              }
            >
              {this.state.cdSelect.map((center) => (
                <MenuItem key={center} value={center}>
                  {center}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">ROL</Label>
            <Select
              value={this.state.role}
              onChange={(e) => this.handle_ROLE(e.target.value)}
            >
              {this.state.roleSelect.map((role) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {this.state.role === "transportista" && (
              <Grid item xs={12} sm={12} className="inline">
                <Label className="myLabel">TRANSPORTISTA</Label>
                <Select
                  value={this.state.carrier}
                  onChange={(opt) => this.handle_Carrier(opt)}
                >
                  {this.state.carrierSelect.map((carrier) => (
                    <MenuItem key={carrier.value} value={carrier.value}>
                      {carrier.label}
                    </MenuItem>
                  ))}
                </Select>
                
              </Grid>
            )}
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">NOMBRE</Label>
            <input
              onChange={(event) => this.setState({ name: event.target.value })}
              defaultValue={this.state.name}
              className="select form-control"
              type="text"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">APELLIDO</Label>
            <input
              onChange={(event) =>
                this.setState({ lastname: event.target.value })
              }
              defaultValue={this.state.lastname}
              className="select form-control"
              type="text"
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myLabel">EMAIL</Label>
            <input
              onChange={(event) => this.setState({ email: event.target.value })}
              defaultValue={this.state.email}
              className="select form-control"
              type="text"
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} className="myTableCenter">
            <Button
              onClick={() => this.handleEdit()}
              variant="contained"
              component="span"
              className="btn btn-info btn-circle3 btn-xl3"
            >
              EDITAR USUARIO
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} className="myTableCenter">
            <Button
              onClick={() => this.props.handleEditHide(false)}
              variant="contained"
              component="span"
              className="btn btn-info btn-circle3 btn-xl3"
            >
              VOLVER
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}

export default UserCreate;
