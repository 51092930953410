import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";

import { GET_LIST } from "react-admin";
import { Label } from "semantic-ui-react";
import Select from "react-select";
import ApiProvider from "../../provider/apiProvider";

import DatePicker from "react-datepicker";

class AssistanceFilterTrafico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cdSelect: [],
      date_start: new Date(),
      date_end: new Date(),
      is_SelectLoaded: false,
      endpoint: this.props.endpoint,
      id_cd: this.props.cd,
    };
  }

  callApi_Distribution = () => {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      let improvedCdData = res.data.map((center) => ({
        label: center.id,
        value: center.id,
        name: center.name,
        id: center.id,
      }));
      const all = {
        label: "Todos",
        value: -1,
        name: "Todos",
        id: 0
      }
      improvedCdData.push(all);
      this.setState({ cdSelect: improvedCdData, is_SelectLoaded: true });
    });
  };

  callApi_Endpoint = () => {
    let myFilter = {};

    if (this.state.id_cd) {
      myFilter.center_distribution = this.state.id_cd;
    }

    if (this.state.date_start && this.state.date_end) {
      let date_start = this.state.date_start;
      let date_end = this.state.date_end;

      let timeStart =
        String(date_start.getFullYear()) +
        "-" +
        String(date_start.getMonth() + 1) +
        "-" +
        String(date_start.getDate());
      let timeEnd =
        String(date_end.getFullYear()) +
        "-" +
        String(date_end.getMonth() + 1) +
        "-" +
        String(date_end.getDate());

      myFilter.date_start = timeStart;
      myFilter.date_end = timeEnd;
    }

    ApiProvider(GET_LIST, this.state.endpoint, {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: myFilter,
    }).then((res) => {
      if (res.data.status === 0) {
        this.props.handleFilter(res.data.data);
      } else {
        this.props.handleFilter([]);
      }
    });
  };

  handleCD = (value) => {
    let id_cd = parseInt(value);
    this.setState({ id_cd: id_cd });
  };

  componentDidMount() {
    this.callApi_Distribution();
    this.callApi_Endpoint();
  }

  handleStartDate = (date) => {
    this.setState({ date_start: date });
  };

  handleEndDate = (date) => {
    this.setState({ date_end: date });
  };

  render() {
    if (this.state.is_SelectLoaded) {
      return (
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} className="inline">
            <Label className="myTableCenter myLabelSelect">CD</Label>
            <Label className="myTableCenter myLabelSelect">Fecha Inicial</Label>
            <Label className="myTableCenter myLabelSelect">Fecha Final</Label>
          </Grid>
          <Grid item xs={12} sm={12} className="inline">
            <Select
              id="CDSelect"
              className="select"
              classNamePrefix="select"
              options={this.state.cdSelect.map((opt) => ({
                label: opt.label,
                value: opt.value,
              }))}
              onChange={(opt) => this.handleCD(opt.value)}
            />
            &nbsp;&nbsp;
            <DatePicker
              selected={this.state.date_start}
              className="select form-control"
              onChange={this.handleStartDate}
              timeIntervals={30}
              dateFormat="yyyy-MM-dd"
            />
            &nbsp;&nbsp;
            <DatePicker
              selected={this.state.date_end}
              className="select form-control"
              onChange={this.handleEndDate}
              timeIntervals={30}
              dateFormat="yyyy-MM-dd"
            />
            &nbsp;&nbsp;
            <Button
              variant="outlined"
              style={filterStyles.fieldCorrection}
              onClick={() => this.callApi_Endpoint()}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

const filterStyles = {
  fieldCorrection: {
    marginLeft: "10px",
  },
};

export default AssistanceFilterTrafico;
