import React, { useState } from "react";
import { Paper, Grid } from "@material-ui/core";
import Select from "react-select";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import CardTitle from "../../utils/cardTitle/CardTitle";

function DepartureUser({ data, zone, distributionCenter }) {
  const [graph, setGraph] = useState("");

  const getTimeFrames = (data) => {
    let includedTimeFrames = [];
    if (data) {
      if (data.find((d) => d.index[0] === "AM")) {
        includedTimeFrames.push("AM");
      }
      if (data.find((d) => d.index[0] === "PM")) {
        includedTimeFrames.push("PM");
      }
      if (data.find((d) => d.index[0] === "Noche")) {
        includedTimeFrames.push("Noche");
      }
    }

    return includedTimeFrames;
  };

  const unifyUsersData = (data) => {
    let result = [];
    let counter;
    let tempFrame;
    let tempUser;
    let tempMotive;
    let tempValue = 0;
    let doesExist;

    if (!data || data.length === 0) {
      return null;
    }

    for (counter = 0; counter < data.length; counter++) {
      doesExist = false;
      tempFrame = data[counter].index[0];
      tempUser = data[counter].index[1];
      tempMotive = data[counter].index[2];
      tempValue = data[counter].value;

      for (let i = 0; i < result.length; i++) {
        if (
          data[counter].index[0] === result[i].frame &&
          data[counter].index[1] === result[i].data[0].user
        ) {
          let newPair = { [tempMotive]: tempValue };
          result[i].data[0] = { ...result[i].data[0], ...newPair };
          doesExist = true;
          break;
        }
      }

      if (!doesExist) {
        result.push({
          frame: tempFrame,
          data: [
            {
              [tempMotive]: tempValue,
              user: tempUser,
            },
          ],
        });
      }
    }
    return result;
  };

  const graphData = (data, timeFrames) => {
    let result = [];

    for (let i = 0; i < timeFrames.length; i++) {
      result.push({
        frame: timeFrames[i],
        data: [],
      });

      for (let j = 0; j < data.length; j++) {
        if (result[i].frame === data[j].frame) {
          result[i].data.push(data[j].data[0]);
        }
      }
    }

    return result;
  };

  const buildTable = (graphsData) => (
    <div>
      {graphsData.map((graph, index) => (
        <div key={index}>
          <div style={chartStyle.frameTitleStyle}>
            <h3
              style={chartStyle.frameTextStyle}
            >{`${graph.frame}: ${graph.total}`}</h3>
          </div>
          <div style={chartStyle.frameStyle}>
            <BarChart width={800} height={250} data={graph.data} barSize={35}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="user" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Modelo" fill="#0d17a6" />
              <Bar dataKey="Prioridad" fill="#43a307" />
              <Bar dataKey="No prioridad" fill="#e6bf00" />
              <Bar dataKey="Fuera" fill="#c91029" />
              <Bar dataKey="Devolucion" fill="#b8b8b8" />
            </BarChart>
          </div>
        </div>
      ))}
    </div>
  );

  const insertTotal = (cdData) => {
    let userTotal;
    for (let frame of cdData) {
      frame.total = 0;
      for (let user of frame.data) {
        const values = Object.values(user);
        userTotal = values
          .filter((entry) => typeof entry === "number")
          .reduce((a, b) => a + b);
        frame.total += userTotal;
      }
    }
    return cdData;
  };

  const getOneChart = (centerDistribution) => {
    if (centerDistribution.status === 1) {
      return null;
    }
    const timeFrames = getTimeFrames(centerDistribution.data);
    const usersData = unifyUsersData(centerDistribution.data);
    const unifiedGraphData = graphData(usersData, timeFrames);
    const table = buildTable(insertTotal(unifiedGraphData));

    return table;
  };

  return (
    <Paper style={{ paddingBottom: 20 }}>
      <Grid container style={{ paddingTop: "5%" }}>
        <Grid item xs={6}>
          <CardTitle main="MOTIVOS POR USUARIO" optional={zone} size="medium" />
        </Grid>
        <Grid item xs={5}>
          <Select
            options={distributionCenter
              .sort((cd_a, cd_b) => cd_a - cd_b)
              .map((opt) => ({
                label: opt,
                value: opt,
              }))}
            onChange={(opt) => setGraph(opt.value)}
          />
        </Grid>
      </Grid>
      <div>
        {graph === "" ? (
          <h2 style={chartStyle.noChartStyle}>
            Seleccione el centro de distribución requerido
          </h2>
        ) : null}
      </div>
      {graph && getOneChart(data.find((el) => el.distributionCenter === graph))}
    </Paper>
  );
}

const chartStyle = {
  frameTextStyle: { marginTop: 30 },
  frameTitleStyle: {
    width: 200,
    height: 100,
    margin: "auto",
    marginTop: 40,
    border: "1px solid black",
  },
  noChartStyle: {
    paddingTop: "100px",
    paddingBottom: "100px",
  },
  frameStyle: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 50,
  },
};

export default DepartureUser;
