import { stringify } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  UPDATE_MANY,
} from "react-admin";

require("dotenv").config();

export const apiUrl = process.env.REACT_APP_API_URL;

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (type, resource, params) => {
  let url = "";
  const token = localStorage.getItem("token");
  const options = {
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `jwt ${token}`,
    }),
  };
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      let filters = "";
      for (var param in params.filter) {
        filters += param + "=" + params.filter[param] + "&";
      }
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      };
      url = `${apiUrl}/${resource}?${filters}${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;

    case CREATE:
      if (typeof params.data.array !== "undefined") {
        params.data = params.data.array;
      }
      url = `${apiUrl}/${resource}`;
      options.method = "POST";
      options.body = JSON.stringify(params.data);
      break;

    case UPDATE:
      if (params.data && params.data.new_password) {
        url = `${apiUrl}/${resource}/${params.id}/change-password`;
        options.method = "POST";
      } else if (params.id)  {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "PATCH";
      } else {
        url = `${apiUrl}/${resource}`;
        options.method = "PATCH";
      }
      options.body = JSON.stringify(params.data);
      break;

    case DELETE:
      if (params.id === 0) {
        url = `${apiUrl}/${resource}`;
      } else {
        url = `${apiUrl}/${resource}/${params.id}`;
      }
      options.method = "DELETE";
      break;

    case UPDATE_MANY:
      url = `${apiUrl}/${resource}`;
      options.method = "PATCH";
      options.body = JSON.stringify(params.data);
      break;

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return fetch(url, options)
    .then((res) => {
      if (type === UPDATE_MANY) {
        return { id: 10 };
      } else {
        return res.json();
      }
    })
    .then((json) => {
      switch (type) {
        case GET_LIST:
          if (resource === "optimization") {
            return {
              data: json.map((record) => ({
                ...record,
                id: record.id_optimization,
              })),
              total: json.reduce((total, json) => total + 1, 0),
            };
          } else if (resource === "results") {
            return {
              data: json.map((record) => ({
                ...record,
                id: record.id_results,
              })),
              total: json.reduce((total, json) => total + 1, 0),
            };
          } else if (
            resource.includes("results") &&
            resource.includes("optimization")
          ) {
            return { data: json, total: 10 };
          } else if (
            resource === "white-flags" ||
            resource === "additional" ||
            resource === "additional2" ||
            resource === "trailer" ||
            resource === "fulfillment-store" ||
            resource === "fulfillment-carrier" ||
            resource === "departures" ||
            resource === "departures/visits" ||
            resource === "lock-store" ||
            resource === "gls" ||
            resource === "ambient-desc" ||
            resource === "regions" ||
            resource === "return" ||
            resource === "fixed-planning" ||
            resource === "shop" ||
            resource === "user" ||
            resource === "ferias" ||
            resource === "store-master" ||
            resource === "driver-assistance" ||
            resource === "tendering" ||
            resource === "tendering-dashboard/tendering-report" ||
            resource === "visits"||
            resource === "distribution-center" ||
            resource === "ambient-desc" ||
            resource === "carrier"
          ) {
            return { data: json, total: 10 };
          } else {
            return {
              data: json,
              total: json.reduce((total, json) => total + 1, 0),
            };
          }

        case GET_ONE:
          return { data: json };

        case CREATE:
          return { data: json };

        case UPDATE:
          return { data: json };

        case UPDATE_MANY:
          return { data: json };

        default:
          return { data: json };
      }
    });
};
