import React, { Component } from 'react'
import { CardContent, Snackbar, SnackbarContent  } from '@material-ui/core';


import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

import 'bootstrap/dist/css/bootstrap.css';

class Container extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            message: "",
            icon: "success",
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleStyleSnack = () => {
        if (this.state.icon === "success"){
            return "mySnackBarSuccess"
        }
        else if (this.state.icon === "error"){
            return "mySnackBarError"
        }
        else if (this.state.icon === "warning"){
            return "mySnackBarWarning"
        }
    }

    handleIconSnack = () => {
        if (this.state.icon === "success"){
            return <CheckCircleIcon/>
        }
        else if (this.state.icon === "error"){
            return <ErrorIcon/>
        }
        else if (this.state.icon === "warning"){
            return <WarningIcon/>
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({open: nextProps.open, message: nextProps.message, icon: nextProps.icon})
    }

    render() {
        return (
            <CardContent>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }} 
                    autoHideDuration = { 5000 }
                    onExit = {this.handleClose}
                    onClose = { this.handleClose }
                    open = { this.state.open }
                >
                    <SnackbarContent 
                        className = { this.handleStyleSnack() }
                        message = {
                            <span id="message-id" className="colorSpan">
                                { this.handleIconSnack() }
                                &nbsp;&nbsp;&nbsp;
                                { this.state.message }
                            </span>
                        }
                    >
                    </SnackbarContent>
                </Snackbar>
            </CardContent>
        );
    }
}

export default Container;