import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from '@material-ui/icons/Edit';


const tableStyles = {
  cell: {
    borderBottom: 'none'
  }
};
const dialogStyles = {
  minHeight: 250
};
const buttonStyle = {
  minWidth: 30,
  padding: '0 2px'
};


export default function EditCarrierDialog(props) {
  const { setGLSValueCarrier, carrier, carrierSelect, trailer } = props;
  
  const [open, setOpen] = useState(false);
  const [newCarrier, setNewCarrier] = useState();

  const handleClickConfirm = () => {
    setGLSValueCarrier(newCarrier, trailer);
    setOpen(false);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCarrier = (option) => {
      setNewCarrier(option.value);
  };

  return (
    <div>
      <Button style={buttonStyle} onClick={handleClickOpen}>
        <EditIcon></EditIcon>
      </Button>
      <Dialog
        open={open}
        scroll="body"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Edicion de transportista
        </DialogTitle>
        <DialogContent
        style={dialogStyles}>
        <Select
            id="CarrierSelect"
            menuIsOpen={true}
            className="select-dialog"
            classNamePrefix="select"
            maxMenuHeight="200"
            defaultValue={carrier && { label: carrier.name, value: carrier.id }}
            options={carrierSelect.map((opt) => ({
                label: opt.label,
                value: opt.value,
            }))}
            onChange={(opt) => handleCarrier(opt)}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => handleClickConfirm()} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
