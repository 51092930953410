import React, { Component } from "react";
import {
  Divider,
  CardContent,
  Card,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import { Title } from "react-admin";

import Filter from "../utils/filter/TableFilter";

import CardTitle from "../utils/cardTitle/CardTitle";

import ResultsRmZone0Table from "./ResultsRmZone0Table";

import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: [
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_2_supervisor",
        "operacion_2_full",
        "trafico",
        "trafico_full",
      ],
      permission_Visibility: [],

      title: "RESULTADOS",
      create: "RESULTADOS",
      endpoint: "departures",
      status: 3,

      zoneFilter: "",
      defaultZone: "RM",
      includeRegions: false,

      idDepartures: 0,
      tableContent: [],
      isLoadedTable: false,
      open: false,
      myCounter: "",
      icon: "success",
    };
  }

  handleZoneFilter = (zoneName) => {
    this.setState({
      zoneFilter: zoneName,
    });
  };

  handleFilter = (tableContent) => {
    this.setState({ tableContent: tableContent, isLoadedTable: true });
  };

  handleTableHide = (status) => {
    this.setState({ status: status });
  };

  handleDelete = (id) => {
    let content = this.state.tableContent.filter((row) => row.id !== id);
    this.setState({
      open: true,
      icon: "success",
      myCounter: "Se ha eliminado salida.",
      tableContent: content,
    });
  };

  handleDeleteError = () => {
    this.setState({
      open: true,
      icon: "error",
      myCounter: "No ha sido posible eliminar salida.",
    });
  };

  handleStyleSnack = () => {
    if (this.state.icon === "success") {
      return "mySnackBarSuccess";
    } else if (this.state.icon === "error") {
      return "mySnackBarError";
    } else if (this.state.icon === "warning") {
      return "mySnackBarWarning";
    }
  };

  handleIconSnack = () => {
    if (this.state.icon === "success") {
      return <CheckCircleIcon />;
    } else if (this.state.icon === "error") {
      return <ErrorIcon />;
    } else if (this.state.icon === "warning") {
      return <WarningIcon />;
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (
      this.state.permission_Gestion.includes(this.state.permission) ||
      this.state.permission_Visibility.includes(this.state.permission)
    ) {
      return (
        <Card>
          <CardContent>
            <Title title={this.state.title} />
            <CardTitle
              optional={this.state.zoneFilter}
              main={this.state.title}
            />
            <Divider />
            <br></br>
            <Filter
              name={this.state.title}
              endpoint={this.state.endpoint}
              defaultZone={this.state.defaultZone}
              includeRegions={this.state.includeRegions}
              handleFilter={this.handleFilter}
              handleTableHide={this.handleTableHide}
              handleZoneFilter={this.handleZoneFilter}
              specialModes='["MULTIZONES"]'
            />
            <br></br>
            <br></br>

            {this.state.status === 0 ? (
              <ResultsRmZone0Table
                tableContent={this.state.tableContent}
                isLoadedTable={this.state.isLoadedTable}
                idDepartures={this.state.idDepartures}
                handleDelete={this.handleDelete}
                handleDeleteError={this.handleDeleteError}
              />
            ) : null}
            {this.state.status === 1 ? (
              <center>
                <h1>No hay resultados disponibles</h1>
              </center>
            ) : null}
            {this.state.status === 3 ? (
              <center>
                <MyLoading /> <h4 className="msg">{this.state.title}</h4>
              </center>
            ) : null}

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              autoHideDuration={5000}
              onClose={this.handleClose}
              open={this.state.open}
            >
              <SnackbarContent
                className={this.handleStyleSnack()}
                message={
                  <span id="message-id" className="colorSpan">
                    {this.handleIconSnack()}
                    &nbsp;&nbsp;&nbsp;
                    {this.state.myCounter}
                  </span>
                }
              ></SnackbarContent>
            </Snackbar>
          </CardContent>
        </Card>
      );
    } else {
      return <NotFound />;
    }
  }
}

export default Container;
