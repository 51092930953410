import React from "react";
import Workbook from "react-excel-workbook";
import { Button } from "@material-ui/core";

const StoreWorkbook = ({ tableContent }) => {
  return (
    <Workbook
      filename="SaturaciónTienda.xlsx"
      element={
        <Button variant="outlined" component="span" color="secondary">
          DESCARGAR
        </Button>
      }
    >
      <Workbook.Sheet data={tableContent} name="BanderasBlancas">
        <Workbook.Column label="Nombre Tienda" value="name" />
        <Workbook.Column
          label="% de Saturación"
          value={(row) => `${row.saturation}%`}
        />
        <Workbook.Column
          label="Cupos restantes"
          value={(row) => `${row.capacity}`}
        />
      </Workbook.Sheet>
    </Workbook>
  );
};

export default StoreWorkbook;
