import React, { Component } from "react";

import { CREATE } from "react-admin";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  CardContent,
  Button,
  Checkbox,
  Grid,
  TextField,
} from "@material-ui/core";

import green from "@material-ui/core/colors/green";
import Workbook from "react-excel-workbook";
import UnlockTrailer from "./UnlockTrailerButton";

import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

const apiUrl = process.env.REACT_APP_API_URL;

const getStyleCheckBox = () => {
  return {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  };
};

class GlsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: [
        "trafico",
        "trafico_full",
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
      ],
      permission_Visibility: [
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_2_supervisor",
        "operacion_2_full",
      ],

      endpoint: "additional2",

      glsMasive: [],

      tableContent: {},
      is_TableLoaded: false,
    };
  }

  handleAdd = (isChecked, center_distribution, trailer_plate, route) => {
    let myArray = this.state.glsMasive;
    let myJson = {
      trailer_plate: trailer_plate,
      center_distribution: center_distribution,
      route: route,
      status: "Cargado",
      type: 0,
      time_window_ini: "",
      time_window_end: "",
      time_to_exit_priority: "",
    };

    let myNumber = 0;
    for (var i in myArray) {
      if (myArray[i].trailer_plate === myJson.trailer_plate) {
        myNumber = i;
      }
    }
    if (isChecked) {
      myArray.push(myJson);
    } else {
      myArray.splice(myNumber, 1);
    }
    this.setState({ glsMasive: myArray });
  };

  handleCreateAll = () => {
    ApiProvider(CREATE, this.state.endpoint, {
      data: this.state.glsMasive,
    }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`${this.state.endpoint},CREATE,Create multiple`);
        this.props.handleSnack(
          true,
          "success",
          "Se han agregado " + this.state.glsMasive.length + " filas"
        );
      } else if (res.data.status === 3) {
        let errString = "Error:  ";

        for (let obj of res.data.data) {
          errString += `${obj.message}, `;
        }
        try {
          this.props.handleSnack(true, "error", errString);
        } catch (error) {
          this.props.handleSnack(
            true,
            "error",
            "No ha sido posible agregar filas"
          );
        }
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No ha sido posible agregar filas"
        );
      }
    });
  };

  getRole = () => localStorage.getItem("role");

  async callApi_lastMinutesInjection() {
    await fetch(`${apiUrl}/gls/last-hour-integration`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({ minutesData: res.data });
        }
      });
  }

  componentDidMount() {
    this.setState({ permission: this.getRole() });
    this.setState({
      tableContent: this.props.tableContent,
      is_TableLoaded: true,
    });
    this.callApi_lastMinutesInjection();
  }

  render() {
    if (this.state.is_TableLoaded) {
      return (
        <Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={glsStyles.buttonsTopSection}
          >
            {this.state.minutesData ? (
              <TextField
                error
                disabled
                label="Última inyección"
                defaultValue={`hace ${this.state.minutesData.minutes} minutos`}
                margin="normal"
                variant="outlined"
              />
            ) : null}
            <Workbook
              filename="GLS.xlsx"
              element={
                <Button variant="outlined" color="secondary">
                  Descargar
                </Button>
              }
            >
              <Workbook.Sheet data={this.props.tableContent} name="GLS">
                <Workbook.Column label="CD" value="center_distribution" />
                <Workbook.Column label="TRAILER" value="trailer_plate" />
                <Workbook.Column label="RUTA" value="route" />
                <Workbook.Column label="FIX" value="fixed_planning" />
                <Workbook.Column label="VENTANA INI" value="time_window" />
                <Workbook.Column label="DESPACHADOS" value="on_route" />
                <Workbook.Column label="PLAN" value="plan" />
                <Workbook.Column label="ESTADO" value="bussiness_status" />
              </Workbook.Sheet>
            </Workbook>
          </Grid>
          <br></br>
          <Grid>
            <Table className="itemAlign">
              <TableHead>
                <TableRow>
                  <TableCell className="myTableBorder">CD</TableCell>
                  <TableCell className="myTableBorder">Trailer</TableCell>
                  <TableCell className="myTableBorder">Tienda(s)</TableCell>
                  <TableCell className="myTableBorder">Fix</TableCell>
                  <TableCell className="myTableBorder">Ventana ini</TableCell>
                  <TableCell className="myTableBorder">Despachados</TableCell>
                  {<TableCell className="myTableBorder">Estado</TableCell>}
                  {this.state.permission_Gestion.includes(
                    this.state.permission
                  ) ? (
                    <TableCell className="myTableBorder">
                      <Button
                        onClick={() => {
                          this.handleCreateAll();
                        }}
                        variant="outlined"
                        color="secondary"
                      >
                        Agregar
                      </Button>
                    </TableCell>
                  ) : null}
                  <TableCell className="myTableBorder">Plan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tableContent.map((obj, index) => {
                  const {
                    center_distribution,
                    trailer_plate,
                    route,
                    fixed_planning,
                    time_window,
                    on_route,
                    plan,
                    bussiness_status,
                  } = obj;

                  return (
                    <TableRow key={index} id={index}>
                      <TableCell className="myTableLeft">
                        {center_distribution}
                      </TableCell>
                      <TableCell className="myTableLeft">
                        {trailer_plate}
                      </TableCell>
                      <TableCell className="myTableLeft">{route}</TableCell>
                      <TableCell className="myTableLeft">
                        {fixed_planning}
                      </TableCell>
                      <TableCell className="myTableLeft">
                        {time_window}
                      </TableCell>
                      <TableCell className="myTableLeft">{on_route}</TableCell>
                      {
                        <TableCell className="myTableLeft">
                          {bussiness_status === "Trailer Bloqueado" ? (
                            <UnlockTrailer
                              plate={trailer_plate}
                              tryToUnlockTrailer={this.props.tryToUnlockTrailer}
                            />
                          ) : (
                            bussiness_status
                          )}
                        </TableCell>
                      }
                      {this.state.permission_Gestion.includes(
                        this.state.permission
                      ) ? (
                        <TableCell className="myTableLeft">
                          <Checkbox
                            color="default"
                            style={getStyleCheckBox()}
                            onChange={(event) =>
                              this.handleAdd(
                                event.target.checked,
                                center_distribution,
                                trailer_plate,
                                route
                              )
                            }
                          />
                        </TableCell>
                      ) : null}
                      <TableCell className="myTableLeft">{plan}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

const glsStyles = {
  buttonsTopSection: {
    justifyContent: "space-between",
  },
  minutesTextArea: {
    textAlign: "center",
  },
};

export default GlsTable;
