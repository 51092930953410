import React, { useState } from "react";

import CardTitle from "../utils/cardTitle/CardTitle";

import { CardContent } from "@material-ui/core";

import { useForm } from "react-hook-form";

import FormContainer from "../../reusableStyles/FormContainer.js";

import Snack from "../utils/snackbar/SnackBar";

const apiUrl = process.env.REACT_APP_API_URL;

export default function CreateAssistance({
  assistanceCount,
  carriers,
  handleSectionToShow,
  sections,
  handleAddAssist,
}) {
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [checkboxValue, setCheckboxValue] = useState(false);
  
  const TITLE = "Ingresar asistencia";

  const { register, handleSubmit, watch, setValue } = useForm();

  const createAssist = (newAssist) => {
    let tempAssist = newAssist;
    tempAssist.rut = parseInt(newAssist.rut);
    tempAssist.id_carrier = parseInt(newAssist.id_carrier);

    fetch(`${apiUrl}/driver-assistance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(tempAssist),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          handleAddAssist();
        }
        else if (res.status === 1){
          setMessage(res.data.message);
          setIcon("error");
          setOpen(true);
        } 
        else {
          handleSectionToShow(sections[0]);
        }
      });
  };

  const validatePlate = (plate) => {
    if (plate.length === 6){
      const regexPlate=/[A-Z]{2}[0-9]{4}|[A-Z]{4}[0-9]{2}/g;
      let res = plate.match(regexPlate);
      if (res == null){
        setMessage("La patente está mal ingresada");
        setIcon("error");
        setOpen(true);
        return false;
      }
      setMessage("res.data.message");
      setIcon("");
      setOpen(false);
      return true;
   }
    else {
      setMessage("La patente está mal ingresada");
      setIcon("error");
      setOpen(true);
      return false;
    }
  };

  const validateRut = (rut) => {
    let dv_in = rut.substring(rut.length-1, rut.length);
    let rol = "" + rut.substring(0, rut.length-2);

    // Algorithm found in --> https://validarutchile.cl/calcular-rut-en-javascript.php
    let multiplier=0, accumulate=1;
    for(;rol;rol=Math.floor(rol/10)) accumulate=(accumulate+rol%10*(9-multiplier++%6))%11;
    let rest = accumulate?accumulate-1:'k';
    if (rest.toString() === dv_in.toLowerCase() && !checkError()){
      setMessage("");
      setIcon("");
      setOpen(false);
      return true;
    }
    else{
      setMessage("Rut invalido");
      setIcon("error");
      setOpen(true);
      return false;
    }
  };

  const addHyphen = () => {
    setMessage("");
    setIcon("");
    setOpen(false);
    let rut = watch("rut").replace("-","");
    let res = rut.substring(0, rut.length-1) + "-" + rut.substring(rut.length-1, rut.length);
    setValue('rut', res);
  };

  const onSubmit = (data) => {
      createAssist(data);
  };

  const checkError = () => {
      setMessage("Rut invalido");
      setIcon("error");
      setOpen(true);
  };

  const togglePlate = () => {
    setCheckboxValue(!checkboxValue);
  }

  return (
    <CardContent>
      <CardTitle main={TITLE} size="medium" />
      <br></br>
      <p>Conductores activos: {assistanceCount}</p>
      <br></br>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Transportista</label>
          <select name="id_carrier" ref={register({ required: true })}>
            {carriers.map((obj, index) => {
              const { id, name } = obj;
              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            })}
          </select>
          <label>Nombre conductor</label>
          <input name="name" type="text" ref={register({ required: true })} />
          <label>Rut conductor (Ej: 11478256-3)</label>
          <input 
            name="rut" 
            type="text" 
            id="input_rut"
            onKeyUp={() => addHyphen()} 
            ref={
              register({
                required: true,
                validate: validateRut 
              }
            )}
          />

          <label>Chasis</label>
          <input 
            id="chasis"
            className="" 
            style={{
              height: 25 + 'px',
              width: 25 + 'px',
            }}
            ref={register({ default: false })}
            name="is_chasis" type="checkbox"
            onChange={() => togglePlate()}
          />
          {checkboxValue ?  
            (<div>
              <label
                id="label_patente">
                Patente
              </label>

              <input
                id="input_patente"
                className=""
                name="plate"
                type="text"
                placeholder="AABBXX o ABXXXX"
                ref={
                  register({
                    validate: validatePlate,
                    default: false 
                  }
                )}
              />
            </div>)
          : null
        } 
          

          <button type="submit" className="submit">
            Ingresar
          </button>
          <button
            type="button"
            className="goBack"
            onClick={() => handleSectionToShow(0)}
          >
            Volver
          </button>
        </form>
      </FormContainer>
      <Snack
        open={open}
        icon={icon}
        message={message}
      />
    </CardContent>
  );
}

