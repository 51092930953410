import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from "react-admin";

import { getAllRolesLogin } from "../components/utils/roleManager/RoleManager";

require("dotenv").config();

export const apiUrl = process.env.REACT_APP_API_URL;

const permissions = getAllRolesLogin();

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(apiUrl + "/auth", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.status === 0) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("username", username);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("cd", res.data.center_distribution);
          localStorage.setItem("carrier", res.data.carrier);
          return Promise.resolve();
        } else if (res.status === 3) {
          localStorage.setItem("id", res.data.id);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", username);
          localStorage.setItem("role", "reset_password");
          return Promise.resolve();
        } else {
          if (res.data.msj) {
            return Promise.reject(res.data.msj);
          } else {
            return Promise.reject();
          }
        }
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // Called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem("role");
    if (!permissions.includes(role)) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("role");
    }
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.reject("Unknown method");
};
