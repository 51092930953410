import React, { Component } from "react";

import { CardContent, Grid, Button } from "@material-ui/core";
import { ExcelRenderer } from "react-excel-renderer";
import CardTitle from "../utils/cardTitle/CardTitle";
import TableTendering from "./TableTendering";
import ButtonContainer from "../../reusableStyles/buttons/ButtonContainer.js";
import { hasCorrectRole } from "../utils/roleManager/RoleManager";
import TenderingFilter from "./TenderingFilter";
import ErrorList from "./ErrorList";
import Workbook from "react-excel-workbook";
import Snack from "../utils/snackbar/SnackBar";
import NotFound from "../utils/notFound/NotFound";

const apiUrl = process.env.REACT_APP_API_URL;

class TenderingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Tendering",

      tenderingEndpoint: "tendering",
      tenderingData: [],

      sectionToShow: "TABLE",
      sections: ["TABLE", "ERRORS"],
      errorList: [],
    };
  }

  async callApi_DeleteTendering() {
    await fetch(`${apiUrl}/${this.state.tenderingEndpoint}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({ tenderingData: [] });
        }
      });
  }

  handleFilter = (tenderingData) => {
    this.setState({ tenderingData: tenderingData });
  };
  
  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
  };

  handleUpload = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        try {
          let rows = resp.rows;
          let checkHead = ["Origen", 
                           "RUT", 
                           "Transportista", 
                           "Tipo de Carga",
                           "Comuna",
                           "Region",
                           "Vehiculo",
                           "% Asignado a la Localidad",
                           "6009",
                           "6011",
                           "6020",
                           "Ranking",];
                           

          if (
            resp.rows[0][0].trim() === checkHead[0] &&
            resp.rows[0][1].trim() === checkHead[1] &&
            resp.rows[0][2].trim() === checkHead[2] &&
            resp.rows[0][3].trim() === checkHead[3]
          ) {
            rows.shift();
            let array = [];
            for (var key in rows) {
              let myJson = {};
              if (rows[key][0] === undefined) {
                break;
              }

              myJson["region"] = rows[key][0];
              myJson["rut"] = parseInt(rows[key][1]);
              myJson["name"] = rows[key][2];
              myJson["commune"] = rows[key][4];
              myJson["percent"] = parseInt(rows[key][7] * 100);
              myJson["cd"] = {};
              myJson["cd"]["6009"] = parseInt(rows[key][8]);
              myJson["cd"]["6011"] = parseInt(rows[key][9]);
              myJson["cd"]["6020"] = parseInt(rows[key][10]);
              myJson["ranking"] = rows[key][11];

              array.push(myJson);
            }

            fetch(`${apiUrl}/tendering`, {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
                Authorization: `jwt ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify(array),
            })
              .then((res) => res.json())
              .then((res) => {
                if (res.status === 0) {
                  this.setState({
                    open: true,
                    icon: "success",
                    message: "Excel cargado correctamente",
                  });
                } else {
                  this.setState({
                    open: true,
                    icon: "error",
                    message: "Error al actualizar información",
                  });
                } 
              })
              .catch((error) => {
              });
          } else {
            this.setState({
              open: true,
              icon: "error",
              message: "Error en el formato del archivo",
            });
          }
        } catch (error) {
          this.setState({
            open: true,
            icon: "error",
            message: "Error de conexión con el servidor"
          });     
          console.log(error);
        }
      }
    });
  };

  buildErrorList = (data) => {
    let keys = Object.keys(data);
    let errorArray = [];
    for (let key in keys) {
      for (let index in data[keys[key]]) {
        errorArray.push(
          `En línea ${keys[key]} del excel: ${data[keys[key]][index]}`
        );
      }
    }
    return errorArray;
  };

  handleErrorList = (errorList) => {
    this.setState({
      errorList: errorList,
      sectionToShow: "ERRORS",
    });
  };

  handleSectionToShow = (nextSection) => {
    this.setState({ sectionToShow: nextSection });
  };

  render() {
    const { title, tenderingData, sectionToShow, errorList } = this.state;
    const { handleShowTendering } = this.props;

    if (sectionToShow === "TABLE") {
      return (
        <CardContent>
          <ButtonContainer>
            <button onClick={() => handleShowTendering(false)}>
              MOSTRAR TRANSPORTISTAS
            </button>
            {hasCorrectRole(["trafico_full", "trafico"]) ? (
              <button onClick={() => this.callApi_DeleteTendering()}>
                  ELIMINAR TENDERING
              </button> 
            ) : null}
            {tenderingData ? (
              <Workbook
                filename="Tendering.xlsx"
                element={<button>DESCARGAR TENDERING</button>}
              >
                <Workbook.Sheet data={tenderingData} name="Tendering">
                  <Workbook.Column label="Origen" value="origin" />
                  <Workbook.Column label="RUT" value="rut"/>
                  <Workbook.Column label="Transportista" value="name" />
                  <Workbook.Column label="Tipo de Carga" value="type" />
                  <Workbook.Column label="Comuna" value="commune" />
                  <Workbook.Column label="Region" value="num_region" />
                  <Workbook.Column label="Vehiculo" value="vehicle" />
                  <Workbook.Column label="% Asignado a la Localidad" value={row => (row.percent / 100).toString()} style={{numFmt: '9'}}/>
                  <Workbook.Column label="6009" value={row => (row.cd[6009]).toString()} />
                  <Workbook.Column label="6011" value={row => (row.cd[6011]).toString()} />
                  <Workbook.Column label="6020" value={row => (row.cd[6020]).toString()} />
                  <Workbook.Column label="Ranking" value="ranking" />
                </Workbook.Sheet>
              </Workbook>
            ) : null}
          </ButtonContainer>
          <br></br>
          <CardTitle main={title} size="medium" />
          <br></br>
          <TenderingFilter handleFilter={this.handleFilter} />
          {hasCorrectRole(["trafico_full", "trafico"]) ? (
            <Grid className="myTableRight">
              <input
                id="contained-button-file"
                className="inputButton"
                multiple
                type="file"
                onChange={this.handleUpload}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  component="span"
                  className="btn btn-info btn-circle3 btn-xl3"
                >
                  CARGAR EXCEL
                </Button>
              </label>
            </Grid>
          ) : null}
          <TableTendering tenderingData={this.state.tenderingData} />
          <br></br>
          <Snack
            open={this.state.open}
            icon={this.state.icon}
            message={this.state.message}
          />
        </CardContent>
      );
      
    } else if (sectionToShow === "ERRORS") {
      return (
        <ErrorList
          errors={errorList}
          handleSectionToShow={this.handleSectionToShow}
        />
      );
    } else {
      return <NotFound />;
    }
  }
}

export default TenderingContainer;
