import React from "react";
import Workbook from "react-excel-workbook";
import { Button } from "@material-ui/core";

import { resolveDate } from "../utils/date/DateUtils";

const FlagWorkbook = ({tableContent}) => {
    return (
    <Workbook
        filename="BanderasBlancas.xlsx"
        element={
          <Button
            variant="outlined"
            component="span"
            color="secondary"
          >
            DESCARGAR
          </Button>
        }
    >
        <Workbook.Sheet
          data={tableContent}
          name="BanderasBlancas"
        >
          <Workbook.Column label="Tienda" value="store" />
          <Workbook.Column label="CD" value="center_distribution" />
          <Workbook.Column
            label="Tipo de mercaderia"
            value="ambient_desc"
          />
          <Workbook.Column
            label="Fecha inicio"
            value={row => resolveDate(row.start_date)}
          />
          <Workbook.Column
            label="Fecha termino"
            value={row => resolveDate(row.end_date)}
          />
        </Workbook.Sheet>
    </Workbook>
    )
}

export default FlagWorkbook;
