import React, {Component} from "react";
import GlsContainer from "./gls/Container";
import SchedulingContainer from "./scheduling/Container";
import NotFound from "../utils/notFound/NotFound";
import Snack from "../utils/snackbar/SnackBar";

const STEP_GLS = 'STEP_GLS';
const STEP_SCHEDULING = 'STEP_SCHEDULING';
class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            permission_Gestion: [
                "operacion_trafico_1_supervisor",
                "operacion_trafico_1_full",
                "operacion_trafico_2_supervisor",
                "operacion_trafico_2_full",
                "operacion_1_supervisor",
                "operacion_1_full",
                "operacion_2_supervisor",
                "operacion_2_full"
            ],
            permission_Visibility: ["trafico_full", "trafico"],
            snack: {open: false, icon: null, message: null},

            step: STEP_GLS,
            trailersToSchedule: []
        };
    }

    componentDidMount() {
        this.setState({ permission: this.getRole() });
    }

    getRole = () => localStorage.getItem("role");

    handleSnack = (icon, message) => {
        this.setState({
            snack: {
                open: true,
                icon: icon,
                message: message
         }});
    };

    schedule = (trailersToSchedule) => {
        this.setState({
            trailersToSchedule: trailersToSchedule,
            step: STEP_SCHEDULING,
        });
    }

    render() {
        let permissionsEnabled = this.state.permission_Gestion.includes(this.state.permission) ||
            this.state.permission_Visibility.includes(this.state.permission)
        
        if(!permissionsEnabled) {
            return <NotFound />;
        }

        let stepContent = [];
        stepContent[STEP_GLS] = <GlsContainer 
            handleSnack={this.handleSnack} 
            schedule={this.schedule}/>;

        stepContent[STEP_SCHEDULING] = <SchedulingContainer
            handleSnack={this.handleSnack} 
            triggerScheduling={this.state.triggerScheduling}
            trailersToSchedule={this.state.trailersToSchedule}/>;

        return <React.Fragment>
                    {stepContent[this.state.step]}
                    <Snack icon={this.state.snack.icon} message={this.state.snack.message} open={this.state.snack.open}/>
                </React.Fragment>;
    }
}

export default Container;
