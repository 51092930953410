import React from "react";

import { hasCorrectRole } from "../utils/roleManager/RoleManager";

import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";

import EditButton from "../../reusableStyles/buttons/EditButton.js";
import ConfirmationDialog from "../utils/confirmationDialog/ConfirmationDialog";

export default function TableCarrier(props) {
  const { carriersData, deleteCarrier, getCarrierToEdit } = props;

  return (
    <Table className="itemAlign">
      <TableHead>
        <TableRow>
          <TableCell className="myTableBorder">NOMBRE</TableCell>
          <TableCell className="myTableBorder">RUT</TableCell>
          <TableCell className="myTableBorderFixplanning">TIPO</TableCell>
          <TableCell className="myTableBorderFixplanning">VEHÍCULO</TableCell>
          <TableCell className="myTableBorderFixplanning">SPOT</TableCell>
          {hasCorrectRole(["trafico_full", "trafico"]) ? (
            <TableCell className="myTableBorderFixplanning"></TableCell>
          ) : null}
          {hasCorrectRole(["trafico_full", "trafico"]) ? (
            <TableCell className="myTableBorderFixplanning"></TableCell>
          ) : null}
        </TableRow>
      </TableHead>
      <TableBody>
        {carriersData.map((obj, index) => {
          const { id, name, rut, type, vehicle, is_spot } = obj;
          return (
            <TableRow key={id} id={id}>
              <TableCell className="myTableLeft">{name}</TableCell>
              <TableCell className="myTableLeft">{rut}</TableCell>
              <TableCell className="myTableLeft">{type}</TableCell>
              <TableCell className="myTableLeft">{vehicle}</TableCell>
              <TableCell className="myTableLeft">{(is_spot) ? "SI" : "NO"}</TableCell>
              {hasCorrectRole(["trafico_full", "trafico"]) ? (
                <TableCell className="myTableLeft">
                  <EditButton onClick={() => getCarrierToEdit(obj)}>
                    Editar
                  </EditButton>
                </TableCell>
              ) : null}
              {hasCorrectRole(["trafico_full", "trafico"]) ? (
                <TableCell className="myTableLeft">
                  <ConfirmationDialog
                    item={id}
                    passedFunction={deleteCarrier}
                    msg="Confirme eliminación de transportista"
                  />
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
