import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";
import { GET_LIST } from "react-admin";
import {
  FormControl,
  Grid,
} from "@material-ui/core";

import Select from "react-select";
import ApiProvider from "../../provider/apiProvider";

export default function Filter(props) {
  const [distCenterValues, setDistCenterValues] = useState([]);
  const [ambientValues, setAmbientValues] = useState([]);
  const [distCenterFilter, setDistCenterFilter] = useState({
    label: "Todos",
    value: -1
  });
  const [storeFilter, setStoreFilter] = useState(13);
  const [ambientFilter, setAmbientFilter] = useState("Todos");
  const { updateEventsFilter, setCDValues } = props;

  useEffect(() => {
    // Get CDs
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      setDistCenterValues(res.data);
      setCDValues(res.data);
    });
  }, []);

  useEffect(() => {
    // Get ambient descriptions
    ApiProvider(GET_LIST, "ambient-desc", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      if (res.data.status === 0) {
        setAmbientValues(res.data.data);
      } 
    });
  }, []);

  useEffect(()=>{
    // Update values
    updateFilter();
  },[ambientFilter, distCenterFilter, storeFilter]);


  const handleChangeCD = (value) => {
    setDistCenterFilter(value);
  };

  const handleChangeStore = (event) => {
    setStoreFilter(event.target.value);
  };

  const handleChangeAmbient = (value) => {
    setAmbientFilter(value.label);
  };

  const updateFilter = () => {
    updateEventsFilter(ambientFilter === "Todos"? "": ambientFilter, distCenterFilter.value? distCenterFilter.value: "", storeFilter);
  };

  const optionsCD = () => {
    let options = {
      label: "Todos",
      value: -1
    };
    return [options, ...distCenterValues.map((opt) => ({
      label: opt.id,
      value: opt.id,
    }))];
  };

  const optionsAmbient = () => {
    let options = {
      label: "Todos",
      value: "Todos"
    };
    return [options, ...ambientValues.map((opt) => ({
      label: opt.ambient_desc,
      value: opt.ambient_desc,
    }))];

  };
  return (
    <Grid container spacing={16} alignItems="flex-end">
      <Grid item>
        <FormControl>
          <Label className="labelFormItem">Tienda</Label>
          <input
            type="number"
            required
            defaultValue={13}
            className="select form-control"
            onChange={(value) => handleChangeStore(value)}
          ></input>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <Label className="labelFormItem">CD</Label>
          <Select
            id="CDSelect"
            maxMenuHeight="100"
            className="select"
            classNamePrefix="select"
            options={optionsCD()}
            onChange={(opt) => handleChangeCD(opt)}
            value={{
              label: distCenterFilter.label,
              value: distCenterFilter.value,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <Label className="labelFormItem">Ambientes</Label>
          <Select
            id="AmbientSelect"
            maxMenuHeight="100"
            className="select"
            classNamePrefix="select"
            onChange={(opt) => handleChangeAmbient(opt)}
            options={optionsAmbient()}
            value={{
              label: ambientFilter,
              value: ambientFilter,
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
