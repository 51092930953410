import React, { Component } from "react";

import { hasValidRole } from "../utils/roleManager/RoleManager";

import { Divider, CardContent, Card } from "@material-ui/core";

import CardTitle from "../utils/cardTitle/CardTitle";
import TenderingContainer from "./TenderingContainer";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Dashboard de Tendering",
    };
  }

  render() {
    const { title } = this.state;

    if (hasValidRole()) {
      return (
        <Card>
          <CardContent>
            <CardTitle main={title} /> <Divider />
            <br></br>
            <TenderingContainer />
          </CardContent>
        </Card>
      );
    } else {
      return (
        <Card>
          <CardContent>
            <CardTitle main={title} /> <Divider />
            <br></br>
            Ha ocurrido un error en autenticación
          </CardContent>
        </Card>
      );
    }
  }
}

export default Container;
