import React, { Component } from "react";

import { DELETE } from "react-admin";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Button,
  CardContent,
} from "@material-ui/core";

import Dialog from "../utils/dialog/Dialog";

import { logUserActivity } from "../utils/logger/Logger";

import ApiProvider from "../../provider/apiProvider";

class AdditionalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: [
        "trafico",
        "trafico_full",
        "operacion_trafico_1_supervisor",
      ],
      permission_Visibility: [
        "operacion_trafico_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_2_supervisor",
        "operacion_2_full",
      ],

      endpoint: "additional2",

      open_dialog: false,
      confirm: false,
      message_dialog: "",
      message_deleteOne: "¿Esta seguro de eliminar ADICIONAL?",

      delete_id: 0,

      tableContent: {},
      is_TableLoaded: false,
    };
  }

  handle_dialog = (id, message_dialog) => {
    this.setState({
      delete_id: id,
      open_dialog: true,
      message_dialog: message_dialog,
    });
  };

  handleDelete = (value) => {
    this.setState({ open_dialog: false });
    if (value) {
      ApiProvider(DELETE, this.state.endpoint, {
        id: this.state.delete_id,
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(`additional2,DELETE,${this.state.delete_id}`);
          this.props.handleSnack(true, "success", "Se ha eliminado adicional.");
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No ha sido posible eliminar adicional."
          );
        }
      });
    }
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
    this.setState({
      tableContent: this.props.tableContent,
      is_TableLoaded: true,
    });
  }

  render() {
    if (this.state.is_TableLoaded) {
      return (
        <CardContent>
          <Table className="itemAlign">
            <TableHead>
              <TableRow>
                <TableCell className="myTableBorder">CD</TableCell>
                <TableCell className="myTableBorder">TRAILER</TableCell>
                <TableCell className="myTableBorder">TIENDA(S)</TableCell>
                <TableCell className="myTableBorder">ESTADO</TableCell>
                <TableCell className="myTableBorder">PRIORIDAD</TableCell>
                <TableCell className="myTableBorder">HORA SALIDA</TableCell>
                <TableCell className="myTableBorder">USUARIO</TableCell>
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessAdditional"></TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableContent.map((obj, index) => {
                const {
                  id,
                  center_distribution,
                  route,
                  status,
                  trailer_plate,
                  type,
                  username,
                } = obj;
                let prior = "NO";
                if (type === 2 || type === 3) {
                  prior = "SI";
                }

                if (obj.time_to_exit_priority === "") {
                  obj.time_to_exit_priority = "Prioridad";
                }

                return (
                  <TableRow key={id} id={id}>
                    <TableCell className="myTableLeft">
                      {center_distribution}
                    </TableCell>
                    <TableCell className="myTableLeft">
                      {trailer_plate}
                    </TableCell>
                    <TableCell className="myTableLeft">{route}</TableCell>
                    <TableCell className="myTableLeft">{status}</TableCell>
                    <TableCell className="myTableLeft">{prior}</TableCell>
                    <TableCell
                      className="myTableLeft"
                      style={getStyle(obj.time_to_exit_priority)}
                    >
                      {obj.time_to_exit_priority}
                    </TableCell>
                    <TableCell className="myTableLeft">{username}</TableCell>
                    {this.state.permission_Gestion.includes(
                      this.state.permission
                    ) ? (
                      <TableCell className="myTableBorderLessAdditional">
                        <Button
                          onClick={() => {
                            this.handle_dialog(
                              id,
                              this.state.message_deleteOne
                            );
                          }}
                          variant="contained"
                          component="span"
                          className="btn btn-info btn-circle6 btn-xl6"
                        >
                          ELIMINAR
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Dialog
            open_dialog={this.state.open_dialog}
            message_dialog={this.state.message_dialog}
            handleDelete={this.handleDelete}
          />
        </CardContent>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}

export default AdditionalTable;

const getStyle = (param) => {
  return {
    color: param === "Prioridad" ? "crimson" : "black",
  };
};
