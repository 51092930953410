import React, { Component } from "react";
import { DELETE } from "react-admin";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Button,
  CardContent,
  Grid,
} from "@material-ui/core";

import Dialog from "../utils/dialog/Dialog";
import Workbook from "react-excel-workbook";

import { logUserActivity } from "../utils/logger/Logger";

import ApiProvider from "../../provider/apiProvider";

class FixplanningTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico", "trafico_full"],
      permission_Visibility: [
        "operacion_2_supervisor",
        "operacion_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
      ],

      endpoint: "shop",

      open_dialog: false,
      confirm: false,
      message_dialog: "",
      message_deleteOne: "¿Esta seguro de eliminar TIENDA?",

      delete_id: 0,

      tableContent: {},
      is_TableLoaded: false,
    };
  }

  handle_dialog = (id, message_dialog) => {
    this.setState({
      delete_id: id,
      open_dialog: true,
      message_dialog: message_dialog,
    });
  };

  handleDelete = (value) => {
    this.setState({ open_dialog: false });
    if (value) {
      ApiProvider(DELETE, this.state.endpoint, {
        id: this.state.delete_id,
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(`shop,DELETE,${this.state.delete_id}`);
          this.props.handleSnack(true, "success", "Se ha eliminado tienda.");
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No ha sido posible eliminar tienda."
          );
        }
      });
    }
  };

  handleEdit(id) {
    this.props.handleEditHide(true, id);
  }

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
    this.setState({
      tableContent: this.props.tableContent,
      is_TableLoaded: true,
    });
  }

  render() {
    if (this.state.is_TableLoaded) {
      return (
        <CardContent>
          <Grid container justify="flex-end">
            <Workbook
              filename="Tiendas.xlsx"
              element={
                <Button
                  variant="contained"
                  component="span"
                  className="btn btn-info btn-circle2 btn-xl2"
                >
                  DESCARGAR
                </Button>
              }
            >
              <Workbook.Sheet data={this.props.tableContent} name="Tiendas">
                <Workbook.Column label="Local" value="id_shops" />
                <Workbook.Column label="Nombre Local" value="name" />
                <Workbook.Column label="Comuna" value="commune" />
                <Workbook.Column label="Region" value="region" />
                <Workbook.Column label="Zona" value="zone" />
                <Workbook.Column label="Formato" value="format" />
                <Workbook.Column label="G. Mercado" value="" />
                <Workbook.Column label="Ventana Horaria" value="" />
                <Workbook.Column
                  label="Hora Inicio 1"
                  value="time_window_ini_1"
                />
                <Workbook.Column
                  label="Hora Final 1"
                  value="time_window_end_1"
                />
                <Workbook.Column
                  label="Hora Inicio 2"
                  value="time_window_ini_2"
                />
                <Workbook.Column
                  label="Hora Final 2"
                  value="time_window_end_2"
                />
                <Workbook.Column label="Observaciones" value="obs" />
                <Workbook.Column label="Nomenclatura Shortrec" value="" />
                <Workbook.Column label="Pies" value="" />
                <Workbook.Column label="Tipo Carro" value="car_type" />
                <Workbook.Column label="Pallets" value="pallets" />
                <Workbook.Column label="CD" value="center_distribution" />
                <Workbook.Column label="Fecha Actualizacion" />
              </Workbook.Sheet>
            </Workbook>
          </Grid>
          <Table className="itemAlign">
            <TableHead>
              <TableRow>
                <TableCell className="myTableBorder">TIENDA</TableCell>
                <TableCell className="myTableBorder">CD</TableCell>
                <TableCell className="myTableBorderFixplanning">ZONA</TableCell>
                <TableCell className="myTableBorderFixplanning">FORMATO</TableCell>
                <TableCell className="myTableBorderFixplanning">HORA INICIO 1</TableCell>
                <TableCell className="myTableBorderFixplanning">HORA FINAL 1</TableCell>
                <TableCell className="myTableBorderFixplanning">HORA INICIO 2</TableCell>
                <TableCell className="myTableBorderFixplanning">HORA FINAL 2</TableCell>
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessFlag"></TableCell>
                ) : null}
                {this.state.permission_Gestion.includes(
                  this.state.permission
                ) ? (
                  <TableCell className="myTableBorderLessFlag"></TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.tableContent.map((obj, index) => {
                const {
                  id,
                  center_distribution,
                  id_shops,
                  zone,
                  format,
                  time_window_ini_1,
                  time_window_end_1,
                  time_window_ini_2,
                  time_window_end_2,
                } = obj;
                return (
                  <TableRow key={id} id={id}>
                    <TableCell className="myTableLeft">{id_shops}</TableCell>
                    <TableCell className="myTableLeft">{center_distribution}</TableCell>
                    <TableCell className="myTableLeft">{zone}</TableCell>
                    <TableCell className="myTableLeft">{format}</TableCell>
                    <TableCell className="myTableLeft">{time_window_ini_1}</TableCell>
                    <TableCell className="myTableLeft">{time_window_end_1}</TableCell>
                    <TableCell className="myTableLeft">{time_window_ini_2}</TableCell>
                    <TableCell className="myTableLeft">{time_window_end_2}</TableCell>
                    {this.state.permission_Gestion.includes(
                      this.state.permission
                    ) ? (
                      <TableCell className="myTableBorderLessFlag">
                        <Button
                          onClick={() => {
                            this.handleEdit(id);
                          }}
                          variant="contained"
                          component="span"
                          className="btn btn-info btn-circle6 btn-xl6"
                        >
                          EDITAR
                        </Button>
                      </TableCell>
                    ) : null}
                    {this.state.permission_Gestion.includes(
                      this.state.permission
                    ) ? (
                      <TableCell className="myTableBorderLessAdditional">
                        <Button
                          onClick={() => {
                            this.handle_dialog(
                              id,
                              this.state.message_deleteOne
                            );
                          }}
                          variant="contained"
                          component="span"
                          className="btn btn-info btn-circle6 btn-xl6"
                        >
                          ELIMINAR
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Dialog
            open_dialog={this.state.open_dialog}
            message_dialog={this.state.message_dialog}
            handleDelete={this.handleDelete}
          />
        </CardContent>
      );
    } else {
      return <CardContent></CardContent>;
    }
  }
}
export default FixplanningTable;
