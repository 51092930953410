import React from "react";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  CardContent,
} from "@material-ui/core";

import Switch from '@material-ui/core/Switch';

export const apiUrl = process.env.REACT_APP_API_URL;

function ControllerTable({
  controllerData,
  setDisplayOption,
  updateConfiguration,
}) {
  const orderedData = controllerData.sort(
    function(a,b){
      return a.id - b.id;
    })
  if (controllerData) {
    return (
      <CardContent>
        <h1 className="myTitle">Controlador de estado del Algoritmo</h1>
        <Table className="itemAlign">
          <TableHead>
            <TableRow>
              <TableCell className="myTableLeft"></TableCell>
              <TableCell className="myTableBorder">CD</TableCell>
              <TableCell className="myTableBorder">
                Algoritmo habilitado
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedData.map((obj, index) => {
              const {id, id_cd, status} = obj;
              return (
                <TableRow key={id_cd} id={id}>
                  <TableCell className="myTableLeft"></TableCell>
                  <TableCell className="myTableLeft">{id_cd}</TableCell>
                  <TableCell>
                    <Switch
                      checked={status}
                      onChange={() => updateConfiguration(id, status)}
                    />
                    {status ? "Flexible" : "Rígido"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    );
  } else {
    return <CardContent>Data no disponible</CardContent>;
  }
}

export default ControllerTable;
