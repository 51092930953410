// Transform a number to a string, padding with 0s to reach minDigits digits
// Only works for integers; the fractional part is discarded
// E.g. formatNumber(1, 2) === "01"
// E.g. formatNumber(1.1, 2) === "01"
function formatIntegerMinDigits(num, minDigits) {
    return num.toLocaleString('en-US', {minimumIntegerDigits: minDigits, useGrouping:false});
}

// Round a number to 2 decimal places; returns a number
// E.g. round(1.31231) === 1.31
// E.g. round(1.31631) === 1.32
function round(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export {formatIntegerMinDigits, round}