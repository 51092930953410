import React from "react";
import dayjs from "dayjs";

const SCHEDULE_STRICT = 'STRICT';
const SCHEDULE_LOOSE = 'LOOSE';

const MORNING_SCHEDULE = [0, 7];
const AM_SCHEDULE = [7, 12];
const PM_SCHEDULE = [12, 18];
const NIGHT_SCHEDULE = [18, 24];

/**
 * Gets the shift name based on the intervals defined by the client and the schedule time.
 * @param {Date} startTime 
 * @returns {String} The shift name
 */
export const getShiftName = (startTime) => {
    const hour = startTime.getHours() + startTime.getMinutes() / 60;
    if (MORNING_SCHEDULE[0] <= hour && hour < MORNING_SCHEDULE[1]) {
        return 'Madrugada';
    }
    if (AM_SCHEDULE[0] <= hour && hour < AM_SCHEDULE[1]) {
        return 'AM';
    }
    if (PM_SCHEDULE[0] <= hour && hour < PM_SCHEDULE[1]) {
        return 'PM';
    }
    if (NIGHT_SCHEDULE[0] <= hour && hour < NIGHT_SCHEDULE[1]) {
        return 'Noche';
    }
};

export default function ScheduleField(props) {
    const { scheduleType, startTime } = props;
    const dateString = dayjs(startTime).format("DD/MM/YYYY") + ' ';
    const timeString = dayjs(startTime).format("HH:mm");

    return (
        startTime &&
        <React.Fragment>
            {scheduleType === SCHEDULE_LOOSE && dateString + getShiftName(startTime)}
            {scheduleType === SCHEDULE_STRICT && dateString + timeString}
        </React.Fragment>
    );
}
