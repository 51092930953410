import React from "react";
import { Pie, PieChart, Tooltip, Cell } from "recharts";
import { Paper, Grid } from "@material-ui/core/";

import CardTitle from "../../utils/cardTitle/CardTitle";

function motiveCharts({ data, colision, tendering, zone }) {
  const totaldistributionCenter = data.find(
    (distributionCenter) => distributionCenter.name === "Walmart"
  );

  let colisionHoy = colision[colision.length - 1];
  let arrColisionPercentage = colision.map(
    (entry) => entry.porcentaje_colision
  );
  let minColision = Math.min(...arrColisionPercentage);
  let maxColision = Math.max(...arrColisionPercentage);

  function pickColisionCircleColor(colisionPercentage, min, max) {
    let rangeStep = (max - min) / 10;
    let differenceFromMin = colisionPercentage - min;
    let numberOfColor = Math.trunc(differenceFromMin / rangeStep);

    return colisionCircleColors[numberOfColor];
  }

  const pickedColisionCircleColor = pickColisionCircleColor(
    colisionHoy.porcentaje_colision,
    minColision,
    maxColision
  );

  function pickTenderingCircleColor(tendering) {
    return tendering <= 30
      ? tenderingCircleColors[0]
      : tendering <= 50
      ? tenderingCircleColors[1]
      : tenderingCircleColors[2];
  }

  let finalChartColors = [];

  const buildDataForHalfPieChart = (data) => {
    return [
      data,
      {
        name: "Otros",
        Fuera: data.total - data.Fuera,
      },
    ];
  };

  const buildHalfPieChart = (halfChartData) => (
    <div style={chartStyle.halfChartContainer}>
      <p style={chartStyle.halfChartName}>CD {halfChartData[0].name}</p>
      <p>
        {((halfChartData[0].Fuera * 100) / halfChartData[0].total).toFixed(1)}%
      </p>
      <PieChart width={180} height={160}>
        <Pie
          data={halfChartData}
          dataKey="Fuera"
          nameKey="name"
          innerRadius={50}
          outerRadius={80}
          fill="#3f51b5"
          startAngle={180}
          endAngle={0}
        >
          {halfChartData.map((entry, index) => (
            <Cell
              key={index}
              fill={halfChartColors[index % halfChartColors.length]}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );

  const buildDataForFullPieChart = (data) => {
    let arr = [];
    finalChartColors = [];
    if (data.Fuera !== 0) {
      arr.push({ name: "Fuera", value: data.Fuera });
      finalChartColors.push(fullChartColors[0]);
    }
    if (data.Prioridad !== 0) {
      arr.push({ name: "Prioridad", value: data.Prioridad });
      finalChartColors.push(fullChartColors[1]);
    }
    if (data["No prioridad"] !== 0) {
      arr.push({ name: "No prioridad", value: data["No prioridad"] });
      finalChartColors.push(fullChartColors[2]);
    }
    if (data.Modelo !== 0) {
      arr.push({ name: "Modelo", value: data.Modelo });
      finalChartColors.push(fullChartColors[3]);
    }
    if (data.Devolucion !== 0) {
      arr.push({ name: "Devolución", value: data.Devolucion });
      finalChartColors.push(fullChartColors[4]);
    }
    return arr;
  };

  const buildFullPieChart = (simpleData, fullChartData) => (
    <PieChart width={350} height={250}>
      <text x={175} y={125} textAnchor="middle" dominantBaseline="middle">
        Total: {simpleData.total}
      </text>
      <Pie
        data={fullChartData}
        dataKey="value"
        nameKey="name"
        innerRadius={50}
        outerRadius={80}
        label={({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          value,
          index,
        }) => {
          const RADIAN = Math.PI / 180;
          const radius = 25 + innerRadius + (outerRadius - innerRadius);
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);
          return (
            <text
              style={chartStyle.fullChartLabel}
              x={x}
              y={y}
              fill="#000000"
              textAnchor={x > cx ? "start" : "end"}
              dominantBaseline="central"
            >
              {fullChartData[index].name}: {value}
            </text>
          );
        }}
      >
        {fullChartData.map((entry, index) => (
          <Cell
            key={index}
            fill={finalChartColors[index % finalChartColors.length]}
          />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  );

  return (
    <div>
      <Grid container>
        <Grid container item xs={4}>
          <Paper style={chartStyle.ChartPaperSection}>
            <h3>COLISIONES</h3>
            <hr />
            <div style={chartStyle.halfChartContainer}>
              <p>Porcentaje colisión</p>
              <div
                style={{
                  ...TodayCircle,
                  backgroundColor: pickedColisionCircleColor,
                }}
              >
                <span style={chartStyle.halfChartCenterPercent}>
                  {colisionHoy.porcentaje_colision}%
                </span>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid container item xs={4}>
          <Paper style={chartStyle.ChartPaperSection}>
            <h3>TENDERING</h3>
            <hr />
            <div style={chartStyle.halfChartContainer}>
              <p>Porcentaje desviación</p>
              <div
                style={{
                  ...TodayCircle,
                  backgroundColor: pickTenderingCircleColor(tendering),
                }}
              >
                <span style={chartStyle.halfChartCenterPercent}>
                  {tendering}%
                </span>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid container item xs={4}>
          <Paper style={chartStyle.ChartPaperSection}>
            <CardTitle main="POR FUERA" optional={zone} size="medium" />
            <hr />
            <div style={chartStyle.halfChartContainer}>
              <p>Total</p>
              <div style={chartStyle.halfChartsCenter}>
                <span style={chartStyle.halfChartCenterPercent}>
                  {totaldistributionCenter &&
                    `${(
                      (totaldistributionCenter.Fuera * 100) /
                      totaldistributionCenter.total
                    ).toFixed(2)}%`}
                </span>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <br />

      <div>
        <Paper>
          <CardTitle
            main="DESPACHADOS POR FUERA CENTROS DE DISTRIBUCIÓN"
            optional={zone}
            size="medium"
          />
          <br />
          <Grid container>
            {data.map((distributionCenter) => {
              if (
                (
                  (distributionCenter.Fuera * 100) /
                  distributionCenter.total
                ).toFixed(1) >= 0 &&
                distributionCenter.name !== "Walmart"
              ) {
                return (
                  <Grid container item xs={4} key={distributionCenter.name}>
                    <div style={chartStyle.halfChartsCenterGrid}>
                      {buildHalfPieChart(
                        buildDataForHalfPieChart(distributionCenter)
                      )}
                    </div>
                  </Grid>
                );
              }
            })}
          </Grid>
        </Paper>
      </div>

      <Paper style={{ marginTop: "20px", paddingTop: "20px" }}>
        <CardTitle main="MOTIVOS DE SALIDA" optional={zone} size="medium" />
        <hr />
        <div>
          <Grid container>
            {data.map((distributionCenter) => {
              if (distributionCenter.name !== "Walmart") {
                return (
                  <Grid item item xs={6} key={distributionCenter.name}>
                    <div style={chartStyle.halfChartsCenterGrid}>
                      <p style={chartStyle.fullChartName}>
                        {distributionCenter.name}
                      </p>
                      {buildFullPieChart(
                        distributionCenter,
                        buildDataForFullPieChart(distributionCenter)
                      )}
                    </div>
                  </Grid>
                );
              }
            })}
          </Grid>
          <hr />
          <div style={chartStyle.halfChartsCenterGrid}>
            <p style={chartStyle.fullChartName}>Total</p>
            {totaldistributionCenter &&
              buildFullPieChart(
                totaldistributionCenter,
                buildDataForFullPieChart(totaldistributionCenter)
              )}
          </div>
        </div>
      </Paper>
    </div>
  );
}

const chartStyle = {
  spreadChartsSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  spreadTotalChartsSection: {
    display: "flex",
    justifyContent: "space-around",
    maxWidth: "300px",
    transform: "translate(400px,-460px)",
  },
  halfChartContainer: {
    alignText: "center",
  },
  ChartPaperSection: {
    paddingTop: 20,
    marginRight: 10,
    width: "100%",
  },
  halfChartName: {
    paddingTop: 20,
    marginBottom: 0,
  },
  halfChartsCenter: {
    backgroundColor: "#c91029",
    borderRadius: "50%",
    color: "#ffffff",
    fontWeight: "bold",
    width: 100,
    height: 100,
    display: "table",
    margin: "50px auto",
  },
  halfChartsCenterGrid: {
    width: 100,
    height: 100,
    display: "table",
    margin: "50px auto",
  },
  halfChartCenterPercent: {
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
  },
  fullChartName: {
    marginBottom: 0,
    fontWeight: "bold",
    fontSize: 30,
  },
  fullChartPaperSection: {
    width: "1110px",
    margin: "auto",
  },
  fullChartLabel: {
    fontSize: 10,
  },
  translateChartRigth: {
    transform: "translate(40px)",
  },
  translateChartLeft: {
    transform: "translate(-40px)",
  },
};

const halfChartColors = ["#c91029", "#38b516"];
const fullChartColors = ["#c91029", "#43a307", "#e6bf00", "#0d17a6", "#b8b8b8"];
const colisionCircleColors = [
  "#00cc00",
  "#56E212",
  "#79DF10",
  "#9CDC0E",
  "#BED90B",
  "#D6CB09",
  "#D3A307",
  "#D07C05",
  "#CD5503",
  "#CA2F01",
  "#C70800",
];

const tenderingCircleColors = ["#00cc00", "#D6CB09", "#C70800"];

const TodayCircle = {
  borderRadius: "50%",
  color: "#ffffff",
  fontWeight: "bold",
  width: 100,
  height: 100,
  display: "table",
  margin: "50px auto",
};

export default motiveCharts;
