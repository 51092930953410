import React from "react";
import {
  TableCell,
  TableRow,
} from "@material-ui/core/";

const BuildTableRow = ({ data, keys }) => (
  <>
    {Object.keys(keys).map((title) => (
      <TableRow key={title}>
        <TableCell>{title}</TableCell>
        {data.map((element) => (
          <TableCell key={element.name} align="right">{element[keys[title]]}</TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export default BuildTableRow;
