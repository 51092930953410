import React from "react";
import "./App.css";
import { Admin, ListGuesser, Resource, Login } from "react-admin";
import { Snackbar, SnackbarContent } from "@material-ui/core";

import Dashboard from "./app/Dashboard";
import authProvider from "./provider/authProvider";
import myDataProv from "./provider/apiProvider";
import MyLayout from "./components/layout/MyLayout";
import MyHeader from "./components/layout/Header";
import NotFound from "./components/utils/notFound/NotFound";
import {
  CenterList,
  CenterEdit,
  CenterCreate
} from "./components/center/CenterList";
import customRoutes from "./routes";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import ErrorIcon from "@material-ui/icons/Error";

import Intercom from "react-intercom";
import { configAnalitics } from "./components/utils/logger/Logger";
import { getAllRolesLogin } from "./components/utils/roleManager/RoleManager"


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: getAllRolesLogin(),
      warningTime: 1000 * 60 * 59,
      signoutTime: 1000 * 60 * 60,
      open: false,
      myCounter: ""
    };
  }

  componentDidMount() {
    configAnalitics();
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    this.setTimeout();
  }

  clearTimeoutFunc = () => {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    if (!window.location.hash.includes("login")) {
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    }
  };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    this.setTimeout();
  };

  warn = () => {
    this.setState({
      open: true,
      icon: "warning",
      myCounter:
        "Serás deslogueado automaticamente en 1 minuto por inactividad."
    });
  };

  logout = () => {
    this.destroy();
  };

  destroy = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    window.location.assign("/");
  };

  handleStyleSnack = () => {
    if (this.state.icon === "success") {
      return "mySnackBarSuccess";
    } else if (this.state.icon === "error") {
      return "mySnackBarError";
    } else if (this.state.icon === "warning") {
      return "mySnackBarWarning";
    }
  };

  handleIconSnack = () => {
    if (this.state.icon === "success") {
      return <CheckCircleIcon />;
    } else if (this.state.icon === "error") {
      return <ErrorIcon />;
    } else if (this.state.icon === "warning") {
      return <WarningIcon />;
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  myApp = () => <Login backgroundImage="none" />;

  render() {
    return (
      <div className="app">
        <MyHeader />
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Admin
          loginPage={this.myApp}
          dashboard={Dashboard}
          authProvider={authProvider}
          dataProvider={myDataProv}
          appLayout={MyLayout}
          customRoutes={customRoutes}
          catchAll={NotFound}
        >
          {permissions => [
            this.state.permission.includes(permissions) ? (
              <Resource
                name="distribution-center"
                options={{ label: "Centros" }}
                list={CenterList}
                edit={permissions === "trafico_full" ? CenterEdit : null}
                create={permissions === "trafico_full" ? CenterCreate : null}
              />
            ) : null,

            permissions === "admin" ? (
              <Resource
                name="zones"
                options={{ label: "Zonas" }}
                list={ListGuesser}
              />
            ) : null
          ]}
        </Admin>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          autoHideDuration={20000}
          onClose={this.handleClose}
          open={this.state.open}
        >
          <SnackbarContent
            className={this.handleStyleSnack()}
            message={
              <span id="message-id" className="colorSpan">
                {this.handleIconSnack()}
                &nbsp;&nbsp;&nbsp;
                {this.state.myCounter}
              </span>
            }
          ></SnackbarContent>
        </Snackbar>
        <Intercom
          appID="rt4s7wnc"
          name={localStorage.getItem("name")}
          email={localStorage.getItem("email")}
        />
      </div>
    );
  }
}
export default App;
