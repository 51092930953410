import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";
import { GET_LIST, CREATE } from "react-admin";

import { Label } from "semantic-ui-react";
import Select from "react-select";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class AditionalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "ADICIONALES",

      endpoint: "additional2",

      center_distribution: 0,
      store: 0,
      trailer_plate: "",
      route: "",
      status: "",
      time_to_exit_priority: "",
      time_window_ini: "",
      time_window_end: "",

      cdSelect: [],
      trailerSelect: [],
      is_SelectLoaded: false,
      is_SelectTrailerLoaded: false,
      trailerPlateShow: "",

      priority: 0,
      type: 4,
      filterTrailerDefault: null,
      trailerList: null,
    };
  }

  callApi_Distribution = () => {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      this.setState({ cdSelect: res.data, is_SelectLoaded: true, is_SelectTrailerLoaded: true });
    });
  };

  callApi_Trailer = (id_cd, id_store) => {
    let myFilter = {};
    if (id_cd !== 0) {
      myFilter.center_distribution = id_cd;
    }
    if (id_store !== 0) {
      myFilter.store = id_store;
    }

    ApiProvider(GET_LIST, "trailer", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: myFilter,
    }).then((res) => {
      if (res.data.status === 0) {
        let myArray = [];
        let myFullArray = [];
        for (var i in res.data.data[0]) {
          myArray.push(i);
          const trailerData = {
            route: res.data.data[0][i].route,
            status: res.data.data[0][i].status,
            trailer_plate: res.data.data[0][i].trailer_plate,
            trailerPlateShow: res.data.data[0][i].trailer_plate  + " - ",
          }
          myFullArray.push(trailerData);
        }
        this.setState({ trailerList: myFullArray, trailerSelect: myArray, is_SelectTrailerLoaded: true });
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No ha sido posible cargar datos del trailer."
        );
        this.setState({ trailerSelect: [] });
      }
    });
  };

  handleCD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    this.setState({ center_distribution: id_cd, trailerSelect: [], filterTrailerDefault: null });
    this.callApi_Trailer(id_cd, this.state.store);
  };

  handlePriority = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let priority = parseInt(value);
    this.setState({ priority: priority });
  };

  handleType = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let type = parseInt(value);
    this.setState({ type: type });
  };

  handleStore = (value) => {
    if (value === "") {
      value = 0;
    }
    let id_store = parseInt(value);
    this.setState({ store: id_store });
  };

  filterStores = () => {
    this.setState({ trailerSelect: [], filterTrailerDefault: null, route: "", status: "", trailer_plate: "", trailerPlateShow: "" });
    this.callApi_Trailer(this.state.center_distribution, this.state.store);
  }

  handleTrailer = (opt) => {
    this.setState({ filterTrailerDefault: opt });
    let trailerTemp = null;
    for(const trailer in this.state.trailerList) {
      if (this.state.trailerList[trailer].trailer_plate === opt.value) {
        trailerTemp = this.state.trailerList[trailer];
        break;
      }
    }
    if (trailerTemp !== null)
      this.setState({
        route: trailerTemp.route,
        status: trailerTemp.status,
        trailer_plate: trailerTemp.trailer_plate,
        trailerPlateShow: trailerTemp.trailerPlateShow,
      });
    else {
      this.setState({
        route: "",
        status: "",
        trailer_plate: "",
        trailerPlateShow: "",
      });
    }
  };

  handleCreate = () => {
    if (
      this.state.center_distribution === 0 ||
      this.state.trailer_plate === ""
    ) {
      this.props.handleSnack(
        true,
        "warning",
        "Debe completar los datos faltantes."
      );
    } else if (this.state.time_window_end < this.state.time_window_ini) {
      this.props.handleSnack(
        true,
        "warning",
        "La hora de Inicio debe ser mayor a la hora Final."
      );
    } else {
      ApiProvider(CREATE, this.state.endpoint, {
        data: {
          trailer_plate: this.state.trailer_plate,
          center_distribution: this.state.center_distribution,
          route: this.state.route,
          status: this.state.status,
          time_to_exit_priority: this.state.time_to_exit_priority,
          time_window_ini: this.state.time_window_ini,
          time_window_end: this.state.time_window_end,
          type: this.state.type,
        },
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(
            `${this.state.endpoint},CREATE,${this.state.trailer_plate}`
          );
          this.props.handleSnack(true, "success", "Se ha agregado adicional.");
          this.props.handleFormHide(false);
        } else {
          this.props.handleSnack(true, "error", `${res.data.data[0].message}`);
        }
      });
    }
  };

  componentDidMount() { this.callApi_Distribution(); }

  render() {
    if (this.state.is_SelectLoaded && this.state.is_SelectTrailerLoaded) {
      if (this.state.priority === 0 || this.state.type === 4) {
        return (
          <CardContent>
            <Grid container spacing={32} justify="center">
              <Grid item xs={12} sm={12} className={"GridSpace"}>
                <Label>¿Adicional con o sin prioridad?</Label>
                <br></br>
                <Select
                  id="priority"
                  className="select"
                  classNamePrefix="select"
                  options={priority.map((opt) => ({
                    label: opt.label,
                    value: opt.id,
                  }))}
                  onChange={(opt) => this.handlePriority(opt.value)}
                />
              </Grid>
              {this.state.priority === 1 ? (
                <Grid item xs={12} sm={12} className={"GridSpace"}>
                  <Label>¿Desea reemplazar la ventana horaria?</Label>
                  <br></br>
                  <Select
                    id="type1"
                    className="select"
                    classNamePrefix="select"
                    options={typeNot.map((typeNot) => ({
                      label: typeNot.label,
                      value: typeNot.id,
                    }))}
                    onChange={(typeNot) => this.handleType(typeNot.value)}
                  />
                </Grid>
              ) : null}
              {this.state.priority === 2 ? (
                <Grid item xs={12} sm={12} className={"GridSpace"}>
                  <Label>Desea programarlos para salidas:</Label>
                  <br></br>
                  <Select
                    id="type2"
                    className="select"
                    classNamePrefix="select"
                    options={typeYes.map((typeYes) => ({
                      label: typeYes.label,
                      value: typeYes.id,
                    }))}
                    onChange={(typeYes) => this.handleType(typeYes.value)}
                  />
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        );
      } else {
        return (
          <CardContent>
            <Grid container spacing={32}>
              <Grid item xs={12} sm={12} className="inline">
                <Label className="myLabel">CD</Label>
                <Select
                  id="cdSelect"
                  className="select"
                  classNamePrefix="select"
                  options={this.state.cdSelect.map((opt) => ({
                    label: opt.id,
                    value: opt.id,
                  }))}
                  onChange={(opt) => this.handleCD(opt.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="inline">
                <Label className="myLabel">TIENDA</Label>
                <input
                  onChange={(event) => this.handleStore(event.target.value)}
                  defaultValue="0"
                  min="0"
                  id="store_create"
                  className="select form-control"
                  type="number"
                  required
                />
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.filterStores()}
                >
                  Filtrar por tienda
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} className="inline">
                <Label className="myLabel">BUSCA TRAILER</Label>
                <Select
                  native
                  required
                  id="flags_create"
                  className="select"
                  classNamePrefix="select"
                  value={this.state.filterTrailerDefault}
                  options={this.state.trailerSelect.map((opt) => ({
                    label: opt,
                    value: opt,
                  }))}
                  onChange={(opt) => this.handleTrailer(opt)}
                />
              </Grid>
              <Grid item xs={12} sm={12} className="inline">
                <Label className="myLabel">RUTA</Label>
                <Label className="myLabelAdditional">
                  {this.state.trailerPlateShow} {this.state.route}
                </Label>
              </Grid>
              <Grid item xs={12} sm={12} className="inline">
                <Label className="myLabel">ESTADO</Label>
                <Label className="myLabel">{this.state.status}</Label>
              </Grid>
              {this.state.type === 3 ? (
                <Grid item xs={12} sm={12} className="inline">
                  <Label className="myLabel">HORA SALIDA</Label>
                  <Select
                    id="priorityForm"
                    styles={customStyles}
                    className="select"
                    classNamePrefix="select"
                    options={hourSelect.map((opt) => ({
                      label: opt.label,
                      value: opt.id,
                    }))}
                    onChange={(opt) =>
                      this.setState({ time_to_exit_priority: opt.label })
                    }
                  />
                </Grid>
              ) : null}
              {this.state.type === 1 ? (
                <Grid item xs={12} sm={12} className="inline">
                  <Label className="myLabel">HORA INICIO</Label>
                  <Select
                    id="priorityForm"
                    styles={customStyles}
                    className="select"
                    classNamePrefix="select"
                    options={hourSelect.map((opt) => ({
                      label: opt.label,
                      value: opt.id,
                    }))}
                    onChange={(opt) =>
                      this.setState({ time_window_ini: opt.label })
                    }
                  />
                </Grid>
              ) : null}
              {this.state.type === 1 ? (
                <Grid item xs={12} sm={12} className="inline">
                  <Label className="myLabel">HORA FINAL</Label>
                  <Select
                    id="priorityForm"
                    styles={customStyles}
                    className="select"
                    classNamePrefix="select"
                    options={hourSelect.map((opt) => ({
                      label: opt.label,
                      value: opt.id,
                    }))}
                    onChange={(opt) =>
                      this.setState({ time_window_end: opt.label })
                    }
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} className="myTableCenter">
                <Button
                  onClick={() => this.handleCreate()}
                  variant="contained"
                  component="span"
                  className="btn btn-info btn-circle4 btn-xl4"
                >
                  CREAR
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        );
      }
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">{this.state.title}</h4>
        </CardContent>
      );
    }
  }
}

export default AditionalForm;

const hourSelect = [
  { id: 0, label: "00:00" },
  { id: 1, label: "01:00" },
  { id: 2, label: "02:00" },
  { id: 3, label: "03:00" },
  { id: 4, label: "04:00" },
  { id: 5, label: "05:00" },
  { id: 6, label: "06:00" },
  { id: 7, label: "07:00" },
  { id: 8, label: "08:00" },
  { id: 9, label: "09:00" },
  { id: 10, label: "10:00" },
  { id: 11, label: "11:00" },
  { id: 12, label: "12:00" },
  { id: 13, label: "13:00" },
  { id: 14, label: "14:00" },
  { id: 15, label: "15:00" },
  { id: 16, label: "16:00" },
  { id: 17, label: "17:00" },
  { id: 18, label: "18:00" },
  { id: 19, label: "19:00" },
  { id: 20, label: "20:00" },
  { id: 21, label: "21:00" },
  { id: 22, label: "22:00" },
  { id: 23, label: "23:00" },
];

const customStyles = {
  menuList: () => ({
    height: 100,
    overflowY: "auto",
  }),
};

const priority = [
  { id: 2, label: "Con prioridad" },
  { id: 1, label: "Sin prioridad" },
];

const typeNot = [
  { id: 1, label: "Si" },
  { id: 0, label: "No" },
];

const typeYes = [
  { id: 2, label: "Salida inmediata" },
  { id: 3, label: "Mas tarde" },
];
