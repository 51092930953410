import React, { Component } from "react";
import { Grid, Snackbar, SnackbarContent } from "@material-ui/core";
import { UPDATE_MANY, CREATE } from "react-admin";

import ApiProvider from "../../provider/apiProvider";
import MyTable from "./TableBody";
import { logUserActivity } from "../utils/logger/Logger";

import ErrorIconIcon from "@material-ui/icons/Error";

class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      notselected: [],
      notavailable: [],
      open: false,
    };
  }

  componentDidMount() {
    this.populateTables();
  }

  populateTables = () => {
    var selected = [];
    var notselected = [];

    for (let parameters of Object.values(this.props.contentData)) {
      if (
        parameters["is_alternative"] === 0 ||
        parameters["is_alternative"] === 2
      ) {
        selected.push(parameters);
      } else {
        notselected.push(parameters);
      }
    }
    this.setState({ selected: selected });
    this.setState({ notselected: notselected });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    ApiProvider(CREATE, "optimization/departures", {
      data: {
        id_optimization: this.props.idOptimization,
      },
    }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(
          `optimization/departures,CREATE,${this.props.idOptimization}`
        );
        window.location.href = "";
      } else {
        this.props.getGestionResult(res.data.data.fail_departures);
      }
    });
  };

  handleChange = (idResult, idOptimzation, alternative) => {
    if (alternative !== 2) {
      this.changeItemStatusApi(idResult, idOptimzation, alternative);
    } else {
      this.setState({ open: true });
    }
  };

  changeItemStatusApi = (idResult, idOptimzation, alternative) => {
    var mySelected = this.state.selected;

    if (
      mySelected.includes(this.props.contentData[idResult.toString()]) ||
      mySelected.length < this.props.selectQuantity
    ) {
      let alternativeBody = alternative === 1 ? 0 : 1;
      ApiProvider(
        UPDATE_MANY,
        `optimization/${idOptimzation}/results/${idResult}`,
        {
          data: { is_alternative: alternativeBody },
        }
      ).then((res) => {
        logUserActivity(
          `optimization/${idOptimzation}/results/${idResult},UPDATE_MANY,${idResult}`
        );
        this.props.getOptimizationResults(idOptimzation);
      });
    }
  };

  handleChangeToUnavailable = (
    idResult,
    idOptimzation,
    is_alternative,
    trailer_plate
  ) => {
    if (is_alternative !== 2) {
      this.changeItemStatusApiUnavailable(
        idResult,
        idOptimzation,
        trailer_plate
      );
    } else {
      this.setState({ open: true });
    }
  };

  changeItemStatusApiUnavailable = (idResult, idOptimzation, trailer_plate) => {
    ApiProvider(CREATE, "trailer/lock", {
      data: {
        id_optimization: idOptimzation,
        trailer_plate: trailer_plate,
      },
    }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(
          `trailer/lock,CREATE,id ${idOptimzation} plate ${trailer_plate}`
        );
        ApiProvider(
          UPDATE_MANY,
          `optimization/${idOptimzation}/results/${idResult}`,
          {
            data: { is_alternative: 3 },
          }
        ).then((res) => {
          this.props.getOptimizationResults(idOptimzation);
        });
      } else {
        this.setState({ open: true });
      }
    });
    console.log("Cambiado");
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <form id="myOptimizerTable" onSubmit={this.handleSubmit}>
        <Grid container spacing={32}>
          <Grid item xs={12} className="myTableCenter">
            <h5 className="myTableCenter">
              Estas son nuestras sugerencias de los resultados de optimización.
            </h5>
          </Grid>
          <Grid item xs={12} className="myTableCenter">
            <MyTable
              tableContent={this.state.selected}
              data={this.props.contentData}
              ifChecked={true}
              handleChange={this.handleChange}
              handleChangeToUnavailable={this.handleChangeToUnavailable}
              notavailable={this.state.notavailable}
            />
          </Grid>
          <Grid item xs={12} className="myTableCenter">
            <h5 className="myTableCenter">Resultados alternativos</h5>
          </Grid>
          <Grid item xs={12} className="myTableCenter">
            <MyTable
              tableContent={this.state.notselected}
              data={this.props.contentData}
              ifChecked={false}
              handleChange={this.handleChange}
              handleChangeToUnavailable={this.handleChangeToUnavailable}
              notavailable={this.state.notavailable}
            />
          </Grid>
          <Grid item xs={12} className="myTableCenter">
            <button
              id="submit"
              type="submit"
              className="btn btn-info btn-circle btn-xl"
            >
              GESTIONAR
            </button>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          autoHideDuration={5000}
          onClose={this.handleClose}
          open={this.state.open}
        >
          <SnackbarContent
            className="mySnackBarError"
            message={
              <span id="message-id" className="colorSpan">
                <ErrorIconIcon />
                &nbsp;&nbsp;&nbsp; Este es un tráiler adicional por lo que debe
                gestionarse.
              </span>
            }
          ></SnackbarContent>
        </Snackbar>
      </form>
    );
  }
}

export default TableView;
