import React, {Component} from "react";
import {Button, FormControl, Grid} from "@material-ui/core";
import {GET_LIST} from "react-admin";
import {Label} from "semantic-ui-react";
import Select from "react-select";
import ApiProvider from "../../../provider/apiProvider";


class TableFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cdSelect: [],
            idCd: parseInt(localStorage.getItem("cd")),
            idStore: 0,
            trailerPlate: "",
            isSelectLoaded: false,
            endpoint: this.props.endpoint,
            selectedZone: "REGIONES",
        };
    }

    callApiDistribution = () => {
        ApiProvider(GET_LIST, "distribution-center", {
            pagination: { page: 1, perPage: 10 },
            sort: { field: "id", order: "ASC" },
        }).then((res) => {
            let improvedCdData = res.data.map((center) => ({
                label: center.id,
                value: center.id,
                name: center.name,
                id: center.id,
            }));
            improvedCdData.push({
                label: "Todos",
                value: "-1",
                name: "",
            });
            this.setState({ cdSelect: improvedCdData, isSelectLoaded: true });
        });
    };

    callApiEndpoint = () => {
        this.props.handleTableHide(3);
        let myFilter = {};
        myFilter.zone = this.state.selectedZone;
        myFilter.include_lead_time = 'true';
        if (this.state.idCd && this.state.idCd !== -1) {
            myFilter.center_distribution = this.state.idCd;
        }
        if (this.state.idStore) {
            myFilter.store = this.state.idStore;
        }
        if (this.state.trailerPlate !== "") {
            myFilter.trailer_plate = this.state.trailerPlate;
        }
        ApiProvider(GET_LIST, this.state.endpoint, {
            pagination: { page: 1, perPage: 10 },
            sort: { field: "id", order: "ASC" },
            filter: myFilter,
        }).then((res) => {
            if (res.data.status === 0) {
                this.props.handleFilter(res.data.data);
                this.props.handleTableHide(0);
            } else {
                this.props.handleTableHide(1);
            }
        });
    };

    handleCD = (value) => {
        value = (value === undefined ? 0 : value);
        let idCd = parseInt(value);
        this.setState({ idCd: idCd});
    };

    handleStore = (event) => {
        let idStore = parseInt(event.target.value);
        this.setState({ idStore: idStore });
    };

    handlePlate = (event) => {
        let trailerPlate = event.target.value
        this.setState({ trailerPlate: trailerPlate });
    };

    componentDidMount() {
        this.callApiEndpoint();
        this.callApiDistribution();
    }

    render() {
        return (
          this.state.isSelectLoaded && (
            <Grid container spacing={8} alignItems="flex-end">
              <Grid item>
                <FormControl>
                  <Label className="myTableCenter myLabelSelect">CD</Label>
                  <Select
                    id="CDSelect"
                    className="select"
                    classNamePrefix="select"
                    defaultValue={{ label: this.state.idCd, value: this.state.idCd }}
                    options={this.state.cdSelect.map((opt) => ({
                      label: opt.label,
                      value: opt.value,
                    }))}
                    onChange={(opt) => this.handleCD(opt.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <Label className="myTableCenter myLabelSelect">Tienda</Label>
                  <input
                    type="number"
                    value={this.state.idStore}
                    onChange={this.handleStore}
                    min="0"
                    id="storeSelect"
                    className="select form-control"
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl>
                  <Label className="myTableCenter myLabelSelect">Patente</Label>
                  <input
                    type="string"
                    className="select form-control"
                    required
                    value={this.state.trailerPlate}
                    onChange={this.handlePlate}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => this.callApiEndpoint()}
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          )
        );
        
    }
}

export default TableFilter;
