import styled from "@emotion/styled";

const EditButton = styled("button")`
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffffff;
  border-radius: 0.15em;
  background-color: #ffdb16;
  padding: 0.2em 1.4em;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

export default EditButton;
