import { Paper } from "@material-ui/core/";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Brush,
  Legend,
  LabelList,
} from "recharts";

import CardTitle from "../../utils/cardTitle/CardTitle";

function Colisions(props) {
  let data = props.data;
  let colisionArray = [];
  let orderedColisionArray = [];
  if (data) {
    let colisionTotal = 0;

    for (let i = 0; i < data.length; i++) {
      colisionTotal += data[i].porcentaje_colision;
      data[i].id = i;
      let temp = data[i].porcentaje_uso * 100;
      data[i].porcentaje_uso = Math.round(temp * 100) / 100;
      if (data[i].porcentaje_colision !== 0) {
        colisionArray.push(data[i].porcentaje_colision);
      }
    }
    if (data.length) {
      let colisionLine = colisionTotal / data.length;
      const average = colisionLine.toString().slice(0, 4);
      for (let value of data) {
        value.shortDate = value.fecha.slice(0, 5);
        value.average = average;
      }
    }
    orderedColisionArray = colisionArray.sort((a, b) => a - b);
  }
  let maxValuesArray = [];
  let minValuesArray = [];
  if (orderedColisionArray) {
    maxValuesArray.push(orderedColisionArray[0]);
    maxValuesArray.push(orderedColisionArray[1]);
    maxValuesArray.push(orderedColisionArray[2]);
    minValuesArray.push(orderedColisionArray[orderedColisionArray.length - 1]);
    minValuesArray.push(orderedColisionArray[orderedColisionArray.length - 2]);
    minValuesArray.push(orderedColisionArray[orderedColisionArray.length - 3]);
    minValuesArray.push(data[data.length - 1].porcentaje_colision);
  }

  const CustomizedLabel = (props) => {
    if (maxValuesArray && maxValuesArray.includes(props.value)) {
      return (
        <g>
          <text
            x={props.x}
            y={props.y + 15}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.value}
          </text>
        </g>
      );
    } else if (minValuesArray && minValuesArray.includes(props.value)) {
      return (
        <g>
          <text
            x={props.x}
            y={props.y - 15}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.value}
          </text>
        </g>
      );
    }
    return null;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={chartStyle.tooltipBox}>
          <p className="">{`${label}`}</p>
          <p className="">{`Visitas: ${payload[0].payload.total_visits}`}</p>
          <p className="">{`Colisiones: ${payload[0].payload.number_collisions}`}</p>
          <p className="">{`Colisión: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Paper
        style={{
          paddingTop: "20px",
          marginTop: "20px",
        }}
      >
        <CardTitle
          main="REGISTRO DE COLISIONES"
          optional={props.zone}
          size="medium"
        />
        <hr />
        <div style={chartStyle.colisionsGraph}>
          <LineChart
            width={1000}
            height={500}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            {" "}
            <XAxis dataKey="shortDate" />
            <YAxis
              ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
              unit="%"
              yAxisId="left"
            />
            <YAxis
              orientation="right"
              yAxisId="right"
              unit="%"
              ticks={[props.data[0].average, 100]}
            />
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              name="Porcentaje de colisión"
              yAxisId="left"
              type="monotone"
              dataKey="porcentaje_colision"
              stroke="#ff2424"
            >
              <LabelList
                dataKey="porcentaje_colision"
                content={<CustomizedLabel />}
              />
            </Line>
            <Line
              name="Porcentaje de uso"
              type="monotone"
              dataKey="porcentaje_uso"
              yAxisId="left"
              stroke="#0072CE"
            />
            <Line
              name="Promedio"
              yAxisId="right"
              type="monotone"
              dataKey="average"
              stroke="#000000"
              dot={false}
              activeDot={false}
              strokeDasharray="5 5"
            />
            <Brush />
          </LineChart>
        </div>
      </Paper>
    </div>
  );
}

const chartStyle = {
  tooltipBox: {
    backgroundColor: "#FFFFFF",
    padding: "10px 5px",
    border: "dashed 2px black",
  },
  fullChartPaperSection: {
    marginTop: 20,
  },
  spreadChartsSection: {
    display: "flex",
    justifyContent: "space-around",
  },
  colisionsGraph: {
    display: "flex",
    justifyContent: "space-around",
  },
  fullChartName: {
    paddingTop: 20,
    marginBottom: 0,
    fontWeight: "bold",
    fontSize: 30,
  },
};

export default Colisions;
