import React from "react";

export default function FulfillmentField({schedule, datetimeArrival, scheduleType}) {
    return (
        <div className="rectangle" style={{
            background: getFulfillmentColor(schedule, datetimeArrival),
            borderRadius: '10px',
            height: '20px',
            width: '20px',
            margin: 'auto'
        }}/>
    );
}

export const getFulfillmentColor = (schedule, datetimeArrival) => {
    if (!datetimeArrival) {
        datetimeArrival = new Date();
    }
    let diff = getMinutesBetweenDates(new Date(schedule), new Date(datetimeArrival));
    if (diff <= 30) {
        return 'lawngreen';
    } else if (diff > 30 && diff <= 60) {
        return 'gold';
    }
    return 'orangered';
    
};

export function getMinutesBetweenDates(startDate, endDate) {
    if (!startDate || ! endDate)
        return 0;

    let diff = endDate.getTime() - startDate.getTime();
    return (diff / 60000);
}
