import React from "react";

import CardTitle from "../utils/cardTitle/CardTitle";

import { CardContent } from "@material-ui/core";

import { useForm } from "react-hook-form";

import FormContainer from "../../reusableStyles/FormContainer.js";

const apiUrl = process.env.REACT_APP_API_URL;

export default function EditCarrier({
  handleSectionToShow,
  sections,
  handleEditCarrier,
  carrier,
}) {
  const TITLE = "Editar transportista";

  const { register, handleSubmit } = useForm({
    defaultValues: {
      rut: carrier.rut,
      name: carrier.name,
      type: carrier.type,
      vehicle: carrier.vehicle,
    },
  });

  const editCarrier = (carrierToEdit) => {
    let tempCarrier = carrierToEdit;
    tempCarrier.id = carrier.id;
    tempCarrier.rut = parseInt(carrierToEdit.rut);

    fetch(`${apiUrl}/carrier/${carrier.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(tempCarrier),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          handleEditCarrier(tempCarrier);
        } else {
          handleSectionToShow(sections[0]);
        }
      });
  };

  const onSubmit = (data) => {
    editCarrier(data);
  };

  return (
    <CardContent>
      <CardTitle main={TITLE} size="medium" />
      <br></br>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Rut</label>
          <input name="rut" type="number" ref={register({ required: true })} />
          <label>Nombre</label>
          <input name="name" type="text" ref={register({ required: true })} />
          <label>Tipo</label>
          <input name="type" type="text" ref={register({ required: true })} />
          <label>Vehículo</label>
          <input
            name="vehicle"
            type="text"
            ref={register({ required: true })}
          />

          <button type="submit" className="submit">
            Editar
          </button>
          <button
            type="button"
            className="goBack"
            onClick={() => handleSectionToShow(sections[0])}
          >
            Volver
          </button>
        </form>
      </FormContainer>
    </CardContent>
  );
}
