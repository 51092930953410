import React from "react";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  CardContent,
  Button
} from "@material-ui/core";

import { hasCorrectRole } from "../utils/roleManager/RoleManager";

function CdTable(props) {
  const cdData = props.cdData;

  if (cdData) {
    return (
      <CardContent>
        <h1 className="myTitle">Centros de distribución</h1>

        <Table className="itemAlign">
          <TableHead>
            <TableRow>
              <TableCell className="myTableBorder">ID</TableCell>
              <TableCell className="myTableBorder">NOMBRE</TableCell>
              <TableCell className="myTableBorderFixplanning">
                DIRECCIÓN
              </TableCell>
              <TableCell className="myTableBorderFixplanning">
                LATITUD
              </TableCell>
              <TableCell className="myTableBorderFixplanning">
                LONGITUD
              </TableCell>
              {hasCorrectRole(["trafico_full"]) ? (
                <TableCell className=""></TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {cdData.map((obj, index) => {
              const { id, name, address, lat, lon } = obj;
              return (
                <TableRow key={id} id={id}>
                  <TableCell className="myTableLeft">{id}</TableCell>
                  <TableCell className="myTableLeft">{name}</TableCell>
                  <TableCell className="myTableLeft">{address}</TableCell>
                  <TableCell className="myTableLeft">{lat}</TableCell>
                  <TableCell className="myTableLeft">{lon}</TableCell>
                  {hasCorrectRole(["trafico_full"]) ? (
                    <TableCell className="">
                      <Button
                        variant="contained"
                        component="span"
                        className="btn btn-info btn-circle3"
                        onClick={()=>props.setDisplayOption(2, obj)}
                      >
                        EDITAR CD
                      </Button>
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    );
  } else {
    return <CardContent></CardContent>;
  }
}

export default CdTable;
