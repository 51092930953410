import React from "react";
import Grid from "@material-ui/core/Grid";

export default function VisitsLegend() {
  return (
    <Grid container item alignItems="center" spacing={8} justify="flex-start" direction="row" style={{fontSize:12}}>
        <Grid item>
          <div
            className="rectangle"
            style={{
              background: "#40CB34",
              borderRadius: "10px",
              height: "13px",
              width: "25px",
              margin: "auto",
            }}
          />
        </Grid>
        <Grid item> PRIMER TOQUE</Grid>
        <Grid item>
          <div
            className="rectangle"
            style={{
              background: "#F0C92D",
              borderRadius: "10px",
              height: "13px",
              width: "25px",
              margin: "auto",
            }}
          />
        </Grid>
        <Grid item> SEGUNDO TOQUE</Grid>
        <Grid item>
          <div
            className="rectangle"
            style={{
              background: "#FA6565",
              borderRadius: "10px",
              height: "13px",
              width: "25px",
              margin: "auto",
            }}
          />
        </Grid>
        <Grid item> TERCERO Y MAS</Grid>
        <Grid item><span role="img" aria-label="Motivo manual">&#128197;</span></Grid>
        <Grid item> AGENDA MANUAL</Grid>
    </Grid>
  );
}
