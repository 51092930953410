import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import DeleteIcon from '@material-ui/icons/Delete';


const buttonStyle = {
  minWidth: 30,
  padding: '0 2px'
};


export default function DeleteDialog(props) {
  const [open, setOpen] = React.useState(false);
  const actionFunction = props.actionFunction;
  const id = props.id;

  const handleClickConfirm = () => {
    actionFunction(id);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button style={buttonStyle} onClick={handleClickOpen} fontSize='small'>
        <DeleteIcon></DeleteIcon>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Confirme eliminación de la salida
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => handleClickConfirm()} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
