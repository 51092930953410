import React from "react";
import { Divider, CardContent, Card, Grid, Button } from "@material-ui/core";

export default function ErrorList(props) {
  const listItems = props.arrayOfErrors.map((msg) => <li>{msg}</li>);

  return (
    <Card>
      <CardContent>
        <br></br>

        <h3>Lista de errores:</h3>
        <br></br>
        <br></br>
        <br></br>
        <ul>{listItems}</ul>
        <Divider />
        <br></br>
        <br></br>
        <br></br>

        <Grid item xs={12} sm={12} className="myTableCenter">
          <Button
            onClick={() => props.handleErrorList(false)}
            variant="contained"
            component="span"
            className="btn btn-info btn-circle3 btn-xl3"
          >
            VOLVER
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}
