import React, { Component } from "react";

import { Divider, CardContent, Card, Grid } from "@material-ui/core";

import { hasCorrectRole } from "../utils/roleManager/RoleManager";

import ButtonContainer from "../../reusableStyles/buttons/ButtonContainer.js";
import AssistanceFilter from "./AssistanceFilter";
import AssistanceFilterTrafico from "./AssistanceFilterTrafico";
import TableDriversAssistance from "./TableDriversAssistance";
import CreateAssistance from "./CreateAssistance";
import Workbook from "react-excel-workbook";
import CardTitle from "../utils/cardTitle/CardTitle";

const apiUrl = process.env.REACT_APP_API_URL;

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Asistencia conductores",
      cd: "",

      assistanceData: [],
      endpointAssistance: "driver-assistance",
      endpointAssistanceFilter: "",

      assistanceCountData: 0,
      endpointAssistanceCount: "driver-assistance-count",
      endpointAssistanceCountFilter: "",

      endpointCarriers: "carrier",
      carriers: [],

      sections: ["TABLE", "CREATE"],
      sectionToShow: "TABLE",
    };    
  }

  handleDelete = (id) => {
    let content = this.state.assistanceData.filter(
      (row) => row.id !== id
    );
    this.setState({
      open: true,
      icon: "success",
      myCounter: "Se ha eliminado conductor.",
      assistanceData: content,
    });
  };

  handleDeleteError = () => {
    this.setState({
      open: true,
      icon: "error",
      myCounter: "No ha sido posible eliminar el conductor.",
    });
  };

  handleAddAssist = () => {
    this.handleSectionToShow(0);
  };

  async callApiCarriers() {
    await fetch(`${apiUrl}/${this.state.endpointCarriers}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({ carriers: res.data });
        }
      });
  }

  async callApiAssistanceCount(cd) {
    await fetch(
      `${apiUrl}/${this.state.endpointAssistanceCount}?center_distribution=${cd}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Authorization: `jwt ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          this.setState({ assistanceCountData: res.data });
        }
      });
  }

  handleSectionToShow = (sectionIndex) => {
    let nextSection = this.state.sections[sectionIndex];

    this.callApiAssistanceCount(this.state.cd);

    this.setState({
      sectionToShow: nextSection,
    });
  };

  handleFilter = (assistanceData) => {
    this.setState({ assistanceData: assistanceData });
  };

  componentDidMount() {
    const CD = localStorage.getItem("cd");
    if (CD) {
      this.setState({
        cd: CD,
      });
    }

    this.callApiCarriers();
    this.callApiAssistanceCount(CD);
  }

  render() {
    const {
      title,
      assistanceData,
      cd,
      endpointAssistance,
      sectionToShow,
      carriers,
      sections,
      assistanceCountData
    } = this.state;

    if (sectionToShow === "TABLE") {
      return (
        <Card>
          <CardContent>
            <CardTitle main={title} />
            <Divider />
            <br></br>
            <CardContent>              
              <ButtonContainer>
                <p>Conductores activos: {assistanceCountData}</p>
                <button onClick={() => this.handleSectionToShow(1)}>
                  {" "}
                  Crear
                  {" "}
                </button>
              </ButtonContainer>                
              <br></br>

              {
                hasCorrectRole([
                  "trafico_full",
                  "trafico",
                  "operacion_2_supervisor",
                  "operacion_2_full",
                  "operacion_1_supervisor",
                  "operacion_1_full",
                  "operacion_trafico_2_supervisor",
                  "operacion_trafico_2_full",
                  "operacion_trafico_1_supervisor",
                  "operacion_trafico_1_full"
                ]) ? (
                  <AssistanceFilterTrafico
                    handleFilter={this.handleFilter}
                    cd={cd}
                    endpoint={endpointAssistance}
                  />
                ) : null 
              }
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end">

                {assistanceData ? (
                  <Workbook
                    filename="AsistenciaConductores.xlsx"
                    element={<button
                        variant="outlined"
                        component="span"
                        className="btn btn-info btn-circle3 btn-xl3"
                      > DESCARGAR
                    </button>}
                  >
                    <Workbook.Sheet data={assistanceData} name="DriversAssistance">
                      <Workbook.Column label="CD" value="id_cd" />
                      <Workbook.Column label="Conductor" value="name" />
                      <Workbook.Column label="Rut Conductor" value="rut" />
                      <Workbook.Column label="Transportista" value="carrier_name" />
                      <Workbook.Column label="Patente chasis" value="plate" />
                      <Workbook.Column label="Estado" value="status" />
                      <Workbook.Column label="Hora de Ingreso" value="registry_date" />
                    </Workbook.Sheet>
                  </Workbook>
                ) : null}
              </Grid>
              <br></br>
              <TableDriversAssistance assistanceData={assistanceData} handleDelete={this.handleDelete} handleDeleteError={this.handleDeleteError} />
              <br></br>
              <br></br>
            </CardContent>
          </CardContent>
        </Card>
      );
    } else if (sectionToShow === "CREATE") {
      return (
        <Card>
          {
            hasCorrectRole([
              "operacion_2_supervisor",
              "operacion_1_supervisor",
              "operacion_trafico_2_supervisor",
              "operacion_trafico_1_supervisor",
              "operacion_2_full",
              "operacion_1_full",
              "operacion_trafico_2_full",
              "operacion_trafico_1_full"
            ]) ? (              
              <CreateAssistance
                carriers={carriers}
                assistanceCount={assistanceCountData}
                handleSectionToShow={this.handleSectionToShow}
                sections={sections}
                handleAddAssist={this.handleAddAssist}
              />
            ) : 
              <h2>Este rol no tiene los permisos para crear asistencia de conductores</h2>
          }
        </Card>
      );
    } else {
      return (null);
    }
  }
}

export default Container;
