import React, { Component } from "react";

import { UPDATE, GET_ONE } from "react-admin";
import { CardContent, Grid, Button } from "@material-ui/core";

import { Label } from "semantic-ui-react";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class StoreEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "TIENDAS",

      endpoint: "shop",

      id: this.props.id,

      id_shops: 0,
      center_distribution: 0,
      car_type: "",
      pallets: 0,
      time_window_ini_1: "",
      time_window_end_1: "",
      time_window_ini_2: "",
      time_window_end_2: "",

      is_StatesLoaded: false,
    };
  }

  handlePallets = (value) => {
    if (value === "") {
      this.setState({ pallets: "" });
    } else {
      let pallets = parseInt(value);
      this.setState({ pallets: pallets });
    }
  };

  handleEdit = () => {
    ApiProvider(UPDATE, this.state.endpoint, {
      id: this.state.id,
      data: {
        name: this.state.name,
        pallets: this.state.pallets,
        car_type: this.state.car_type,
        feet: this.state.feet,
        time_window_ini_1: this.state.time_window_ini_1,
        time_window_end_1: this.state.time_window_end_1,
        time_window_ini_2: this.state.time_window_ini_2,
        time_window_end_2: this.state.time_window_end_2,
      },
    }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`shop,EDIT,${this.state.id}`);
        this.props.handleSnack(true, "success", "Se ha editado tienda.");
        this.props.handleEditHide(false, 0);
      } else {
        this.props.handleSnack(true, "error", "No se ha podido editar tienda.");
      }
    });
  };

  callApi_fillStates = () => {
    ApiProvider(GET_ONE, this.state.endpoint, { id: this.state.id }).then(
      (res) => {
        if (res.data.status === 0) {
          if (res.data.data.pallets === null) {
            this.setState({ pallets: "" });
          } else {
            this.setState({ pallets: res.data.data.pallets });
          }
          this.setState({
            id_shops: res.data.data.id_shops,
            name: res.data.data.name,
            center_distribution: res.data.data.center_distribution,
            zone: res.data.data.zone,
            car_type: res.data.data.car_type,
            feet: res.data.data.feet,
            format: res.data.data.format,
            obs: res.data.data.obs,
            commune: res.data.data.commune,
            region: res.data.data.region,
            time_window_ini_1: res.data.data.time_window_ini_1,
            time_window_end_1: res.data.data.time_window_end_1,
            time_window_ini_2: res.data.data.time_window_ini_2,
            time_window_end_2: res.data.data.time_window_end_2,

            is_StatesLoaded: true,
          });
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No se ha podido cargar tienda."
          );
        }
      }
    );
  };

  componentDidMount() {
    this.callApi_fillStates();
  }

  render() {
    if (this.state.is_StatesLoaded) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CD</Label>
              <Label className="select">{this.state.center_distribution}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIENDA</Label>
              <Label className="select">{this.state.id_shops}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">NOMBRE</Label>
              <Label className="select">{this.state.name}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">ZONA</Label>
              <Label className="select">{this.state.zone}</Label>
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIPO DE AUTO</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ car_type: event.target.value })
                }
                defaultValue={this.state.car_type}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">PALLETS</Label>
              <input
                onChange={(event) => this.handlePallets(event.target.value)}
                defaultValue={this.state.pallets}
                min="0"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">PIES</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ feet: event.target.value })
                }
                defaultValue={this.state.feet}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">HORA INICIO 1</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ time_window_ini_1: event.target.value })
                }
                defaultValue={this.state.time_window_ini_1}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">HORA FINAL 1</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ time_window_end_1: event.target.value })
                }
                defaultValue={this.state.time_window_end_1}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">HORA INICIO 2</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ time_window_ini_2: event.target.value })
                }
                defaultValue={this.state.time_window_ini_2}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">HORA FINAL 2</Label>
              <input
                type="text"
                onChange={(event) =>
                  this.setState({ time_window_end_2: event.target.value })
                }
                defaultValue={this.state.time_window_end_2}
                className="select form-control"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleEdit()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                EDITAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">{this.state.title}</h4>
        </CardContent>
      );
    }
  }
}

export default StoreEdit;
