import React from "react";

import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";

export default function TableTendering(props) {
  const { tenderingData } = props;

  return (
    <Table className="itemAlign smallTable">
      <TableHead>
        <TableRow>
          <TableCell className="myTableBorder">Region</TableCell>
          <TableCell className="myTableBorder">Localidad</TableCell>
          <TableCell className="myTableBorder">Transportista</TableCell>
          <TableCell className="myTableBorder">Participacion%</TableCell>
          <TableCell className="myTableBorder">Plan de Viajes</TableCell>
          <TableCell className="myTableBorder">Total Comuna</TableCell>
          <TableCell className="myTableBorder">Control%</TableCell>
          <TableCell className="myTableBorder">Control</TableCell>
          <TableCell className="myTableBorder">Cumplimiento</TableCell>
          <TableCell className="myTableBorder">Desviacion%</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tenderingData.map((obj, index) => {
          const { 
            region,
            commune,
            carrier,
            currentPercent,
            currentDispatches,
            totalCommuneDispatches,
            expectedPercent,
            expectedDispatches,
            currentCompliance,
            deviation 
          } = obj;

          return (
            <TableRow key={index}>
              <TableCell className="myTableLeft">{region}</TableCell>
              <TableCell className="myTableLeft">{commune}</TableCell>
              <TableCell className="myTableLeft">{carrier}</TableCell>
              <TableCell className="myTableLeft">{expectedPercent}%</TableCell>
              <TableCell className="myTableLeft">{expectedDispatches}</TableCell>
              <TableCell className="myTableLeft">{totalCommuneDispatches}</TableCell>
              <TableCell className="myTableLeft">{currentPercent}%</TableCell>
              <TableCell className="myTableLeft">{currentDispatches}</TableCell>
              <TableCell className="myTableLeft">{currentCompliance}</TableCell>
              <TableCell className="myTableLeft">{deviation}%</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

