import dayjs from "dayjs";
import { formatIntegerMinDigits } from "../number/NumberUtils";

function applyTimeZoneOffset(originalDateTime, timezoneOffset) {
    let result = new Date(originalDateTime.getTime());
    result.setMinutes(result.getMinutes() + timezoneOffset);
    return result;
}

function getDaysRange(startDay, endDay){
    let range = []
    for (let currentDate = startDay; currentDate<=endDay; currentDate = currentDate.add(1, 'day') ) {
        range.push(currentDate)
    }
    return range
}

function  extractDateAndTime(originalDateTime, date_format='YYYY-MM-DD') {
    let date = dayjs(originalDateTime).format(date_format);
    let time = dayjs(originalDateTime).format('HH:mm');
    return {date, time}
}

function resolveDate(date){
    if(date){
        date = date.replace(/-/g, '/')
        return extractDateAndTime(
            new Date(date), 'DD/MM/YYYY'
        ).date
    }
    return null
}

// Transforms an amount of minutes to an hours string.
// E.g. formatMinutesAsHoursString(75) === "01:15"
function formatMinutesAsHoursString(minutes) {
    if(!minutes) { // Handles null, 0, or undefined
        return '00:00';
    }

    return [
        formatIntegerMinDigits(Math.floor(minutes / 60), 2),
        formatIntegerMinDigits(minutes % 60, 2)]
        .join(":");
}

export {applyTimeZoneOffset, extractDateAndTime, formatMinutesAsHoursString, resolveDate, getDaysRange}
