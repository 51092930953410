import React from "react";
import { DELETE } from "react-admin";
import ApiProvider from "../../provider/apiProvider";
import Dialog from "./Dialog";
import { CardContent, Grid } from "@material-ui/core";
import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import { hasCorrectRole } from "../utils/roleManager/RoleManager";

import { logUserActivity } from "../utils/logger/Logger";

import Workbook from "react-excel-workbook";

const getStyle = (param) => {
  return {
    color: param === "En proceso" ? "crimson" : "black",
  };
};

const getStyleTable = (param) => {
  return {
    backgroundColor: param === "En proceso" ? "lightgreen" : "",
  };
};

function ResultsRmZone0Table(props) {
  const deleteDeparture = (id) => {
    ApiProvider(DELETE, "departures", { id: id }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`departures,DELETE,${id}`);
        props.handleDelete(id);
      } else {
        props.handleDeleteError();
      }
    });
  };

  return (
    <CardContent>
      <Grid container justify="flex-end">
        <Workbook
          filename="RESULTADOS_RM_ZONA_0.xlsx"
          element={
            <Button
              variant="contained"
              component="span"
              className="btn btn-info btn-circle2 btn-xl2"
            >
              DESCARGAR
            </Button>
          }
        >
          <Workbook.Sheet data={props.tableContent} name="RESULTADOS">
            <Workbook.Column label="PATENTE" value="trailer_plate" />
            <Workbook.Column label="CD" value="center_distribution" />
            <Workbook.Column label="HORA SALIDA MODELO" value="time_to_exit" />
            <Workbook.Column
              label="HORA INICIO GESTION"
              value="time_arrangment"
            />
            <Workbook.Column label="HORA SALIDA GLS" value="time_exit" />
            <Workbook.Column label="RUTA" value="route" />
            <Workbook.Column label="MOTIVO" value="motive" />
            <Workbook.Column label="USUARIO" value="username" />
          </Workbook.Sheet>
        </Workbook>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="myTableBorderLeft myTableBorder">
              &nbsp;&nbsp;&nbsp;&nbsp;CORRIDA
            </TableCell>
            <TableCell className="myTableBorder">PATENTE</TableCell>
            <TableCell className="myTableBorder">CD</TableCell>
            <TableCell className="myTableBorder">HORA SALIDA MODELO</TableCell>
            <TableCell className="myTableBorder">HORA INICIO GESTION</TableCell>
            <TableCell className="myTableBorder">HORA SALIDA GLS</TableCell>
            <TableCell className="myTableBorder">TIENDA(S)</TableCell>
            <TableCell className="myTableBorder">CONDUCTOR</TableCell>
            <TableCell className="myTableBorder">TRANSPORTISTA</TableCell>
            <TableCell className="myTableBorder">MOTIVO</TableCell>
            <TableCell className="myTableBorder">USUARIO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableContent.map((obj, index) => {
            const {
              id,
              id_optimization,
              trailer_plate,
              center_distribution,
              time_to_exit,
              route,
              username,
              time_arrangment,
              motive,
              driver_name,
              carrier_name,
            } = obj;
            var zero = "0"
            if (obj.time_exit === "") {
              obj.time_exit = "En proceso";
            }
            else if (obj.time_exit.search(":") === 1) {
              obj.time_exit = zero.concat(obj.time_exit)
            }
            if (obj.time_to_exit.search(":") === 1) {
              obj.time_to_exit = zero.concat(obj.time_to_exit)
            }
            if (obj.time_arrangment.search(":") === 1) {
              obj.time_arrangment = zero.concat(obj.time_arrangment)
            }

            return (
              <TableRow key={id} style={getStyleTable(obj.time_exit)}>
                <TableCell className="myTableBorderLeft myTableLeft">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{id_optimization}
                </TableCell>
                <TableCell className="myTableLeft">{trailer_plate}</TableCell>
                <TableCell className="myTableLeft">{center_distribution}</TableCell>
                <TableCell className="myTableLeft"
                  style={getStyle(obj.time_to_exit)}
                >
                  {obj.time_to_exit}
                </TableCell>
                <TableCell className="myTableLeft"
                  style={getStyle(obj.time_arrangment)}
                >
                  {obj.time_arrangment}
                </TableCell>
                <TableCell className="myTableLeft"
                  style={getStyle(obj.time_exit)}
                >
                  {obj.time_exit}
                </TableCell>
                <TableCell className="myTableLeft">{route}</TableCell>
                <TableCell className="myTableLeft">{driver_name}</TableCell>
                <TableCell className="myTableLeft">{carrier_name}</TableCell>
                <TableCell className="myTableLeft">{motive}</TableCell>
                <TableCell className="myTableLeft">{username}</TableCell>
                <TableCell className="deleteDepartures myTableBorderLessAdditional">
                  {!hasCorrectRole(["trafico", "trafico_full"]) &&
                  motive === "Fuera" ? null : (
                    <Dialog id={id} function={deleteDeparture} />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </CardContent>
  );
}

export default ResultsRmZone0Table;
