import React from "react";
import Workbook from "react-excel-workbook";
import { Button } from "@material-ui/core";

const CarrierWorkbook = ({ tableContent }) => {
  const replaceNullValue = (value) => value || "0";

  return (
    <Workbook
      filename="SaturaciónTransportista.xlsx"
      element={
        <Button variant="outlined" component="span" color="secondary">
          DESCARGAR
        </Button>
      }
    >
      <Workbook.Sheet data={tableContent} name="BanderasBlancas">
        <Workbook.Column label="Nombre Transportista" value="name" />
        <Workbook.Column
          label="Off Time"
          value={(row) => `${replaceNullValue(row.off_time)}`}
        />
        <Workbook.Column
          label="Retrasados"
          value={(row) => `${replaceNullValue(row.delayed)}`}
        />
        <Workbook.Column
          label="On time"
          value={(row) => `${replaceNullValue(row.on_time)}`}
        />
        <Workbook.Column
          label="Adelantos"
          value={(row) => `${replaceNullValue(row.advanced)}`}
        />
      </Workbook.Sheet>
    </Workbook>
  );
};

export default CarrierWorkbook;
