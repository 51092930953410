import React, { Component } from "react";

import { Title, CREATE } from "react-admin";
import { Divider, CardContent, Card, Grid, Button } from "@material-ui/core";

import Table from "./FixplanningTable";
import Form from "./FixplanningForm";
import Edit from "./FixplanningEdit";

import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";
import ApiProvider from "../../provider/apiProvider";

import Filter from "../utils/filter/TableFilter";
import Snack from "../utils/snackbar/SnackBar";
import Dialog from "../utils/dialog/Dialog";

import CardTitle from "../utils/cardTitle/CardTitle";

import { ExcelRenderer } from "react-excel-renderer";

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission_Gestion: ["trafico", "trafico_full"],
      permission_Visibility: [
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_2_supervisor",
        "operacion_2_full",
      ],

      tittle: "FIXPLANNING",
      create: "FIXPLANNING",

      endpoint: "fixed-planning",

      tableContent: [],
      status: 3,

      open_dialog: false,
      confirm: false,
      message_dialog: "",
      message_deleteAll: "¿Esta seguro de eliminar TODOS los FIXPLANNING?",

      delete_id: 0,

      zoneFilter: "",

      open: false,
      message: "",
      icon: "success",

      id_toEdit: 0,

      is_FormCalled: false,
      is_EditCalled: false,
    };
  }

  handleFilter = (tableContent) => {
    this.setState({ tableContent: tableContent, status: 0 });
  };

  handleEditHide = (is_EditCalled, id) => {
    if (id === 0) {
      this.setState({ is_EditCalled: is_EditCalled });
    } else {
      this.setState({ is_EditCalled: is_EditCalled, id_toEdit: id });
    }
  };

  handleZoneFilter = (zoneName) => {
    this.setState({
      zoneFilter: zoneName,
    });
  };

  handle_dialog = (id, message_dialog) => {
    this.setState({
      delete_id: id,
      open_dialog: true,
      message_dialog: message_dialog,
    });
  };

  handleTableHide = (status) => {
    this.setState({ status: status });
  };

  handleSnack = (open, icon, message) => {
    this.setState({ open: open, icon: icon, message: message });
  };

  handleFormHide = (is_FormCalled) => {
    this.setState({ is_FormCalled: is_FormCalled });
  };

  handleUpload = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        try {
          let rows = resp.rows;
          let checkHead = ["Local", "Lunes", "Martes", "Miercoles", "Jueves"];
          if (
            resp.rows[0][0].trim() === checkHead[0] &&
            resp.rows[0][1].trim() === checkHead[1] &&
            resp.rows[0][2].trim() === checkHead[2] &&
            resp.rows[0][3].trim() === checkHead[3] &&
            resp.rows[0][4].trim() === checkHead[4]
          ) {
            rows.shift();
            let array = [];
            for (var key in rows) {
              if (resp.rows[key].length === 0) {
                break;
              }
              let myJson = {};
              myJson["center_distribution"] = resp.rows[key][10];
              myJson["store"] = resp.rows[key][0];
              myJson["ambient_desc"] = resp.rows[key][9];
              myJson["monday"] = resp.rows[key][1] ? resp.rows[key][1] : 0;
              myJson["tuesday"] = resp.rows[key][2] ? resp.rows[key][2] : 0;
              myJson["wednesday"] = resp.rows[key][3] ? resp.rows[key][3] : 0;
              myJson["thursday"] = resp.rows[key][4] ? resp.rows[key][4] : 0;
              myJson["friday"] = resp.rows[key][5] ? resp.rows[key][5] : 0;
              myJson["saturday"] = resp.rows[key][6] ? resp.rows[key][6] : 0;
              myJson["sunday"] = resp.rows[key][7] ? resp.rows[key][7] : 0;

              array.push(myJson);
            }
            ApiProvider(CREATE, this.state.endpoint, {
              data: { array },
            }).then((res) => {
              if (!res.data.status) {
                this.setState({
                  is_FormCalled: true,
                  open: true,
                  icon: "success",
                  message:
                    String(rows.length) + " filas han sido cargadas con exito.",
                });
                this.handleFormHide(false);
              } else {
                let errorMessage = "Error en linea ";
                for (var linea in res.data.data) {
                  let campo = res.data.data[linea];
                  let stringCampo = JSON.stringify(campo).substr(2);
                  stringCampo = stringCampo
                    .substr(0, stringCampo.length - 3)
                    .split('":["');
                  errorMessage +=
                    String(linea) +
                    " en " +
                    stringCampo[0] +
                    ": " +
                    stringCampo[1];
                  this.setState({
                    open: true,
                    icon: "error",
                    message: errorMessage,
                  });
                  break;
                }
              }
            });
          } else {
            this.setState({
              open: true,
              icon: "error",
              message: "Error en la estructura del archivo.",
            });
          }
        } catch (error) {
          this.setState({
            open: true,
            icon: "error",
            message: "Archivo no soportado.",
          });
        }
      }
    });
  };

  getRole = () => localStorage.getItem("role");

  componentDidMount() {
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (
      this.state.permission_Gestion.includes(this.state.permission) ||
      this.state.permission_Visibility.includes(this.state.permission)
    ) {
      if (this.state.is_FormCalled) {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">CREAR {this.state.create}</h1>
              <Divider />
              <br></br>

              <Form
                handleSnack={this.handleSnack}
                handleFormHide={this.handleFormHide}
              />

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      } else if (this.state.is_EditCalled) {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <h1 className="myTitle">EDITAR {this.state.create}</h1>
              <Divider />
              <br></br>

              <Edit
                handleSnack={this.handleSnack}
                handleEditHide={this.handleEditHide}
                id={this.state.id_toEdit}
              />

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      } else {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.tittle} />
              <CardTitle
                optional={this.state.zoneFilter}
                main={this.state.tittle}
              />
              <Divider />
              <br></br>

              <Filter
                name={this.state.tittle}
                endpoint={this.state.endpoint}
                handleFilter={this.handleFilter}
                handleTableHide={this.handleTableHide}
                handleZoneFilter={this.handleZoneFilter}
              />
              <br></br>
              <br></br>

              {this.state.status === 0 ? (
                <Table
                  tableContent={this.state.tableContent}
                  handleSnack={this.handleSnack}
                  handleFormHide={this.handleFormHide}
                  handleEditHide={this.handleEditHide}
                />
              ) : null}
              {this.state.status === 1 ? (
                <center>
                  <h1>No hay resultados disponibles.</h1>
                </center>
              ) : null}
              {this.state.status === 3 ? (
                <center>
                  <MyLoading /> <h4 className="msg">{this.state.tittle}</h4>
                </center>
              ) : null}

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
              <Dialog
                open_dialog={this.state.open_dialog}
                message_dialog={this.state.message_dialog}
                handleDelete={this.handleDelete}
              />
              {this.state.permission_Gestion.includes(this.state.permission) ? (
                <Grid className="myTableCenter">
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    id="contained-button-file"
                    className="inputButton"
                    multiple
                    type="file"
                    onChange={this.handleUpload}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      component="span"
                      className="btn btn-info btn-circle3 btn-xl3"
                    >
                      SUBIR
                    </Button>
                  </label>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      this.setState({ is_FormCalled: true });
                    }}
                    variant="contained"
                    component="span"
                    className="btn btn-info btn-circle3 btn-xl3"
                  >
                    AGREGAR
                  </Button>
                </Grid>
              ) : null}
            </CardContent>
          </Card>
        );
      }
    } else {
      return <NotFound />;
    }
  }
}

export default Container;
