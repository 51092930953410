import React from "react";
import { AppBar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  appStyle: {
    background: "white",
    color: "grey",
    position: "fixed",
    top: 64,
    "& svg": { fontSize: "30px" }
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "black"
  }
});

const CustomAppBar = ({ classes, ...props }) => {
  return (
    <AppBar {...props} className={classes.appStyle}>
      <Typography
        variant="title"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  );
};

export default withStyles(styles)(CustomAppBar);
