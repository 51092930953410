import React, { useState } from "react";

const useSort = (initialValue = "") => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(initialValue);

  const check_order_and_order_by = (key) => {
    const setOrderAndSetOrderBy = (key) => {
      setOrderBy(key);
      setOrder("asc");
    };
    orderBy === key
      ? setOrder(order === "asc" ? "desc" : "asc")
      : setOrderAndSetOrderBy(key);
  };

  const sortArray = (optional_value = null) => {
    return (element_a, element_b) => {
      if (order === "asc") {
        return (
          (element_a[orderBy] || optional_value) -
          (element_b[orderBy] || optional_value)
        );
      } else if (order === "desc") {
        return (
          (element_b[orderBy] || optional_value) -
          (element_a[orderBy] || optional_value)
        );
      }
    };
  };

  return {
    order,
    orderBy,
    sortArray,
    check_order_and_order_by,
  };
};

export default useSort;
