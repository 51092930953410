import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

import DeleteButton from "../../../reusableStyles/buttons/DeleteButton";
import BlueButton from "../../../reusableStyles/buttons/BlueButton";

/**
 * This Reusable Dialog promps for confirmation
 * Must be passed a function, an item and a message
 *
 * Upon acepting, then function will be executed with the item as parameter
 * If there is no item passed, then the function will be called on its own
 */
export default function AlertDialog({ passedFunction, item, msg }) {
  const [open, setOpen] = React.useState(false);

  const handleClickConfirm = () => {
    if (item) {
      passedFunction(item);
    } else {
      passedFunction();
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DeleteButton onClick={handleClickOpen}>Eliminar</DeleteButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
        <DialogActions>
          <BlueButton onClick={handleClose}>Cancelar</BlueButton>
          <DeleteButton onClick={() => handleClickConfirm()} autoFocus>
            Aceptar
          </DeleteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
