import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";
import { GET_LIST, CREATE } from "react-admin";

import { Label } from "semantic-ui-react";
import Select from "react-select";
import DatePicker from "react-datepicker";

import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import "react-datepicker/dist/react-datepicker.css";

import { logUserActivity } from "../utils/logger/Logger";

class FlagForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "BLOQUEO TIENDAS",

      endpoint: "lock-store",

      center_distribution: 0,
      store: 0,
      ambient_desc: "",
      date_ini: new Date(),
      date_end: new Date(),

      cdSelect: [],
      ambientSelect: [],
      is_SelectCDLoaded: false,
      is_SelectAmbientLoaded: false,
    };
  }

  callApi_Distribution() {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      this.setState({ cdSelect: res.data, is_SelectCDLoaded: true });
    });
  }

  callApi_Ambient() {
    ApiProvider(GET_LIST, "ambient-desc", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      if (res.data.status === 0) {
        this.setState({
          ambientSelect: res.data.data,
          is_SelectAmbientLoaded: true,
        });
      }
    });
  }

  handle_CD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    this.setState({ center_distribution: id_cd });
  };

  handle_Store = (value) => {
    if (value === "") {
      value = 0;
    }
    let id_store = parseInt(value);
    this.setState({ store: id_store });
  };

  handle_Ambient = (value) => {
    if (value === undefined) {
      value = "";
    }
    return value;
  };

  handle_StartDate = (date) => {
    this.setState({ date_ini: date });
  };

  handle_EndDate = (date) => {
    this.setState({ date_end: date });
  };

  handleCreate = () => {
    let date_ini = this.state.date_ini;
    let date_end = this.state.date_end;
    if (
      this.state.center_distribution === 0 ||
      this.state.store === 0 ||
      this.state.ambient_desc === "" ||
      date_ini === null ||
      date_end === null
    ) {
      this.props.handleSnack(
        true,
        "warning",
        "Debe completar los datos faltantes."
      );
    } else {
      let timeStart =
        String(date_ini.getFullYear()) +
        "-" +
        String(date_ini.getMonth() + 1) +
        "-" +
        String(date_ini.getDate()) +
        " " +
        date_ini.toLocaleTimeString("en-US", {
          hour12: false,
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });
      let timeEnd =
        String(date_end.getFullYear()) +
        "-" +
        String(date_end.getMonth() + 1) +
        "-" +
        String(date_end.getDate()) +
        " " +
        date_end.toLocaleTimeString("en-US", {
          hour12: false,
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        });

      ApiProvider(CREATE, this.state.endpoint, {
        data: {
          center_distribution: this.state.center_distribution,
          store: this.state.store,
          ambient_desc: this.state.ambient_desc,
          date_ini: timeStart,
          date_end: timeEnd,
        },
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(`lock-store,CREATE,${this.state.store}`);
          this.props.handleSnack(true, "success", "Se ha agregado bloqueo.");
          this.props.handleFormHide(false);
        } else {
          this.props.handleSnack(
            true,
            "error",
            "No se ha podido agregar bloque."
          );
        }
      });
    }
  };

  componentDidMount() {
    this.callApi_Distribution();
    this.callApi_Ambient();
  }

  render() {
    if (this.state.is_SelectCDLoaded && this.state.is_SelectAmbientLoaded) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CD</Label>
              <Select
                className="select"
                classNamePrefix="select"
                options={this.state.cdSelect.map((opt) => ({
                  label: opt.id,
                  value: opt.id,
                }))}
                onChange={(opt) => this.handle_CD(opt.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIENDA</Label>
              <input
                onChange={(event) => this.handle_Store(event.target.value)}
                defaultValue="0"
                min="0"
                id="store_create"
                className="select form-control"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIPO MERCADERIA</Label>
              <Select
                className="select"
                classNamePrefix="select"
                options={this.state.ambientSelect.map((opt) => ({
                  label: opt.ambient_desc,
                  value: opt.ambient_desc,
                }))}
                onChange={(opt) =>
                  this.setState({
                    ambient_desc: this.handle_Ambient(opt.value),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">FECHA INICIO</Label>
              <DatePicker
                selected={this.state.date_ini}
                className="select form-control"
                onChange={this.handle_StartDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="dd-MM-yyyy HH:mm:ss"
                timeCaption="Time"
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">FECHA FIN</Label>
              <DatePicker
                selected={this.state.date_end}
                className="select form-control"
                onChange={this.handle_EndDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                dateFormat="dd-MM-yyyy HH:mm:ss"
                timeCaption="Time"
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleCreate()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                CREAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">{this.state.title}</h4>
        </CardContent>
      );
    }
  }
}

export default FlagForm;
