import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import minMax from "dayjs/plugin/minMax";

import { extractDateAndTime, getDaysRange } from "../utils/date/DateUtils";

dayjs.extend(isBetween);
dayjs.extend(minMax);

export const getDailyWhiteFlags = (weekStartDay, weekEndDay, whiteFlags) => {
  const whiteFlagsObj = getDaysRange(weekStartDay, weekEndDay)
    .map((element) => extractDateAndTime(element).date)
    .reduce((acc, dateAsKey) => ({ ...acc, [dateAsKey]: [] }), {});

  whiteFlags.forEach((el) => {
    const elStartDate = el.start_date ? dayjs(el.start_date) : weekStartDay;
    const elEndDate = el.end_date ? dayjs(el.end_date) : weekEndDay;

    if (elStartDate <= weekEndDay) {
      const startDate = dayjs.max(dayjs(elStartDate), weekStartDay);
      const endDate = dayjs.min(dayjs(elEndDate), weekEndDay);

      Object.keys(whiteFlagsObj).forEach((currentDate) => {
        if (dayjs(currentDate).isBetween(startDate, endDate, "[]", "day")) {
          whiteFlagsObj[currentDate].push(el);
        }
      });
    }
  });

  return whiteFlagsObj;
};
