import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Paper, Grid } from "@material-ui/core/";

function advancesUser({ data }) {
  const buildAdvancesChart = (data) => (
    <div>
      <BarChart
        width={600}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="+1" fill="rgb(230, 191, 0)" />
        <Bar dataKey="+2" fill="rgb(201, 16, 41)" />
        <Bar dataKey="OK" fill="rgb(67, 163, 7)" />
      </BarChart>
    </div>
  );

  return (
    <div>
      <Paper style={chartStyle.halfChartPaperSection}>
        <h3 style={{ paddingTop: "20px", marginTop: "20px" }}>
          ADELANTO POR USUARIO
        </h3>
        <hr />
        <Grid container>
          {data.map((element) => (
            <Grid item xs={6} key={element.distributionCenter}>
              <p style={chartStyle.fullChartName}>
                {element.distributionCenter}
              </p>
              {element.status === 0
                ? buildAdvancesChart(element.data)
                : "No disponible"}
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
}

const chartStyle = {
  fullChartPaperSection: {
    marginTop: 20,
  },
  fullChartName: {
    paddingTop: 20,
    marginBottom: 0,
    fontWeight: "bold",
    fontSize: 30,
  },
};

export default advancesUser;
