import React from "react";

import {
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Dialog from "./Dialog";
import ApiProvider from "../../provider/apiProvider";
import { DELETE } from "react-admin";
import { logUserActivity } from "../utils/logger/Logger";

export default function TableDriversAssistance(props) {
  const { assistanceData } = props;
  function deleteDriver(id) {
    ApiProvider(DELETE, "driver-assistance", { id: id }).then((res) => {
      if (res.data.status === 0) {
        logUserActivity(`driver-assistance,DELETE,${id}`);
        props.handleDelete(id);
      } else {
        props.handleDeleteError();
      }
    });
  }

  return (
    <Table className="itemAlign">
      <TableHead>
        <TableRow>
          <TableCell className="myTableBorder">CD</TableCell>
          <TableCell className="myTableBorder">Conductor</TableCell>
          <TableCell className="myTableBorder">Rut Conductor</TableCell>
          <TableCell className="myTableBorderFixplanning">Transportista</TableCell>
          <TableCell className="myTableBorderFixplanning">Patente chasis</TableCell>
          <TableCell className="myTableBorderFixplanning">Estado</TableCell>
          <TableCell className="myTableBorderFixplanning">Hora de ingreso</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {assistanceData.map((obj, index) => {
          const {
            id,
            id_cd,
            name,
            rut,
            carrier_name,
            is_chasis,
            plate,
            status,
            registry_date,
          } = obj;
          return (
            <TableRow key={id} id={id}>
              <TableCell className="myTableLeft">{id_cd}</TableCell>
              <TableCell className="myTableLeft">{name}</TableCell>
              <TableCell className="myTableLeft">{rut}</TableCell>
              <TableCell className="myTableLeft">{carrier_name}</TableCell>
              <TableCell className="myTableLeft">{plate}</TableCell>
              <TableCell className="myTableLeft">{status}</TableCell>
              <TableCell className="myTableLeft">{registry_date}</TableCell>
              <TableCell className="mydeleteDrivers myTableBorderLessAdditional">
                <Dialog id={id} status={status} function={deleteDriver} disabled="true" />
              </TableCell>
            </TableRow>
          ); 
      })}
      </TableBody>
    </Table>
  );
}