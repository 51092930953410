import React, { Component } from "react";
import { CardContent, Grid, Button } from "@material-ui/core";
import { GET_LIST, CREATE } from "react-admin";

import { Label } from "semantic-ui-react";
import Select from "react-select";
import MyLoading from "../utils/loading/Loading";
import ApiProvider from "../../provider/apiProvider";

import { logUserActivity } from "../utils/logger/Logger";

import "react-datepicker/dist/react-datepicker.css";

class ReturnForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "DEVOLUCION",

      endpoint: "return",

      center_distribution: 0,
      store: 0,
      ambient_desc: "",
      route: "",
      trailer_plate: "",

      cdSelect: [],
      ambientSelect: [],
      trailerSelect: [],
      is_SelectLoaded: false,
      is_SelectAmbientLoaded: false,
      is_SelectTrailerLoaded: false,
      trailerPlateShow: "",
    };
  }

  callApi_Distribution() {
    ApiProvider(GET_LIST, "distribution-center", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      this.setState({ cdSelect: res.data, is_SelectLoaded: true });
    });
  }

  callApi_Ambient() {
    ApiProvider(GET_LIST, "ambient-desc", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
    }).then((res) => {
      if (res.data.status === 0) {
        this.setState({
          ambientSelect: res.data.data,
          is_SelectAmbientLoaded: true,
        });
      } else {
        console.log("No se puede cargar Tipos de mercaderia.");
      }
    });
  }

  callApi_Trailer = (id_cd, id_store) => {
    let myFilter = {};
    if (id_cd !== 0) {
      myFilter.center_distribution = id_cd;
    }
    if (id_store !== 0) {
      myFilter.store = id_store;
    }

    ApiProvider(GET_LIST, "trailer", {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "id", order: "ASC" },
      filter: myFilter,
    }).then((res) => {
      if (res.data.status === 0) {
        let myArray = [];
        for (var i in res.data.data[0]) {
          myArray.push(i);
        }
        this.setState({ trailerSelect: myArray, is_SelectTrailerLoaded: true });
      } else {
        this.props.handleSnack(
          true,
          "error",
          "No ha sido posible cargar datos del trailer."
        );
        this.setState({ trailerSelect: [] });
      }
    });
  };

  verifyRoute = (data) => {
    const pattern = /^\d+(,\d+)*$/;
    const result = pattern.test(data);
    return result;
  };

  handle_EndDate = (value) => {
    if (value === undefined) {
      value = "";
    }
    return value;
  };

  handle_CD = (value) => {
    if (value === undefined) {
      value = 0;
    }
    let id_cd = parseInt(value);
    this.setState({ center_distribution: id_cd });
    this.callApi_Trailer(id_cd, this.state.store);
  };

  handleCreate = () => {
    if (
      this.state.center_distribution === 0 ||
      this.state.ambient_desc === "" ||
      this.state.route === "" ||
      this.state.trailer_plate === ""
    ) {
      this.props.handleSnack(
        true,
        "warning",
        "Debe completar los datos faltantes."
      );
    } else if (!this.verifyRoute(this.state.route)) {
      this.props.handleSnack(
        true,
        "warning",
        "Tiendas de ruta mal ingresadas."
      );
    } else {
      ApiProvider(CREATE, this.state.endpoint, {
        data: {
          center_distribution: this.state.center_distribution,
          ambient_desc: this.state.ambient_desc,
          route: "[" + this.state.route + "]",
          trailer_plate: this.state.trailer_plate,
        },
      }).then((res) => {
        if (res.data.status === 0) {
          logUserActivity(
            `return,CREATE,${this.state.center_distribution}/${this.state.ambient_desc}/${this.state.trailer_plate}`
          );
          this.props.handleSnack(true, "success", "Se ha agregado devolución.");
        } else {
          this.props.handleSnack(true, "error", `${res.data.data[0].message}`);
        }
      });
    }
  };

  componentDidMount() {
    this.callApi_Distribution();
    this.callApi_Ambient();
  }

  render() {
    if (this.state.is_SelectLoaded && this.state.is_SelectAmbientLoaded) {
      return (
        <CardContent>
          <Grid container spacing={32}>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">CD</Label>
              <Select
                id="cdSelect"
                className="select"
                classNamePrefix="select"
                options={this.state.cdSelect.map((opt) => ({
                  label: opt.id,
                  value: opt.id,
                }))}
                onChange={(opt) => this.handle_CD(opt.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TRAILER</Label>
              <input
                onBlur={(event) =>
                  this.setState({ trailer_plate: event.target.value.trim() })
                }
                defaultValue=""
                className="select form-control"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIENDA(S)</Label>
              <input
                onBlur={(event) => this.setState({ route: event.target.value })}
                defaultValue=""
                className="select form-control"
                type="text"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className="inline">
              <Label className="myLabel">TIPO MERCADERIA</Label>
              <Select
                className="select"
                classNamePrefix="select"
                options={this.state.ambientSelect.map((opt) => ({
                  label: opt.ambient_desc,
                  value: opt.ambient_desc,
                }))}
                onChange={(opt) =>
                  this.setState({
                    ambient_desc: this.handle_EndDate(opt.value),
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} className="myTableCenter">
              <Button
                onClick={() => this.handleCreate()}
                variant="contained"
                component="span"
                className="btn btn-info btn-circle3 btn-xl3"
              >
                CREAR
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      );
    } else {
      return (
        <CardContent>
          <MyLoading />
          <h4 className="msg">{this.state.title}</h4>
        </CardContent>
      );
    }
  }
}

export default ReturnForm;
