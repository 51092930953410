import React from "react";
import Grid from "@material-ui/core/Grid";

export default function ResultsRegionsLegend() {
  return (
    <Grid
      container
      item
      alignItems="center"
      spacing={8}
      justify="flex-start"
      direction="row"
      style={{ fontSize: 14, paddingTop: 15 }}
    >

      <Grid item style={{padding: 2}}>
        <span role="img" aria-label="Motivo manual">
          &#9200;
        </span>
      </Grid>
      <Grid item>
        <div
          className="rectangle"
          style={{
            color: "#000000",
            background: "#EAF0FE",
            borderRadius: "10px",
            margin: "auto",
            padding: "2px 5px"
          }}
        >MADRUGADA</div>
        </Grid>
        <Grid item style={{marginRight: 10}}>00:00 - 07:00</Grid>
      <Grid item>
        <div
          className="rectangle"
          style={{
            color: "#000000",
            background: "#EAF0FE",
            borderRadius: "10px",
            margin: "auto",
            padding: "2px 5px"
          }}
        >AM</div>
      </Grid>
      <Grid item style={{marginRight: 10}}>07:00 - 12:00</Grid>
      <Grid item>
        <div
          className="rectangle"
          style={{
            color: "#000000",
            background: "#EAF0FE",
            borderRadius: "10px",
            margin: "auto",
            padding: "2px 5px"
          }}
        >PM</div>
      </Grid>
      <Grid item style={{marginRight: 10}}>12:00 - 18:00</Grid>
      <Grid item>
        <div
          className="rectangle"
          style={{
            color: "#000000",
            background: "#EAF0FE",
            borderRadius: "10px",
            margin: "auto",
            padding: "2px 5px"
          }}
        >NOCHE</div>
      </Grid>
      <Grid item style={{marginRight: 10}}>18:00 - 00:00</Grid>
      
    </Grid>
  );
}
