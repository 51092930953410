import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  TableBody,
  Table,
  TableCell,
  TableRow,
} from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';

import { applyTimeZoneOffset } from "../utils/date/DateUtils";

const tableStyles = {
  cell: {
    borderBottom: 'none'
  }
};
const dialogStyles = {
  minHeight: 300
};
const buttonStyle = {
  minWidth: 30,
  padding: '0 2px'
};


export default function DeleteDialog(props) {
  const { editVisitsAndDeparture , visits} = props;
  let timezoneOffset = - (new Date().getTimezoneOffset());
  const initialVisitDate = visits.reduce(
    (o, visit) => ({...o, [visit.id]: visit.schedule_start? applyTimeZoneOffset(new Date(visit.schedule_start), timezoneOffset): null }),
    {}
  );
  const [open, setOpen] = useState(false);
  const [visitsDate, setVisitsDate] = useState(initialVisitDate);

  
  const handleClickConfirm = () => {
    editVisitsAndDeparture(visitsObjectToArray());
    updateVisits();
    setOpen(false);
  };

  const visitsObjectToArray = () => {
    return Object.keys(visitsDate).map(visit => {
      let schedule_end = new Date(visitsDate[visit]);
      schedule_end.setHours(visitsDate[visit].getHours() + 1);
      return { id: visit, schedule_start: visitsDate[visit], schedule_end: schedule_end};
    });
  };

  const updateVisits = () => {
    return visits.map(visit => {
      visit.schedule_start = applyTimeZoneOffset(visitsDate[visit.id], -timezoneOffset);
      return visit;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (visit, newValue) => {
    setVisitsDate(prevState => {
      return { ...prevState, [visit.id]: newValue }
    });
  };

  return (
    <div>
      <Button style={buttonStyle} onClick={handleClickOpen}>
        <EditIcon></EditIcon>
      </Button>
      <Dialog
        open={open}
        scroll="body"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Edicion de visitas
        </DialogTitle>
        <DialogContent
        style={dialogStyles}>
          <DialogContentText>
            Selecciona una fecha y hora para cada visita.
          </DialogContentText>
          <Table>
            <TableBody>
              {visits.map((visit) => {
                return (
                  <TableRow key={visit.sequence}>
                    <TableCell style={tableStyles.cell}>{visit.id_store}</TableCell>
                    <TableCell style={tableStyles.cell}>
                      <DatePicker
                        selected={visitsDate[visit.id]}
                        showTimeSelect
                        timeFormat="HH:mm"
                        className="select form-control"
                        timeIntervals={30}
                        dateFormat="dd-MM-yyyy HH:mm:ss"
                        timeCaption="Time"
                        onChange={(event) => handleOnChange(visit, event)}
                      ></DatePicker>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => handleClickConfirm()} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
