import React from "react";

function CreateErrorList(props) {
  let arrList = Object.keys(props.data);
  let arrFinal = [];

  for (let key of arrList) {
    let tempArr = Object.values(props.data[key]).map(
      val => `Línea ${[key]} del excel: ${val}`
    );
    arrFinal = arrFinal.concat(tempArr);
  }

  return (
    <ul>
      {arrFinal.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}

export default CreateErrorList;
